import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { setContentView } from '../mixins/set-content-view';

// // some test data
// [
//   {
//     'name1': 'Meta & Peter Beeken',
//     'street': 'Niedergeorgswerder Deich 164',
//     'city': '21109 Hamburg',
//     'phone': '040 - 754 94 416',
//     'email': 'beekentruck@beeken-hamburg.de',
//     'country': 'Deutschland'
//   },
//   {
//     'name1': 'TBS GmbH Thomas Bergen Spedition',
//     'street': 'Georg-Henschel-Straße 1',
//     'city': '28197 Bremen',
//     'phone': '0421 - 5288880',
//     'country': 'Deutschland'
//   },
//   {
//     'name1': 'Spedition Jakob Weets e.K.',
//     'street': 'Eichstraße 2',
//     'city': '26725 Emden',
//     'phone': '04921 - 95850',
//     'email': 'jakob.weets@weets.de',
//     'country': 'Deutschland'
//   },
//   {
//     'name1': 'Molthahn-Transporte GmbH',
//     'street': 'Schnatwinkel 10',
//     'city': '31688 Nierstädt',
//     'phone': '08721 - 808860',
//     'email': 'info@molthahn.de',
//     'country': 'Deutschland'
//   },
//   {
//     'name1': 'Willi Kreykenbohm Spedition GmbH',
//     'street': 'Rehwiese 13-15',
//     'city': '37603 Holzminden',
//     'phone': '05531 - 940022',
//     'email': 'wik@kreykenbohm.de',
//     'country': 'Deutschland'
//   },
//   {
//     'name1': 'Elsen GmbH & Co. KG Intern. Spedition',
//     'street': 'Justus-Liebig-Straße 2',
//     'city': '54516 Wittlich',
//     'phone': '06571 - 955220',
//     'email': '',
//     'country': 'Deutschland'
//   },
//   {
//     'name1': 'Seifert Logistics GmbH',
//     'street': 'Daimlerstraße 22-26',
//     'city': '89079 Ulm/Donautal',
//     'phone': '0731 - 4000-311',
//     'email': 'A.Kuelper@seifert-logistics.com',
//     'country': 'Deutschland'
//   },
//   {
//     'name1': 'Addicks & Kreye Container Logistik GmbH & Co. KG',
//     'street': 'Ellerholzweg 18-28',
//     'city': '21107 Hamburg',
//     'phone': '040 - 30396-690',
//     'email': 'ohl@addicks.de'
//   },
//   {
//     'name1': 'Jürgen Kobernuss Sped. E.K.',
//     'street': 'Neu Ripdorf 90',
//     'city': '29525 Uelzen',
//     'phone': '0581 - 9077-77',
//     'email': 'HubertusKobernuss@Kobernuss.de'
//   },
//   {
//     'name1': 'Schmid Transport und Spedition GmbH & Co. KG',
//     'street': 'Robert-Bosch-Straße 10',
//     'city': '93055 Regensburg',
//     'phone': '0941 -  40991-0',
//     'email': 'm.schmid@spedition-schmid.de'
//   },
//   {
//     'name1': 'Spedition Ansorge GmbH & Co. KG\nInternationale Spedition - Logistik',
//     'street': 'Gewerbepark 2',
//     'city': '87640 Bissenhofen',
//     'phone': '08342 - 913-0',
//     'email': 'thomaw@ansolog.com'
//   },
//   {
//     'name1': 'Wilhelm Schüssler Spedition GmbH',
//     'street': 'Eifelstraße 2-4',
//     'city': '64646 Heppenheim',
//     'phone': '06252 - 71039',
//     'email': 'kschuessler@schuessler-spedition.de'
//   }
// ];

interface FlmViewData extends KVComponent {}

export default {
  props: {
    flmData: {
      type: Object,
      // @todo: Remove Dummy Data after Presentation
      default() {
        return {
          first: [],
          last: [],
        };
      },
    },
  },

  mixins: [perfectScrollbarComponent(), setContentView('detail')],

  computed: {},
} as ComponentOptions<FlmViewData>;
