import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import App from 'components/App.vue';
import Layout from 'components/main/Layout.vue';
import Selector from 'components/main/Selector.vue';
import Main from 'components/main/Main.vue';
import Menu from 'components/main/Menu.vue';
import Map from 'components/main/Map.vue';
import ListView from 'components/ui/ListView.vue';
import ListBlock from 'components/ui/ListBlock.vue';
import DetailView from 'components/ui/DetailView.vue';
import ContactBlock from 'components/ui/ContactBlock.vue';
import ContactFlmBlock from 'components/ui/ContactFlmBlock.vue';
import AddressBlock from 'components/ui/AddressBlock.vue';
import InfosBlock from 'components/ui/InfosBlock.vue';
import DocumentsBlock from 'components/ui/DocumentsBlock.vue';
import BusinessHoursBlock from 'components/ui/BusinessHoursBlock.vue';
import KeysBlock from 'components/ui/KeysBlock.vue';
import VueCollapse from 'components/ui/collapse';
import Multicheckbox from 'components/ui/Multicheckbox.vue';
import Tooltip from 'components/ui/Tooltipp.vue';
import SelectWidgetExtended from 'components/ui/SelectWidgetExtended.vue';
import SelectWidget from 'components/ui/SelectWidget.vue';
import router from './router';
import store from './store';
import Multiselect from 'vue-multiselect';
import 'kv_shared/lib/vue/i18n';
import VueMatomo from 'vue-matomo';

Vue.use(VueMatomo, {
  host: 'https://analytics.railway.tools',
  siteId: 1,
  router,
});

sync(store, router);

// register external components
Vue.component('multiselect', Multiselect);

// Use Plugin
Vue.use(VueCollapse);

// register global components
Vue.component('layout', Layout);
Vue.component('selector', Selector);
Vue.component('main-content', Main);
Vue.component('app-menu', Menu);
Vue.component('main-map', Map);
Vue.component('list-view', ListView);
Vue.component('list-block', ListBlock);
Vue.component('detail-view', DetailView);
Vue.component('contact-block', ContactBlock);
Vue.component('contact-flm-block', ContactFlmBlock);
Vue.component('address-block', AddressBlock);
Vue.component('infos-block', InfosBlock);
Vue.component('documents-block', DocumentsBlock);
Vue.component('keys-block', KeysBlock);
Vue.component('business-hours-block', BusinessHoursBlock);
Vue.component('multicheckbox', Multicheckbox);
Vue.component('tooltip', Tooltip);
Vue.component('select-widget-extended', SelectWidgetExtended);
Vue.component('select-widget', SelectWidget);

function start() {
  const app = new Vue({
    router,
    store,
    ...App,
  } as any);

  app.$mount('#kv_frontend');
}

export { start, router, store };
