function getSelectionListGetters(prefix) {
  return {
    PLACE_AND_LOCATION_STORE: prefix + '_by_locations_and_countries',
    SELECTION_LIST: prefix + '_selection_list',
  };
}

export const GLOBAL = {
  APPLICATION_MODE: 'global_application_mode',
};

export const COUNTRY = {
  NAMES_BY_ID: 'country_names_by_id',
  BY_COUNTRY_CODE: 'coutry_by_code',
};

export const LOCATION = {
  LOCALE_NAMES: 'location_locale_names',
  BY_COUNTRY: 'location_by_country',
  PARSED_POSITIONS: 'location_parsed_positions',
};

export const COMPANY = {
  TYPE_NAMES: 'company_type_names',
  LIST_BY_TYPE: 'company_list_by_type',
  OPERATORS: 'company_operators',
  TERMINAL_CARRIERS: 'company_terminal_carriers',
  LOADING_POINT_CARRIERS: 'company_loading_point_carriers',
  SERVICE_STATION_CARRIERS: 'company_service_station_carriers',
  FORWARDERS: 'company_forwarders',
  BOOKING_PROVIDERS: 'company_booking_providers',
};

export const CONNECTION = {
  ...getSelectionListGetters('connection'),
  USED_TERMINALS: 'connection_used_terminals',
  DESTINATION_LOCATION_LIST: 'connection_destination_location_list',
  TRANSFORMED_SEARCH_RESULTS: 'connection_transformed_search_results',
  FILTERED_SEARCH_RESULTS: 'connection_filtered_search_results',
  ALL_BOOKABLE_CONNECTIONS: 'connection_all_bookable_connections',
  FILTERED_BOOKING_LINKS: 'connection_filtered_booking_links',
};

export const TERMINAL = {
  ...getSelectionListGetters('terminal'),
  ACTIVE_TERMINALS: 'terminal_active_terminals',
  HYDROGEN_TERMINALS: 'terminal_hydrogen_terminals',
  PARSED_POSITIONS: 'terminal_parsed_positions',
  HYDROGEN_PLACE_AND_LOCATION_STORE:
    'terminal_hydrogen_by_locations_and_countries',
  HYDROGEN_SELECTION_LIST: 'terminal_hydrogen_selection_list',
  RENDER_HYDROGEN_PIPELINES_MAP: 'terminal_render_hydrogen_pipelines_map',
};

export const LOADING_POINT = {
  ...getSelectionListGetters('loadingpoint'),
  COMPANY_TYPES_BY_ID: 'loadingpoint_company_types_by_id',
  COMPANY_TYPE_LABELS: 'loadingpoint_company_type_labels',
  COMPANY_TYPE_INDEX: 'loadingpoint_company_type_index',
};

export const SELECTOR = {
  SELECTION_MODE: 'selector_selection_mode',
  GET_CURRENT_SELECTION_AS_TEXT: 'selector_get_current_selection_as_text',
  START_LOCATIONS_IN_AREA: 'selector_start_locations_in_area',
  DESTINATIONS_LOCATIONS_IN_AREA: 'selector_destinations_locations_in_area',
};

export const UI = {
  HAS_MAIN_CONTENT_MOBILE_SCROLLBAR: 'ui_has_main_content_mobile_scrollbar',
  IS_MOBILE: 'ui_is_mobile',
};

export const CONVEYANCE = {
  START_COUNTRY_FORWARDERS: 'conveyance_start_country_forwarders',
  DESTINATION_COUNTRY_FORWARDERS: 'conveyance_destination_country_forwarders',
};

export const WAGON_LOAD = {
  ITEMS_BY_COUNTRY: 'wagon_load_items_by_country',
  ...getSelectionListGetters('wagon_load'),
};
