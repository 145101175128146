import { LOADING_POINT } from 'store/actions';
import { LOADING_POINT as GETTERS } from 'store/getters';
import { RootState, StoredArray, StoredEntity } from 'types';
import { ComponentOptions } from 'vue';
import {
  selectionListComponent,
  SelectionListMixin,
} from 'components/mixins/selection-list-component';
// tslint:disable-next-line:max-line-length
import {
  RailwaySiding,
  RailwaySidingInterchanges,
  RailwaySidingConnection,
  RailwaySidingMachines,
  RailwaySidingEquipment,
  LoadingPoint,
  LoadingPointCompanyTypeObject,
} from 'kv_shared/lib/data-types';
import { API_ENDPOINT } from 'utils/constants';
import { mapState, mapGetters } from 'vuex';
import { placeMapInteractions } from 'components/mixins/place-map-setup';
import { createLoadingPointRenderOption } from 'utils/map-helpers';

type LoadingPointArray = StoredArray<LoadingPoint | RailwaySiding>;

interface Component extends SelectionListMixin<LoadingPoint | RailwaySiding> {
  parseFeaturesConnection: (features: RailwaySidingConnection) => any;
  parseFeaturesInterchanges: (features: RailwaySidingInterchanges) => any;
  parseFeaturesMachines: (features: RailwaySidingMachines) => any;
  parseFeaturesEquipment: (features: RailwaySidingEquipment) => any;
  itemsByCountry: { [countryId: string]: LoadingPointArray };
  companyTypes: LoadingPointCompanyTypeObject[];
  currentCountry?: string;
  companyTypeIndex: any;
}

export default {
  created() {
    this.$store.dispatch(LOADING_POINT.LOAD_COMPANY_TYPES);
  },

  mixins: [
    selectionListComponent(LOADING_POINT, GETTERS),
    placeMapInteractions(),
  ],

  data() {
    return {
      documentsApiEndpoint: API_ENDPOINT.LOADING_POINT,
    };
  },

  computed: {
    ...mapState<RootState>({
      item: state => state.loadingPoints.selectedItem,
      items: state => state.loadingPoints.items, // needed for map rendering
      companyTypes: state => state.loadingPoints.companyTypes,
    }),

    ...mapGetters({
      companyTypeIndex: GETTERS.COMPANY_TYPE_INDEX,
    }),

    company(this: Component) {
      if (this.item && this.item.company.uid) {
        return this.companies[this.item.company.uid];
      }
    },

    listWithMarkers(this: Component) {
      return this.list.map(item => {
        return {
          ...item,
          marker: this.companyTypeIndex[item.uid],
        };
      });
    },

    itemsByCountry(this: Component) {
      return this.locationStore.placesByCountry;
    },

    currentCountry(this: Component) {
      return this.currentSelection.startCountry;
    },

    legendKeysList(this: Component) {
      if (!this.currentCountry || !this.itemsByCountry[this.currentCountry]) {
        return [];
      }

      const countryList = this.itemsByCountry[this.currentCountry];

      const keyIds: { [key: string]: boolean } = {};
      for (const item of countryList) {
        const type = this.companyTypeIndex[item.uid];
        if (type) {
          keyIds[type.id] = true;
        }
      }

      return this.companyTypes.filter(type => keyIds[type.id]);
    },

    infos() {
      const converted: any[] = [];

      if (
        this.item.infos &&
        this.item.infos.findIndex(info => info.infoContent !== '') >= 0
      ) {
        converted.push({
          list: this.item.infos.map(pair => ({
            label: pair.infoHeadline,
            value: pair.infoContent,
          })),
        });
      }

      if (this.item.freeText && this.item.freeText !== '') {
        converted.push({
          title: this.$t('Weitere Informationen'),
          list: [{ value: this.item.freeText }],
        });
      }

      return converted;
    },
  },

  methods: {
    getPlaceMapRenderOptions(lp: StoredEntity<LoadingPoint>) {
      const companyType: LoadingPointCompanyTypeObject = this.companyTypeIndex[
        lp.uid
      ];
      const color = companyType && companyType.color;
      return createLoadingPointRenderOption(
        lp,
        this.locations,
        color,
        this.item && this.item.uid === lp.uid,
      );
    },

    convertFeaturesToList(features: RailwaySiding['features']) {
      const converted: any[] = [];

      if (features.connection) {
        converted.push(this.parseFeaturesConnection(features.connection));
      }

      if (features.interchanges && features.interchanges.tracks) {
        converted.push(this.parseFeaturesInterchanges(features.interchanges));
      }

      if (
        features.machines &&
        Object.values(features.machines).find(
          elem => elem === true || elem.length > 0,
        )
      ) {
        converted.push(this.parseFeaturesMachines(features.machines));
      }

      if (
        features.equipment &&
        Object.values(features.equipment).find(elem => elem === true)
      ) {
        converted.push(this.parseFeaturesEquipment(features.equipment));
      }

      return converted;
    },

    parseFeaturesConnection(connection: RailwaySidingConnection) {
      const list: any[] = [];

      list.push({
        label: this.$t('Status'),
        value:
          connection.status === undefined || connection.status === 'true'
            ? this.$t('aktiv')
            : connection.status === 'false'
            ? this.$t('inaktiv')
            : this.$t('keine Angabe'),
      });

      list.push({
        label: this.$t('Verladung externer möglich'),
        value:
          connection.loading === 'true'
            ? this.$t('Ja')
            : connection.loading === 'false'
            ? this.$t('Nein')
            : this.$t('keine Angabe'),
      });

      list.push({
        label: this.$t('Gleisanschluss elektrifiziert'),
        value:
          connection.electric === 'true'
            ? this.$t('Ja')
            : connection.electric === 'false'
            ? this.$t('Nein')
            : this.$t('keine Angabe'),
      });

      list.push({
        label: this.$t('Umfahrmöglichkeit im Gleisanschluss'),
        value:
          connection.bypass === 'true'
            ? this.$t('Ja')
            : connection.bypass === 'false'
            ? this.$t('Nein')
            : this.$t('keine Angabe'),
      });

      return {
        title: this.$t('Anschlussinformationen'),
        list,
      };
    },

    parseFeaturesInterchanges(interchanges: RailwaySidingInterchanges) {
      const list: any[] = [];

      if (interchanges.tracks && interchanges.tracks.length > 0) {
        interchanges.tracks.forEach((item, index) => {
          list.push({
            label: this.$t('Gleis {number}', { number: index + 1 }),
            value: item + ' m',
          });
        });
      }

      if (interchanges.sideRamp) {
        list.push({
          label: this.$t('Seitenrampe'),
          value: this.$t('Ja'),
        });
      }

      if (interchanges.headRamp) {
        list.push({
          label: this.$t('Kopframpe'),
          value: this.$t('Ja'),
        });
      }

      if (interchanges.groundLevel) {
        list.push({
          label: this.$t('Ebenerdiger Zugang'),
          value: this.$t('Ja'),
        });
      }

      if (interchanges.size) {
        list.push({
          label: this.$t('Größe'),
          value: interchanges.size + ' m²',
        });
      }

      if (interchanges.storageArea) {
        list.push({
          label: this.$t('Verfügbare Lagerfläche'),
          value: interchanges.storageArea + ' m²',
        });
      }

      return {
        title: this.$t('Umschlaggleise und Nutzlängen'),
        list,
      };
    },

    parseFeaturesMachines(machines: RailwaySidingMachines) {
      const list: any[] = [];

      if (machines.forklift) {
        list.push({
          label: this.$t('Gabelstabler'),
        });
      }

      if (machines.reachStacker) {
        list.push({
          label: this.$t('Reachstacker'),
        });
      }

      if (machines.crane) {
        list.push({
          label: this.$t('Portalkran'),
        });
      }

      if (machines.digger) {
        list.push({
          label: this.$t('Bagger'),
        });
      }

      if (machines.others && machines.others.length > 0) {
        machines.others.forEach((item, index) => {
          list.push({
            label: item,
          });
        });
      }

      return {
        title: this.$t('Verfügbare Umschlaggeräte'),
        list,
      };
    },

    parseFeaturesEquipment(equipment: RailwaySidingEquipment) {
      const list: any[] = [];

      if (equipment.rankDevice) {
        list.push({
          label: this.$t('Eigene Rangiergeräte oder Tfz'),
        });
      }

      if (equipment.trainScale) {
        list.push({
          label: this.$t('Eisenbahnwaage'),
        });
      }

      if (equipment.truckScale) {
        list.push({
          label: this.$t('Lkw Waage'),
        });
      }

      if (equipment.serviceStation) {
        list.push({
          label: this.$t('Eisenbahntankstelle'),
        });
      }

      if (equipment.parking) {
        list.push({
          label: this.$t('Triebfahrzeugabstellplatz'),
        });
      }

      return {
        title: this.$t('Eisenbahnequipment'),
        list,
      };
    },
  },
} as ComponentOptions<Component>;
