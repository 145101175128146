import {
  SelectionMode,
  MapInteractionType,
  PlaceRenderOptions,
  MapRenderSpec,
} from 'types';
import { COUNTRY as COUNTRY_GETTERS } from 'store/getters';
import { SELECTOR, MAP } from 'store/actions';
import { Place } from 'kv_shared/lib/data-types';
import { SelectionListMixin } from 'components/mixins/selection-list-component';
import { createStartLocationRenderOption } from 'utils/map-helpers';

interface State extends SelectionListMixin<Place> {
  getPlaceMapRenderOptions<T extends Place>(t: T): PlaceRenderOptions;
}

export function placeMapInteractions() {
  return {
    methods: {
      // OVERRIDE in root components!!
      getPlaceMapRenderOptions<T extends Place>(t: T): PlaceRenderOptions {
        return {} as PlaceRenderOptions;
      },

      handleStateChange(this: State, selectionHasChanged?: boolean) {
        switch (this.selectionMode) {
          case SelectionMode.START_COUNTRY:
            this.$store.dispatch(MAP.RENDER, {});
            this.$store.dispatch(MAP.ZOOM_TO_WORLD);
            break;

          case SelectionMode.START_CITY:
            const startPlaces =
              this.locationStore.placesByCountry[
                this.currentSelection.startCountry
              ] || [];

            this.$store.dispatch(MAP.RENDER, {
              places: startPlaces.map(place =>
                this.getPlaceMapRenderOptions(place),
              ),
            } as MapRenderSpec);
            this.$store.dispatch(MAP.ZOOM_TO_PLACES);
            break;

          case SelectionMode.ALL_SELECTED:
            const locationUid = this.currentSelection.startLocations[0];
            const selectedPlaces =
              this.locationStore.placesByLocation[locationUid] || [];

            this.$store.dispatch(MAP.RENDER, {
              locations: [
                createStartLocationRenderOption(
                  this.locations[locationUid],
                  this.locationNames,
                ),
              ],
              places: selectedPlaces.map(place =>
                this.getPlaceMapRenderOptions(place),
              ),
            } as MapRenderSpec);

            if (selectionHasChanged) {
              this.$store.dispatch(MAP.ZOOM_TO_PLACES);
            }
            break;
        }
      },

      onMapInteraction(this: State) {
        const { payload, type } = this.mapInteraction;

        switch (this.selectionMode) {
          case SelectionMode.START_COUNTRY:
            if (type === MapInteractionType.COUNTRY_CLICKED) {
              const country = this.$store.getters[
                COUNTRY_GETTERS.BY_COUNTRY_CODE
              ][payload.countryCode];
              this.$store.dispatch(
                SELECTOR.SELECT_START_COUNTRY,
                country && country.uid,
              );
            }
            break;

          case SelectionMode.START_CITY:
            if (type === MapInteractionType.PLACE_CLICKED) {
              const place = this.items[payload.uid];
              this.$store.dispatch(
                SELECTOR.SELECT_START_LOCATIONS,
                place && [place.locality.uid],
              );
            }
            break;

          case SelectionMode.ALL_SELECTED:
            if (type === MapInteractionType.PLACE_CLICKED) {
              if (this.item) {
                this.resetItem();
              } else {
                this.selectItem(payload.uid);
              }
            }

            if (type === MapInteractionType.LOCATION_CLOSED) {
              this.$store.dispatch(SELECTOR.SELECT_START_LOCATIONS, null);
            }
            break;
        }
      },
    },
  };
}
