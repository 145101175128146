import { mapState, mapGetters } from 'vuex';
import { ComponentOptions } from 'vue';
import { KVComponent, RootState } from 'types';
import { normalizeUrl } from 'utils/helpers';
import { USABLE_UNITS } from 'utils/constants';
import { weekDaysMini } from 'utils/moment-service';
import { LOCATION as LOCATION_GETTERS } from 'store/getters';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';

interface TrainDetailsData extends KVComponent {
  trainData: Array<{
    title: string;
    list: Array<{ label: string; value: string }>;
  }>;
  currentLanguage: string;
  locationsLocaleNames: { [uid: string]: string };
}

export default {
  props: ['trainData'],

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
    }),

    ...mapGetters({
      locationsLocaleNames: LOCATION_GETTERS.LOCALE_NAMES,
    }),

    trainBookingInfo() {
      let info = this.$t('n/a');
      const trainTimingData = this.trainData.endOfBooking;

      if (trainTimingData && trainTimingData.day && trainTimingData.time) {
        info =
          (weekDaysMini[trainTimingData.day - 1].label[this.currentLanguage] ||
            weekDaysMini[trainTimingData.day - 1].label[DEFAULT_LANGUAGE]) +
          ' ' +
          trainTimingData.time;
      }

      return info;
    },

    trainCapacitiesText() {
      let text = '';

      if (this.trainData.capacitiesLeft === undefined) {
        text = this.$t('n/a');
      } else if (this.trainData.capacitiesLeft) {
        text = this.$t('vorhanden');
      } else {
        text = this.$t('keine');
      }

      return this.$t('Kapazitäten') + ': ' + text;
    },

    convertedUsableUnits() {
      return Object.entries(this.trainData.usableUnits).map(([key, value]) => {
        if (value) {
          return this.$t(USABLE_UNITS[key]);
        }
      });
    },
  },

  methods: {
    normalizeUrl(url) {
      return normalizeUrl(url);
    },

    isUrl(value) {
      return value.toLowerCase().indexOf('http') >= 0;
    },

    displayUrl(url) {
      return url.replace(/^http[s]?\:\/\//, '');
    },

    getUsableUnitName(unitKey) {
      return this.$t(USABLE_UNITS[unitKey]);
    },

    getLocationNameFromUid(uid) {
      // TODO: Test this !!
      return this.locationsLocaleNames[uid] || this.$t('n/a');
    },
  },
} as ComponentOptions<TrainDetailsData>;
