import Vue from 'vue';
import Router from 'vue-router';
import i18n from 'kv_shared/lib/vue/i18n';
import StartScreen from 'components/root/StartScreen.vue';
import AboutScreen from 'components/root/AboutScreen.vue';
import Connections from 'components/root/Connections.vue';
import Terminals from 'components/root/Terminals.vue';
import HydrogenTerminals from 'components/root/HydrogenTerminals.vue';
import LoadingPoints from 'components/root/LoadingPoints.vue';
import WagonLoad from 'components/root/WagonLoad.vue';
import { ApplicationMode } from 'types';

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: () => '/' + i18n.locale,
    },

    {
      path: '/:lang/connections',
      name: ApplicationMode.CONNECTIONS,
      component: Connections,
    },

    {
      path: '/:lang/terminals',
      name: ApplicationMode.TERMINALS,
      component: Terminals,
    },

    {
      path: '/:lang/loadingpoints',
      name: ApplicationMode.LOADING_POINTS,
      component: LoadingPoints,
    },

    {
      path: '/:lang/wagonload',
      name: ApplicationMode.WAGON_LOAD,
      component: WagonLoad,
    },

    {
      path: '/:lang/hydrogen',
      name: ApplicationMode.HYDROGEN_TERMINALS,
      component: HydrogenTerminals,
    },

    {
      path: '/:lang/about/:scrollTo?',
      name: ApplicationMode.ABOUT,
      component: AboutScreen,
      props: true,
    },

    {
      path: '/:lang/styleguide',
      name: ApplicationMode.STYLEGUIDE,
      component: () => import('components/styleguide/Styleguide.vue'),
    },

    {
      path: '/:lang/:info?/:scrollTo?',
      name: ApplicationMode.START,
      component: StartScreen,
      props: true,
    },

    // catch all redirect
    { path: '*', redirect: '/' },
  ],
} as any);

export default router;

// make root components hot reloadable in development
if ((module as any).hot) {
  (module as any).hot.accept();
}
