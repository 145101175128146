import { ComponentOptions } from 'vue';
import { mapState, mapGetters } from 'vuex';
import { KVComponent, RootState } from 'types';
import { MISC } from 'store/actions';
import { ApplicationMode } from 'types';
import { UI as UI_GETTERS } from 'store/getters';

interface Component extends KVComponent {
  text: {};
  showCookieNotice: boolean;
  isMobile: boolean;
}

export default {
  data() {
    return {
      // Todo: Add languages
      text: {
        // tslint:disable:max-line-length
        de: 'Datenschutz',
        en: 'privacy',
        it: 'privacie',
        no: 'priate',
        fr: 'privee',
        // tslint:enable:max-line-length
      },
    };
  },

  created() {
    this.$store.dispatch(MISC.GET_COOKIE_NOTICE_VISIBILITY);
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
      showCookieNotice: state => state.misc.showCookieNotice,
    }),

    ...mapGetters({
      isMobile: UI_GETTERS.IS_MOBILE,
    }),

    getText() {
      return this.text[this.currentLanguage] || this.text.en;
    },

    isBlockHidden() {
      return !this.showCookieNotice;
    },
  },

  methods: {
    acceptPrivacy() {
      this.$store.dispatch(MISC.HIDE_COOKIE_NOTICE);
    },

    openPrivacyPage() {
      this.$store.dispatch(MISC.HIDE_COOKIE_NOTICE);

      if (this.isMobile) {
        this.$router.push({
          name: ApplicationMode.ABOUT,
          params: { scrollTo: '#matomo' },
        });
      } else {
        this.$router.push({
          name: ApplicationMode.START,
          params: { info: 'privacy', scrollTo: '#matomo' },
        });
      }
    },
  },
} as ComponentOptions<Component>;
