import { SELECTOR as ACTIONS, API } from 'store/actions';
import { SELECTOR as MUTATIONS } from 'store/mutations';
// tslint:disable-next-line:max-line-length
import {
  SELECTOR as GETTERS,
  GLOBAL as GLOBAL_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
  LOCATION as LOCATION_GETTERS,
} from 'store/getters';
// tslint:disable-next-line:max-line-length
import {
  RootState,
  SelectorState,
  SelectionMode,
  ApplicationMode,
  SelectorQuery,
  StoredArray,
  LocationQueryOption,
} from 'types';
import router from 'router';
import { GetterTree, ActionTree, MutationTree, Action } from 'vuex';
import isEqual from 'lodash/isEqual';
import { Location, ParsedGeoLocation } from 'kv_shared/lib/data-types';
import { getDistanceInKM } from 'kv_shared/lib/utils/geo-coords';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';
import debounce from 'lodash/debounce';
import { defaultValueOnHttpError } from 'utils/helpers';
import { DEFAULT_SELECTOR_RADIUS } from 'utils/constants';
import { availablePLZApiCountryCodes } from 'kv_shared/lib/constants';

// ===

const state: SelectorState = {
  startCountry: '',
  startPosQuery: '',
  startPosOptions: null,
  startGeoPosition: null,
  startRadius: DEFAULT_SELECTOR_RADIUS,
  startLocations: [],
  endCountry: '',
  endPosQuery: '',
  endPosOptions: null,
  endGeoPosition: null,
  endRadius: DEFAULT_SELECTOR_RADIUS,
  endLocations: [],
  locationStore: {
    locationsByCountry: {},
    placesByLocation: {},
    placesByCountry: {},
  },
};

const getters: GetterTree<SelectorState, RootState> = {
  [GETTERS.SELECTION_MODE](state, getters, rootState) {
    if (
      !(
        Object.keys(rootState.countries.items).length &&
        Object.keys(rootState.locations.items).length
      )
    ) {
      return SelectionMode.INITIALIZE;
    }
    if (!state.startCountry) {
      return SelectionMode.START_COUNTRY;
    }
    if (
      getters[GLOBAL_GETTERS.APPLICATION_MODE] === ApplicationMode.WAGON_LOAD
    ) {
      return SelectionMode.ALL_SELECTED;
    }
    if (!(state.startLocations && state.startLocations.length)) {
      return SelectionMode.START_CITY;
    }
    if (
      getters[GLOBAL_GETTERS.APPLICATION_MODE] !== ApplicationMode.CONNECTIONS
    ) {
      return SelectionMode.ALL_SELECTED;
    }
    if (!state.endCountry) {
      return SelectionMode.DESTINATION_COUNTRY;
    }
    if (!(state.endLocations && state.endLocations.length)) {
      return SelectionMode.DESTINATION_CITY;
    }
    return SelectionMode.ALL_SELECTED;
  },

  [GETTERS.GET_CURRENT_SELECTION_AS_TEXT](state, getters, rootState) {
    const countryNamesById = getters[COUNTRY_GETTERS.NAMES_BY_ID];
    const locationNamesById = getters[LOCATION_GETTERS.LOCALE_NAMES];

    return {
      startCountry: countryNamesById[state.startCountry],
      startLocation: locationNamesById[state.startLocations[0]],
      endCountry: countryNamesById[state.endCountry],
      endLocation: locationNamesById[state.endLocations[0]],
    };
  },

  [GETTERS.START_LOCATIONS_IN_AREA](state, getters) {
    const positions = getters[LOCATION_GETTERS.PARSED_POSITIONS];
    const locations =
      state.locationStore.locationsByCountry[state.startCountry];
    return getAreaLocation(
      state.startGeoPosition,
      state.startRadius,
      locations,
      positions,
    );
  },

  [GETTERS.DESTINATIONS_LOCATIONS_IN_AREA](state, getters) {
    const positions = getters[LOCATION_GETTERS.PARSED_POSITIONS];
    const locations = state.locationStore.locationsByCountry[state.endCountry];
    return getAreaLocation(
      state.endGeoPosition,
      state.endRadius,
      locations,
      positions,
    );
  },
};

const actions: ActionTree<SelectorState, RootState> = {
  // Public actions, use to set and manipulate selector

  [ACTIONS.RESET_SEARCH]({ dispatch }) {
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, { startCountry: '' });
  },

  [ACTIONS.SELECT_START_COUNTRY]({ dispatch, state }, countryId: string) {
    // countryId either falsy (deselect) or inside available countries
    const countryLocations = state.locationStore.locationsByCountry[countryId];
    if (!countryId || countryLocations) {
      dispatch(ACTIONS.UPDATE_ROUTE_QUERY, { startCountry: countryId });
    }
  },

  [ACTIONS.SELECT_START_LOCATIONS](
    { dispatch, rootGetters, state },
    locationIds: string[],
  ) {
    const countryLocations =
      state.locationStore.locationsByCountry[state.startCountry];
    const positions = rootGetters[LOCATION_GETTERS.PARSED_POSITIONS];

    const startLocations: string[] = [];
    if (locationIds) {
      for (const locationId of locationIds) {
        if (
          countryLocations &&
          countryLocations.find(l => l.uid === locationId)
        ) {
          startLocations.push(locationId);
        }
      }
    }
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      startLocations,
      startGeoPosition:
        state.startGeoPosition ||
        (startLocations.length ? positions[startLocations[0]] : null),
    });
  },

  [ACTIONS.SELECT_DESTINATION_COUNTRY]({ dispatch, state, commit }, countryId) {
    // countryId either falsy (deselect) or inside available countries
    const countryLocations = state.locationStore.locationsByCountry[countryId];
    if (!countryId || countryLocations) {
      dispatch(ACTIONS.UPDATE_ROUTE_QUERY, { endCountry: countryId });
    }
  },

  [ACTIONS.SELECT_DESTINATION_LOCATIONS](
    { dispatch, state, rootGetters },
    locationIds: string[],
  ) {
    const countryLocations =
      state.locationStore.locationsByCountry[state.endCountry];
    const positions = rootGetters[LOCATION_GETTERS.PARSED_POSITIONS];

    const endLocations: string[] = [];
    if (locationIds) {
      for (const locationId of locationIds) {
        if (
          countryLocations &&
          countryLocations.find(l => l.uid === locationId)
        ) {
          endLocations.push(locationId);
        }
      }
    }
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      endLocations,
      endGeoPosition:
        state.endGeoPosition ||
        (endLocations.length ? positions[endLocations[0]] : null),
    });
  },

  [ACTIONS.SELECT_START_RADIUS]({ dispatch }, radius) {
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      startRadius: radius,
      startLocations: null,
    });
  },
  [ACTIONS.SELECT_START_POSITION]({ dispatch }, position) {
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      startGeoPosition: position,
      startLocations: null,
    });
  },
  [ACTIONS.SELECT_DESTINATION_RADIUS]({ dispatch }, radius) {
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      endRadius: radius,
      endLocations: null,
    });
  },
  [ACTIONS.SELECT_DESTINATION_POSITION]({ dispatch }, position) {
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      endGeoPosition: position,
      endLocations: null,
    });
  },

  [ACTIONS.SELECT_START_POS_QUERY]({ dispatch }, query: string) {
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      startPosQuery: query,
      startLocations: null,
    });
  },

  [ACTIONS.SELECT_DESTINATION_POS_QUERY]({ dispatch }, query) {
    dispatch(ACTIONS.UPDATE_ROUTE_QUERY, {
      endPosQuery: query,
      endLocations: null,
    });
  },

  [ACTIONS.UPDATE_START_POSITION_OPTIONS]: (debounce as any)(
    (({ rootState, dispatch, commit, getters }) => {
      const query = state.startPosQuery;
      if (state.startCountry) {
        const country = rootState.countries.items[state.startCountry];
        const locations = state.locationStore.locationsByCountry[country.uid];
        const locationNamesById = getters[LOCATION_GETTERS.LOCALE_NAMES];
        const parsedGeoLocations = getters[LOCATION_GETTERS.PARSED_POSITIONS];

        const promise =
          query &&
          query.length >= 2 &&
          availablePLZApiCountryCodes[country.code]
            ? dispatch(ACTIONS.API_GET_POSITIONS, {
                countryCode: country && country.code,
                query,
              })
            : Promise.resolve();

        return promise.then(list => {
          commit(
            MUTATIONS.SET_START_POSITION_OPTIONS,
            generatePositionOptions(
              query,
              rootState.locale,
              locations,
              locationNamesById,
              parsedGeoLocations,
              list,
            ),
          );
        });
      } else {
        commit(MUTATIONS.SET_START_POSITION_OPTIONS, []);
      }
    }) as Action<SelectorState, RootState>,
    200,
  ),

  [ACTIONS.UPDATE_DESTINATIOIN_POSITION_OPTIONS]: (debounce as any)(
    (({ state, rootState, dispatch, commit, getters }) => {
      const query = state.endPosQuery;
      if (state.endCountry) {
        const country = rootState.countries.items[state.endCountry];
        const locations = state.locationStore.locationsByCountry[country.uid];
        const locationNamesById = getters[LOCATION_GETTERS.LOCALE_NAMES];
        const parsedGeoLocations = getters[LOCATION_GETTERS.PARSED_POSITIONS];

        const promise =
          query &&
          query.length >= 2 &&
          availablePLZApiCountryCodes[country.code]
            ? dispatch(ACTIONS.API_GET_POSITIONS, {
                countryCode: country && country.code,
                query,
              })
            : Promise.resolve();

        return promise.then(list => {
          commit(
            MUTATIONS.SET_END_POSITION_OPTIONS,
            generatePositionOptions(
              query,
              rootState.locale,
              locations,
              locationNamesById,
              parsedGeoLocations,
              list,
            ),
          );
        });
      } else {
        commit(MUTATIONS.SET_END_POSITION_OPTIONS, []);
      }
    }) as Action<SelectorState, RootState>,
    200,
  ),

  [ACTIONS.SET_CURRENT_LOCATION_STORE]({ commit }, store) {
    commit(MUTATIONS.SET_CURRENT_LOCATION_STORE, store);
  },

  // ===== API actions =====

  async [ACTIONS.API_GET_POSITIONS]({ dispatch }, { countryCode, query }) {
    return dispatch(API.GET, {
      path: `location/plz/${countryCode}`,
      query: { search: query, limit: 30 },
    })
      .then(response => {
        return response.plzList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  // ===== Privat actions, do not use in components =====

  // This is called only internally by other actions in this module
  [ACTIONS.UPDATE_ROUTE_QUERY]({ dispatch, state, rootState }, stateUpdate) {
    const newQuery = getNewQuery(state, stateUpdate) as any;
    const route = rootState.route;
    router.push({ name: route.name, params: route.params, query: newQuery });
  },

  // Called by vue router after every route change.
  // Sets the selector state from current URL query string.
  [ACTIONS.PROCESS_ROUTE_QUERY]({ commit, dispatch, rootState, state }) {
    commit(MUTATIONS.SET_STATE_FROM_QUERY, rootState.route.query);
  },
};

const mutations: MutationTree<SelectorState> = {
  [MUTATIONS.SET_STATE_FROM_QUERY](state, query: Partial<SelectorQuery>) {
    if (state.startCountry !== query.startCountry) {
      if (!query.startCountry) {
        state.startCountry = '';
      } else {
        state.startCountry = query.startCountry;
      }
    }

    if (state.startPosQuery !== query.startPosQuery) {
      if (!query.startPosQuery) {
        state.startPosQuery = '';
      } else {
        state.startPosQuery = query.startPosQuery;
      }
    }

    if (query.startLat != null && query.startLng != null) {
      const newPos = {
        lat: parseFloat(query.startLat as string),
        lng: parseFloat(query.startLng as string),
      };
      if (!isEqual(newPos, state.startGeoPosition)) {
        state.startGeoPosition = newPos;
      }
    } else if (state.startGeoPosition) {
      state.startGeoPosition = null;
    }

    if (query.startRadius != null) {
      const r = parseFloat((query.startRadius as any) as string);
      if (r !== state.startRadius) {
        state.startRadius = r;
      }
    } else if (state.startRadius) {
      state.startRadius = DEFAULT_SELECTOR_RADIUS;
    }

    if (!isEqual(state.startLocations, query.startLocations)) {
      const uids = query.startLocations;
      if (!uids) {
        state.startLocations = [];
      } else if (typeof uids === 'string') {
        state.startLocations = [uids];
      } else {
        state.startLocations = uids;
      }
    }

    if (state.endCountry !== query.endCountry) {
      if (!query.endCountry) {
        state.endCountry = '';
      } else {
        state.endCountry = query.endCountry;
      }
    }

    if (state.endPosQuery !== query.endPosQuery) {
      if (!query.endPosQuery) {
        state.endPosQuery = '';
      } else {
        state.endPosQuery = query.endPosQuery;
      }
    }

    if (query.endLat != null && query.endLng != null) {
      const newPos = {
        lat: parseFloat(query.endLat as string),
        lng: parseFloat(query.endLng as string),
      };
      if (!isEqual(newPos, state.endGeoPosition)) {
        state.endGeoPosition = newPos;
      }
    } else if (state.endGeoPosition) {
      state.endGeoPosition = null;
    }

    if (query.endRadius != null) {
      const r = parseFloat((query.endRadius as any) as string);
      if (r !== state.endRadius) {
        state.endRadius = r;
      }
    } else if (state.endRadius) {
      state.endRadius = DEFAULT_SELECTOR_RADIUS;
    }

    if (!isEqual(state.endLocations, query.endLocations)) {
      const uids = query.endLocations;
      if (!uids) {
        state.endLocations = [];
      } else if (typeof uids === 'string') {
        state.endLocations = [uids];
      } else {
        state.endLocations = uids;
      }
    }
  },

  [MUTATIONS.SET_CURRENT_LOCATION_STORE](state, currentStore) {
    state.locationStore = currentStore || {
      locationsByCountry: {},
      placesByLocation: {},
      placesByCountry: {},
    };
  },

  [MUTATIONS.SET_START_POSITION_OPTIONS](state, data) {
    state.startPosOptions = data;
  },

  [MUTATIONS.SET_END_POSITION_OPTIONS](state, data) {
    state.endPosOptions = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

// === Helpers ===

/**
 * Creates a new valid router query from current state and an update object.
 * Cleans up the object in correct order, starting with FLM status and start country
 * and ending with destination locations.
 * This is the main selector control flow implementation!
 */
function getNewQuery(
  state: SelectorState,
  stateUpdate: Partial<SelectorState>,
) {
  const updatedState = { ...state, ...stateUpdate };
  const newState = {} as Partial<SelectorQuery>;

  if (!updatedState.startCountry) {
    return newState;
  }

  newState.startCountry = updatedState.startCountry;

  if (updatedState.startCountry !== state.startCountry) {
    return newState;
  }

  newState.startPosQuery = updatedState.startPosQuery || '';
  if (updatedState.startGeoPosition) {
    newState.startLat = updatedState.startGeoPosition.lat;
    newState.startLng = updatedState.startGeoPosition.lng;
  }
  if (updatedState.startRadius) {
    newState.startRadius = updatedState.startRadius;
  }

  if (!updatedState.startLocations || !updatedState.startLocations.length) {
    return newState;
  }

  newState.startLocations = updatedState.startLocations;

  if (
    !isEqual(updatedState.startLocations, state.startLocations) ||
    !updatedState.endCountry
  ) {
    return newState;
  }

  newState.endCountry = updatedState.endCountry;

  if (updatedState.endCountry !== state.endCountry) {
    return newState;
  }

  newState.endPosQuery = updatedState.endPosQuery || '';
  if (updatedState.endRadius) {
    newState.endRadius = updatedState.endRadius;
  }
  if (updatedState.endGeoPosition) {
    newState.endLat = updatedState.endGeoPosition.lat;
    newState.endLng = updatedState.endGeoPosition.lng;
  }

  if (!updatedState.endLocations || !updatedState.endLocations.length) {
    return newState;
  }

  newState.endLocations = updatedState.endLocations;
  return newState;
}

function getAreaLocation(
  position: ParsedGeoLocation | null,
  radius: number,
  countryLocations?: StoredArray<Location>,
  positions?: { [id: string]: ParsedGeoLocation },
) {
  if (countryLocations && position && radius && positions) {
    const locationsInArea: StoredArray<Location> = [];
    for (const loc of countryLocations) {
      const pos = positions[loc.uid];
      // position distance with 5km offset
      if (pos && getDistanceInKM(position, pos) - 5 < radius) {
        locationsInArea.push(loc);
      }
    }
    return freezeToStopVueReactivity(locationsInArea);
  }
  return countryLocations || [];
}

function generatePositionOptions(
  query: string,
  language: string,
  locationsOfCountry: StoredArray<Location>,
  locationNamesById: { [id: string]: string },
  parsedGeoLocations: { [id: string]: ParsedGeoLocation },
  queryResults?: any[],
): LocationQueryOption[] {
  const options: LocationQueryOption[] = [];

  if (locationsOfCountry) {
    for (const loc of locationsOfCountry) {
      const id = loc.uid;
      const label = locationNamesById[id];
      if (label.toLocaleLowerCase().indexOf(query.toLowerCase().trim()) >= 0) {
        options.push(
          Object.freeze({
            id,
            label,
            value: parsedGeoLocations[id],
          }),
        );
      }
    }
    options.sort((a, b) => a.label.localeCompare(b.label));
  }

  if (queryResults) {
    for (const res of queryResults) {
      options.push(
        Object.freeze({
          value: res.loc,
          label: res.plz + ', ' + (res.city[language] || res.city.def),
        }),
      );
    }
  }

  return options;
}
