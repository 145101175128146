import { mapState } from 'vuex';
import { ComponentOptions } from 'vue';
import { FileDocument } from 'kv_shared/lib/data-types';
import { KVComponent } from 'types';

interface DocumentsData extends KVComponent {
  documents: FileDocument[];
}

export default {
  props: ['documents', 'itemUid', 'apiEndpoint'],

  computed: {
    ...mapState<any>({
      apiUrl: state => state.api.apiUrl,
    }),
  },
} as ComponentOptions<DocumentsData>;
