import { GLOBAL as MUTATIONS, API } from './mutations';
import { GLOBAL as ACTIONS } from './actions';
import { GLOBAL as GETTERS } from './getters';
import { BaseState, RootState, ApiConfig, ApplicationMode } from 'types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

// initial State
const state: BaseState = {
  locale: 'de',
  availableLanguages: ['de', 'en'],
  apiConfig: null,
};

const actions: ActionTree<BaseState, RootState> = {
  [ACTIONS.ADD_CONFIG]({ commit, dispatch }, config: ApiConfig) {
    commit(MUTATIONS.SET_CONFIG, config);
    commit(API.SET_URL, config.backendApiUrl);
  },

  [ACTIONS.CHANGE_LANGUAGE]({ commit, state }, language) {
    if (language && typeof language === 'string') {
      language = language.toLowerCase();
      if (state.availableLanguages.includes(language)) {
        commit(MUTATIONS.SET_LANGUAGE, language);
      }
    }
  },
};

const getters: GetterTree<BaseState, RootState> = {
  [GETTERS.APPLICATION_MODE]: (state, getters, rootState) =>
    Object.values(ApplicationMode).includes(rootState.route
      .name as ApplicationMode)
      ? rootState.route.name
      : ApplicationMode.START,
};

const mutations: MutationTree<BaseState> = {
  [MUTATIONS.SET_CONFIG](state, config) {
    state.apiConfig = config;
  },

  [MUTATIONS.SET_LANGUAGE](state, lang) {
    state.locale = lang;
  },

  [MUTATIONS.SET_AVAILABLE_LANGUAGES](state, langs) {
    if (langs && langs.length) {
      state.availableLanguages = langs.map(lang => lang.toLowerCase());
    }
  },
};

export default { state: state as RootState, mutations, getters, actions };
