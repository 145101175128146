//
// Collapse Plugin
// Forked and Customized from https://roszpun.github.io/vue-collapse/#/
//

import CollapseWrapper from './components/wrapper.vue';
import CollapseGroup from './components/group.vue';
import { defaults } from './defaults';

const VueCollapse: any = {};
VueCollapse.install = (Vue, options) => {
  // merge configs
  const settings = Object.assign(defaults, options);

  // creating required components
  Vue.component(settings.prefix + '-wrapper', CollapseWrapper);
  Vue.component(settings.prefix + '-group', CollapseGroup);

  // creates instance of settings in the Vue
  Vue.mixin({
    created() {
      this.$options.$vc = {
        settings,
      };
    },
  });

  // content directive
  Vue.directive(settings.basename + '-content', {
    // assigning css classes from settings
    bind(el, binding, vnode, oldVnode) {
      vnode.elm.classList.add(
        vnode.context.$options.$vc.settings.contentClassDefault,
      );
    },
  });

  // toggler directive
  Vue.directive(settings.basename + '-toggle', {
    // adding toggle class
    bind(el, binding, vnode, oldVnode) {
      vnode.elm.classList.add(
        vnode.context.$options.$vc.settings.togglerClassDefault,
      );
    },

    // Creating custom toggler handler
    inserted(el, binding, vnode, oldVnode) {
      if (binding.value != null) {
        vnode.elm.addEventListener('click', () => {
          vnode.context.$refs[binding.value].status = !vnode.context.$refs[
            binding.value
          ].status;
        });
      }
    },
  });
};

export default VueCollapse;
