import { ComponentOptions } from 'vue';
import { USABLE_UNITS } from 'utils/constants';
import { Terminal } from 'kv_shared/lib/data-types';

export function terminalFeatures() {
  return {
    methods: {
      convertFeaturesToList(features: Terminal['features']) {
        const converted: any[] = [];

        // T R A N S P O R T
        // transport: new TerminalTransport(),
        if (features.transport || features.transportCarrier) {
          converted.push(this.parseFeaturesTransport(features));
        }

        // I N F R A S T R U C T U R E
        // infrastructure: new TerminalInfrastructure(),
        if (
          features.infrastructure &&
          Object.values(features.infrastructure).find(
            elem => elem === true || elem.length > 0,
          )
        ) {
          converted.push(this.parseFeaturesInfrastructure(features));
        }

        // A C C O M P L I S H M E N T S
        // accomplishments: new TerminalAccomplishments(),
        if (
          features.accomplishments &&
          Object.values(features.accomplishments).find(
            elem => elem === true || elem.length > 0,
          )
        ) {
          converted.push(this.parseFeaturesAccomplishments(features));
        }

        // I N T E R C H A N G E S
        // interchangesAvailable: false,
        // interchanges: [] as string[],
        // interchangesTotalLengthAvailable: false,
        // interchangesTotalLength: '',
        if (
          (features.interchangesAvailable &&
            features.interchanges.length > 0) ||
          (features.interchangesTotalLengthAvailable &&
            parseInt(features.interchangesTotalLength) > 0)
        ) {
          converted.push(this.parseFeaturesInterchanges(features));
        }

        // G R I P P E R
        // gripperAvailable: false,
        // gripperLength: '',
        if (features.gripperAvailable) {
          converted.push(this.parseFeaturesGripper(features));
        }

        // M O B I L E   I N T E R C H A N G E
        // mobileInterchangeMachinesAvailable: false,
        // mobileInterchangeMachines: [] as string[],
        if (
          features.mobileInterchangeMachines &&
          features.mobileInterchangeMachines.length > 0
        ) {
          converted.push(this.parseFeaturesMobileInterchange(features));
        }

        // H Y D R O G E N
        if (
          features.hydrogen &&
          (features.hydrogen.isHandling || features.hydrogen.isProvider)
        ) {
          converted.push(this.parseFeaturesHydrogen(features));
        }

        // H A Z A R D O U S   G O O D S   I N T E R C H A N G E
        // hazardousGoods: new TerminalHazardousGoods(),
        if (
          features.hazardousGoods &&
          features.hazardousGoods.interchangeAvailable
        ) {
          converted.push(this.parseFeaturesHazardousInterchange(features));
        }

        if (
          features.hazardousGoods &&
          features.hazardousGoods.leakageAvailable
        ) {
          converted.push(this.parseFeaturesHazardousLeakage(features));
        }

        // U S A B L E   U N I T S
        // usableUnits: new TerminalUsableUnits()
        if (
          features.usableUnits &&
          Object.values(features.usableUnits).find(
            value => value === true || value.length > 0,
          )
        ) {
          converted.push(this.parseFeaturesUsableUnits(features));
        }

        // F U R T H E R   I N F O
        // furtherInformation: '',
        if (features.furtherInformation && features.furtherInformation !== '') {
          converted.push(this.parseFeaturesFurtherInfo(features));
        }

        //
        return converted;
      },

      parseFeaturesTransport(features) {
        let text = '';

        if (features.transport) {
          const textArray: any[] = [];

          if (features.transport.road) {
            textArray.push(this.$t('Straße'));
          }
          if (features.transport.rail) {
            textArray.push(this.$t('Schiene'));
          }
          if (features.transport.ship) {
            textArray.push(this.$t('Binnenschiff'));
          }
          if (features.transport.transship) {
            textArray.push(this.$t('Tiefsee'));
          }

          text = textArray.join(' / ');
        } else if (
          features.transportCarrier &&
          features.transportCarrier.length > 0
        ) {
          text = features.transportCarrier;
        }

        return {
          title: this.$t('Angeschlossene Verkehrsträger'),
          list: [
            {
              label: text,
            },
          ],
        };
      },

      parseFeaturesInfrastructure(features) {
        const list: any[] = [];

        if (features.infrastructure.catenary) {
          list.push({
            label: this.$t('Umschlaggleise elektrifiziert (Oberl.)'),
          });
        }

        if (features.infrastructure.breaksTestInstallation) {
          list.push({
            label: this.$t('stationäre Bremsprobeanlage'),
          });
        }

        if (features.infrastructure.connectionsTemperatureManagedLoadingUnits) {
          list.push({
            label: this.$t('Anschlüsse für temperaturgeführte LE'),
          });
        }

        if (features.infrastructure.cranes) {
          list.push({
            label: this.$t('Schienenportalkrane'),
            value: features.infrastructure.cranesQuantity.toString(),
          });
        }

        if (features.infrastructure.limitedLiftingHeight) {
          list.push({
            label: this.$t('max. Hubhöhe über Schienenoberkante'),
            value: features.infrastructure.maxLiftingHeight + ' m',
          });
        }

        if (features.infrastructure.limitedTonnage) {
          list.push({
            label: this.$t('max. Tragfähigkeit am Ladegeschirr'),
            value: features.infrastructure.maxTonnage + ' t',
          });
        }

        if (features.infrastructure.limitedTransShipment) {
          list.push({
            label: this.$t('max. Umschlagkapazität des Terminals'),
            value:
              features.infrastructure.maxTransShipment +
              ' ' +
              this.$t('LE p.a.'),
          });
        }

        if (features.infrastructure.limitedGroundSpace) {
          list.push({
            label: this.$t('max. Bodenabstellkapazität'),
            value: features.infrastructure.maxGroundSpace + ' TEU',
          });
        }

        return {
          title: this.$t('Infrastruktur'),
          list,
        };
      },

      parseFeaturesAccomplishments(features) {
        const list: any[] = [];

        if (features.accomplishments.customsAvailable) {
          list.push({
            label: this.$t('Zollstelle'),
            value: this.$t('in {distance} km Entfernung', {
              distance: features.accomplishments.customsDistance,
            }),
          });
        }

        if (features.accomplishments.containerRepairAvailable) {
          list.push({
            label: this.$t('Containerreparatur'),
            value: features.accomplishments.containerRepairDistance
              ? // tslint:disable-next-line:max-line-length
                this.$t('in {distance} km Entfernung', {
                  distance: features.accomplishments.containerRepairDistance,
                })
              : '',
          });
        }

        if (features.accomplishments.containerCleaningAvailable) {
          list.push({
            label: this.$t('Containerreinigung'),
            value: features.accomplishments.containerCleaningDistance
              ? this.$t('in {distance} km Entfernung', {
                  distance: features.accomplishments.containerCleaningDistance,
                })
              : '',
          });
        }

        if (features.accomplishments.containerDepositAvailable) {
          list.push({
            label: this.$t('Containerdepot'),
            value: features.accomplishments.containerDepositDistance
              ? // tslint:disable-next-line:max-line-length
                this.$t('in {distance} km Entfernung', {
                  distance: features.accomplishments.containerDepositDistance,
                })
              : '',
          });
        }

        if (
          features.accomplishments.furtherAccomplishments &&
          features.accomplishments.furtherAccomplishmentsList.length > 0
        ) {
          features.accomplishments.furtherAccomplishmentsList.forEach(
            (item, index) => {
              list.push({
                label: item.name,
                value: item.distance
                  ? this.$t('in {distance} km Entfernung', {
                      distance: item.distance,
                    })
                  : '',
              });
            },
          );
        }

        return {
          title: this.$t('Sonstige Leistungen'),
          list,
        };
      },

      parseFeaturesInterchanges(features) {
        const list: any[] = [];

        if (
          features.interchangesAvailable &&
          features.interchanges.length > 0
        ) {
          features.interchanges.forEach((item, index) => {
            list.push({
              label: this.$t('Gleis {number}', { number: index + 1 }),
              value: item + ' m',
            });
          });
        }

        if (
          features.interchangesTotalLengthAvailable &&
          features.interchangesTotalLength > 0
        ) {
          list.push({
            label: this.$t('Gleislänge gesamt'),
            value: features.interchangesTotalLength + ' m',
          });
        }

        return {
          title: this.$t('Umschlaggleise und Nutzlängen'),
          list,
        };
      },

      parseFeaturesGripper(features) {
        return {
          title: this.$t('Umschlagarten'),
          list: [
            {
              label: this.$t('Max. Greifarmlänge'),
              value: features.gripperLength + ' m',
            },
          ],
        };
      },

      parseFeaturesMobileInterchange(features) {
        const list: any[] = [];

        features.mobileInterchangeMachines.forEach((item, index) => {
          list.push({
            label: item,
          });
        });

        return {
          title: this.$t('Mobilumschlaggeräte'),
          list,
        };
      },

      parseFeaturesHazardousInterchange(features) {
        return {
          title: this.$t('Gefahrgut (RID/ADR)'),
          list: [
            {
              label: this.$t('Gefahrgutumschlag (RID/ADR) möglich'),
            },
          ],
        };
      },

      parseFeaturesHazardousLeakage(features) {
        return {
          title: this.$t('Leckageplatz (RID/ADR)'),
          list: [
            {
              label: this.$t('Leckageplatz vorhanden'),
            },
          ],
        };
      },

      parseFeaturesHydrogen(features) {
        const list: any[] = [];

        if (features.hydrogen.isHandling) {
          list.push({
            label: this.$t('H\u2082-Umschlag'),
          });
        }
        if (features.hydrogen.isProvider) {
          list.push({
            label: this.$t('H\u2082-HUB/Hersteller'),
          });
        }

        return {
          title: this.$t('Gefahrgut Wasserstoff'),
          list,
        };
      },

      parseFeaturesUsableUnits(features) {
        const list: any[] = [];

        Object.entries(features.usableUnits).forEach(([key, value]) => {
          if (value) {
            list.push({
              label: this.$t(USABLE_UNITS[key]),
            });
          }
        });

        if (
          features.usableUnits.furtherUnitsAvailable &&
          features.usableUnits.furtherUnits.length > 0
        ) {
          features.usableUnits.furtherUnits.forEach((item, index) => {
            list.push({
              label: item,
            });
          });
        }

        return {
          title: this.$t('Umschlagbare Ladeeinheiten'),
          list,
        };
      },

      parseFeaturesFurtherInfo(features) {
        return {
          title: this.$t('Weitere Informationen'),
          list: [
            {
              label: features.furtherInformation,
            },
          ],
        };
      },
    },
  } as ComponentOptions<any>;
}
