import { ComponentOptions } from 'vue';
import { KVComponent, RootState, TrainConnectionDetail } from 'types';
import { mapState } from 'vuex';
import { weekDaysMini } from 'utils/moment-service';
import ConnectionHeaderBlock from 'components/ui/ConnectionHeaderBlock.vue';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { setContentView } from '../mixins/set-content-view';
import { dragscroll } from 'vue-dragscroll';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';

interface ScheduleViewData extends KVComponent {
  currentLanguage: string;
  trainData: TrainConnectionDetail[];
}

export default {
  props: ['trainData'],

  directives: {
    dragscroll,
  },

  components: {
    ConnectionHeaderBlock,
  },

  mixins: [perfectScrollbarComponent(), setContentView('detail')],

  data() {
    return {
      dayKeys: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
    };
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
    }),

    trainDataMatrix(this: ScheduleViewData) {
      const matrixCellData: any[] = [];
      const operatorsDictionary = {};

      this.trainData.forEach((connectionItem, connectionIndex) => {
        const trainConnectionData = connectionItem;

        Object.entries(trainConnectionData.trains).forEach(
          ([operatorUid, operatorData]) => {
            if (!operatorsDictionary[operatorUid]) {
              operatorsDictionary[operatorUid] = {
                operator: operatorData.operator.name,
                color: Object.entries(operatorsDictionary).length + 1,
              };
            }

            Object.entries(operatorData.trains).forEach(
              ([operatorTrainKey, operatorTrainData]) => {
                const fromTerminalTimings = operatorTrainData.train.terminalTimings.find(
                  item => item.uid === operatorTrainData.terminalsFrom[0].uid,
                );

                const filteredTrainData = Object.assign(
                  {},
                  operatorsDictionary[operatorTrainData.train.operator.uid],
                );

                if (
                  fromTerminalTimings &&
                  fromTerminalTimings.delivery &&
                  fromTerminalTimings.delivery.day
                ) {
                  // matrix index should start at 0
                  // weekDaysMini array also starts at 0
                  const departureDayIndex =
                    parseInt(fromTerminalTimings.delivery.day) - 1;

                  matrixCellData[departureDayIndex] =
                    matrixCellData[departureDayIndex] ||
                    new Array(this.trainData.length);

                  matrixCellData[departureDayIndex][connectionIndex] =
                    matrixCellData[departureDayIndex][connectionIndex] || [];

                  // Departure time
                  filteredTrainData.departure =
                    (weekDaysMini[departureDayIndex].label[
                      this.currentLanguage
                    ] ||
                      weekDaysMini[departureDayIndex].label[DEFAULT_LANGUAGE]) +
                    ' ' +
                    fromTerminalTimings.delivery.time;

                  // Arrival time
                  const toTerminalTimings = operatorTrainData.train.terminalTimings.find(
                    item =>
                      item.uid ===
                      operatorTrainData.terminalsTo[
                        operatorTrainData.terminalsTo.length - 1
                      ].uid,
                  );

                  if (
                    toTerminalTimings &&
                    toTerminalTimings.collection &&
                    toTerminalTimings.collection.day
                  ) {
                    // weekDaysMini array starts at 0
                    const collectionDayIndex =
                      parseInt(toTerminalTimings.collection.day) - 1;
                    filteredTrainData.arrival =
                      (weekDaysMini[collectionDayIndex].label[
                        this.currentLanguage
                      ] ||
                        weekDaysMini[collectionDayIndex].label[
                          DEFAULT_LANGUAGE
                        ]) +
                      ' ' +
                      toTerminalTimings.collection.time;
                  }

                  matrixCellData[departureDayIndex][connectionIndex].push(
                    filteredTrainData,
                  );
                }
              },
            );
          },
        );
      });

      return matrixCellData;
    },
  },

  methods: {
    getWeekday(index) {
      return (
        weekDaysMini[index].label[this.currentLanguage] ||
        weekDaysMini[index].label[DEFAULT_LANGUAGE]
      );
    },
  },
} as ComponentOptions<ScheduleViewData>;
