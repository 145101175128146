function createItemMutations(prefix) {
  return {
    UPDATE_ITEMS: prefix + '_update_items',
    REPLACE_ITEMS: prefix + '_replace_items',
    SET_COMPLETELY_LOADED: prefix + '_set_completely_loaded',
  };
}

function createSelectionListMutations(prefix) {
  return {
    SET_CURRENT_ITEM: prefix + '_set_current_item',
  };
}

export const GLOBAL = {
  SET_CONFIG: 'global_set_api_config',
  SET_LANGUAGE: 'global_set_language',
  SET_AVAILABLE_LANGUAGES: 'global_set_available_languages',
};

export const UI = {
  SET_LOADER: 'ui_set_loader',
  SET_CONTENT_VIEW: 'ui_set_content_view',
  SET_MOUSE_DRAGGING_STATE: 'ui_set_mouse_dragging_state',
  SET_CLOSE_DETAILS: 'ui_set_close_details',
  SET_CLOSE_MAIN_CONTENT: 'ui_set_close_main_content',
  SET_CLOSE_CONNECTION_FILTER: 'ui_set_close_connection_filter',
  SET_WINDOW_SIZE: 'ui_window_size',
};

export const API = {
  SET_URL: 'api_set_url',
};

export const MISC = {
  SET_IMPRINT: 'set_imprint',
  SHOW_COOKIE_NOTICE: 'show_cookie_notice',
  HIDE_COOKIE_NOTICE: 'hide_cookie_notice',
  SET_MATOMO_TRACKING: 'set_matomo_tracking',
};

export const COMPANY = {
  ...createItemMutations('company'),
  SET_CONFIG_BY_TYPE: 'company_set_config_by_type',
};

export const LOCATION = {
  ...createItemMutations('location'),
};

export const COUNTRY = {
  ...createItemMutations('country'),
};

export const CONNECTION = {
  ...createItemMutations('connection'),
  ...createSelectionListMutations('connection'),
  SET_SELECTED_CONNECTIONS: 'connection_set_selected_connections',
  SET_DIRECT_CONNECTIONS: 'connection_set_direct_connections',
  SET_TERMINALS_WITH_TRAINS: 'connection_set_terminals_with_trains',
  SET_TERMINALS_LOADED: 'connections_set_terminals_loaded',
  SET_SEARCHING: 'connection_set_searching',
  SET_SELECTED_TRAIN_UID: 'connection_set_selected_train_uid',
  SET_SELECTED_TERMINAL_UID: 'connection_set_selected_terminal_uid',
  SET_SELECTED_OPERATOR_UID: 'connection_set_selected_operator_uid',
  SET_DIRECT_CONNECTIONS_VISIBILITY:
    'connections_set_direct_connections_visibility',
  SET_FILTER: 'connections_set_filter',
  SET_USABLE_UNITS: 'connection_set_usable_units',
};

export const TRAIN = {
  ...createItemMutations('train'),
  SET_SELECTED_TRAIN: 'train_set_selected',
  SET_LOADED_OPERATORS: 'train_set_loaded_operators',
};

export const TERMINAL = {
  ...createItemMutations('terminal'),
  ...createSelectionListMutations('terminal'),
  SET_HYDROGEN_PIPELINES_VISIBILITY:
    'terminal_set_hydrogen_pipelines_visibility',
};

export const LOADING_POINT = {
  ...createItemMutations('loadingpoint'),
  ...createSelectionListMutations('loadingpoint'),
  SET_COMPANY_TYPES: 'loadingpoint_replace_company_types',
};

export const NEWS = {
  ...createItemMutations('news'),
};

export const SELECTOR = {
  SET_STATE_FROM_QUERY: 'selector_set_state_from_query',
  SET_CURRENT_LOCATION_STORE: 'selector_set_current_location_store',
  SET_END_POSITION_OPTIONS: 'selector_set_end_position_options',
  SET_START_POSITION_OPTIONS: 'selector_set_start_position_options',
};

export const MAP = {
  SET_ZOOM: 'map_set_zoom',
  SET_RENDER: 'map_set_render',
  SET_INTERACTION: 'map_set_interactiond',
};

export const CONVEYANCE = {
  ...createItemMutations('conveyance'),
};

export const WAGON_LOAD = {
  ...createItemMutations('wagon_load'),
  ...createSelectionListMutations('wagon_load'),
};
