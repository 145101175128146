import { ComponentOptions } from 'vue';
import { KVComponent, RootState } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { setContentView } from '../mixins/set-content-view';
import { mapState } from 'vuex';

interface DetailViewData extends KVComponent {}

export default {
  props: ['headline', 'locationName', 'canCloseParent', 'marker'],

  mixins: [perfectScrollbarComponent(), setContentView('detail')],

  computed: {
    ...mapState<RootState>({
      uiCloseDetails: state => state.ui.closeDetails,
    }),
  },

  watch: {
    uiCloseDetails: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.closeParent();
        }
      },
    },
  },

  methods: {
    closeDetails() {
      this.$emit('close');
    },

    closeParent() {
      this.$emit('closeParent');
    },
  },
} as ComponentOptions<DetailViewData>;
