import { ComponentOptions } from 'vue';
import { Company } from 'kv_shared/lib/data-types';
import { KVComponent } from 'types';
import { normalizePhone } from 'utils/helpers';
import { COUNTRY } from 'store/getters';

interface Component extends KVComponent {
  forwarder: Company;
  countryNames: any;
}

export default {
  props: ['forwarder'],

  computed: {
    countryNames(this: Component) {
      return this.$store.getters[COUNTRY.NAMES_BY_ID];
    },

    contactData(this: Component) {
      const data: any = {};

      data.name1 = this.forwarder.name;
      data.name2 = this.forwarder.contact.name;
      data.address1 = this.forwarder.address.address1;
      data.address2 = this.forwarder.address.address2;
      data.zip = this.forwarder.address.zip;
      data.city = this.forwarder.address.city;
      data.country = this.countryNames[
        this.forwarder.address.country.uid as string
      ];

      data.phone = this.forwarder.contact.phone;
      data.email = this.forwarder.contact.email;

      return data;
    },
  },

  methods: {
    normalizePhone(phonenumber) {
      return normalizePhone(phonenumber);
    },
  },
} as ComponentOptions<Component>;
