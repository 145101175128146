import { ComponentOptions } from 'vue';
import { mapState } from 'vuex';
import { KVComponent, RootState } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { MISC } from 'store/actions';
import { Imprint } from 'kv_shared/lib/data-types';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';

interface Component extends KVComponent {
  imprintData: Imprint | {};
  imprint: string;
  currentLanguage: string;
}

export default {
  props: {
    scrollbar: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    this.$store.dispatch(MISC.GET_IMPRINT);
  },

  mixins: [perfectScrollbarComponent()],

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
      imprintData: state => state.misc.imprintData,
    }),

    imprint(this: Component) {
      const data = this.imprintData && (this.imprintData as Imprint);
      return (
        (data &&
          data.content &&
          (data.content[this.currentLanguage] ||
            data.content[DEFAULT_LANGUAGE])) ||
        ''
      );
    },
  },
} as ComponentOptions<Component>;
