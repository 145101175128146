import { WAGON_LOAD, SELECTOR, MAP } from 'store/actions';
import {
  WAGON_LOAD as GETTERS,
  COUNTRY as COUNTRY_GETTERS,
} from 'store/getters';
import {
  RootState,
  MapInteractionType,
  SelectionMode,
  StoredHashmap,
  CountryRenderOptions,
  CountryClasses,
  CountryItemRenderOptions,
  PlaceClasses,
} from 'types';
import { ComponentOptions } from 'vue';
import {
  SelectionListMixin,
  selectionListComponent,
} from 'components/mixins/selection-list-component';
import { mapState, mapGetters } from 'vuex';
import { WagonLoad, Info, Country } from 'kv_shared/lib/data-types';
import { sortListBy } from 'utils/helpers';

interface Component extends SelectionListMixin<any> {
  legendKeysList: any[];
  itemsByCountry: { [id: string]: WagonLoad[] };
  countries: StoredHashmap<Country>;
  item: WagonLoad;
  list: WagonLoad[];
}

export default {
  mixins: [selectionListComponent(WAGON_LOAD, GETTERS)],

  computed: {
    ...mapState<RootState>({
      item: state => state.wagonLoad.selectedItem,
      items: state => state.wagonLoad.items,
      countries: state => state.countries.items,
      companies: state => state.companies.items,
    }),

    ...mapGetters({
      itemsByCountry: GETTERS.ITEMS_BY_COUNTRY,
    }),

    listWithMarkers(this: Component) {
      return this.list.map(item => {
        return {
          ...item,
          name: item.name || this.companies[item.company.uid!].name,
          marker: {
            name: item.name || this.companies[item.company.uid!].name,
            color: item.color,
          },
        };
      });
    },

    legendKeysList(this: Component) {
      const legendItems = Object.values(this.items)
        .map((wl: WagonLoad) => ({
          ...wl,
          label: this.companies[wl.company.uid!]
            ? this.companies[wl.company.uid!].name
            : '',
        }))
        // group labels by company name and color
        .reduce((items, item) => {
          items[item.label + item.color] = item;
          return items;
        }, {});

      const list = Object.values(legendItems);

      return sortListBy(list, 'label');
    },

    company(this: Component) {
      if (this.item && this.item.company.uid) {
        return this.companies[this.item.company.uid];
      }
    },

    infoExists(this: Component) {
      return this.item.infos.some(i => !!(i.infoContent || i.infoHeadline));
    },
  },

  methods: {
    convertWagonLoadInfosToList(infos: Info[]) {
      const list = infos.map(i => {
        return {
          label: i.infoHeadline,
          value: i.infoContent,
        };
      });

      return [
        {
          list,
        },
      ];
    },

    handleStateChange(selectionHasChanged?: boolean) {
      const countries: CountryRenderOptions[] = [];
      for (const cid in this.itemsByCountry) {
        const c = this.countries[cid];
        const items = this.itemsByCountry[cid];
        if (c) {
          countries.push({
            countryCode: c.code,
            center: c.center,
            className:
              this.currentSelection.startCountry === c.uid
                ? CountryClasses.HIGHLIGHT
                : undefined,
            items: items.map(
              i =>
                ({
                  ...i,
                  className:
                    this.item && this.item.uid === i.uid
                      ? PlaceClasses.CUSTOM_COLOR_HIGHLIGHT
                      : PlaceClasses.CUSTOM_COLOR,
                  selectable: true,
                } as CountryItemRenderOptions),
            ),
          });
        }
      }

      if (countries.length) {
        this.$store.dispatch(MAP.RENDER, { countries });
      }
      if (this.selectionMode === SelectionMode.START_COUNTRY) {
        this.$store.dispatch(MAP.ZOOM_TO_WORLD);
      } else if (
        this.selectionMode === SelectionMode.ALL_SELECTED &&
        selectionHasChanged
      ) {
        this.$store.dispatch(MAP.ZOOM_TO_START_COUNTRY);
      }
    },

    onMapInteraction(this: Component) {
      const { payload, type } = this.mapInteraction;

      if (type === MapInteractionType.COUNTRY_ITEM_CLICKED) {
        if (this.item && this.item.uid === payload.data.uid) {
          this.resetItem();
        } else {
          this.selectItem(payload.data.uid);
        }
        const country = this.$store.getters[COUNTRY_GETTERS.BY_COUNTRY_CODE][
          payload.data.country
        ];
        this.$store.dispatch(
          SELECTOR.SELECT_START_COUNTRY,
          country && country.uid,
        );
      }

      if (type === MapInteractionType.COUNTRY_CLICKED) {
        const country = this.$store.getters[COUNTRY_GETTERS.BY_COUNTRY_CODE][
          payload.countryCode
        ];
        this.$store.dispatch(
          SELECTOR.SELECT_START_COUNTRY,
          country && country.uid,
        );
      }
    },
  },
} as ComponentOptions<Component>;
