import { Route } from 'vue-router';
import Vue from 'vue';
import { Store } from 'vuex';
import {
  Entity,
  Company,
  CompanyType,
  Connection,
  Country,
  RailwaySiding,
  LoadingPoint,
  Terminal,
  LoadingPointCompanyTypeObject,
  Location,
  Imprint,
  News,
  Train,
  Place,
  ConnectionSearchResult,
  ConnectionResultZug,
  GeoLocation,
  ParsedGeoLocation,
  TransportUsableUnits,
  ConveyanceArea,
  WagonLoad,
} from 'kv_shared/lib/data-types';

// ===== Global enums =====

export enum ApplicationMode {
  START = 'start',
  INFO = 'info',
  CONNECTIONS = 'connections',
  TERMINALS = 'terminals',
  HYDROGEN_TERMINALS = 'hydrogen_terminals',
  LOADING_POINTS = 'loadingPoints',
  WAGON_LOAD = 'wagonLoads',
  ABOUT = 'about',

  // Use only in development
  STYLEGUIDE = 'styleguide',
}

export enum SelectionMode {
  INITIALIZE = 'selection_mode::initialize',
  START_COUNTRY = 'selection_mode::start_country',
  START_CITY = 'selection_mode::start_city',
  DESTINATION_COUNTRY = 'selection_mode::destination_country',
  DESTINATION_CITY = 'selection_mode::destination_city',
  ALL_SELECTED = 'selection_mode::all_selected',
}

export enum TransportFeatureStatus {
  NO_DATA = 'no_data',
  CAPACITIES = 'available',
  SOME_CAPACITIES = 'partial',
  NO_CAPACITIES = 'unavailable',
}

// ===== Vuex state definitions =====

// Make Entity uid be always string, not null, when loaded form api.
export type StoredEntity<T extends Entity> = Readonly<T & { uid: string }>;

export interface StoredHashmap<T extends Entity> {
  [uid: string]: StoredEntity<T>;
}

export type StoredArray<T extends Entity> = Array<StoredEntity<T>>;

export interface ItemState<T extends Entity> {
  items: StoredHashmap<T>;
  completelyLoaded: boolean;
}

export interface SelectionListState<T extends Entity> {
  selectedItem: StoredEntity<T> | null;
}

export interface ApiConfig {
  version: string;
  backendApiUrl: string;
  map: {
    loc: string;
    de: string;
    en: string;
  };
}

export interface BaseState {
  locale: string;
  availableLanguages: string[];
  apiConfig: ApiConfig | null;
}

export interface ApiState {
  apiUrl: string;
}

export interface CompanyState extends ItemState<Company> {
  configurations: { [P in CompanyType]?: any };
  companyTypesLoaded: { [type: string]: boolean };
}

export interface CountryState extends ItemState<Country> {}

export interface LocationState extends ItemState<Location> {}

export interface WagonLoadState
  extends ItemState<WagonLoad>,
    SelectionListState<WagonLoad> {}

export interface LoadingPointState
  extends ItemState<LoadingPoint | RailwaySiding>,
    SelectionListState<LoadingPoint | RailwaySiding> {
  companyTypes: LoadingPointCompanyTypeObject[];
}

export interface TerminalState
  extends ItemState<Terminal>,
    SelectionListState<Terminal> {
  requestHydrogenPipelines: boolean;
}

export class ConnectionFilter {
  changeCount = 2;
  changeTerminal = true;
  changeOperator = true;
}

export interface ConnectionState extends ItemState<Connection> {
  selectedConnections: ReadonlyArray<Readonly<ConnectionSearchResult>>;
  selectedItem: Readonly<{
    label: string;
    connections: ReadonlyArray<TrainConnectionDetail>;
  }> | null;
  directConnections: ReadonlyArray<Readonly<Entity>>;
  terminalsWithTrains: StoredHashmap<Terminal>;
  terminalsLoaded: boolean;
  isSearching: boolean;
  selectedTerminalUid: string;
  selectedOperatorUid: string;
  selectedTrainUid: string;
  showDirectConnections: boolean;
  filter: ConnectionFilter;
  usableUnits: TransportUsableUnits | null;
}

export interface TrainState extends ItemState<StoredTrain> {
  operatorsLoaded: { [uid: string]: boolean };
}

export interface ConveyanceState extends ItemState<ConveyanceArea> {}

export interface NewsState extends ItemState<News> {}

export interface MiscState {
  imprintData: Imprint | {};
  showCookieNotice: boolean;
  isMatomoTrackingActive: boolean;
}

export interface UIState {
  windowSize: { width: number; height: number };
  loaderCount: number;
  showLoader: boolean;
  currentContentView: string;
  mouseIsDragging: boolean;
  closeDetails: boolean;
  closeMainContent: boolean;
  closeConnectionFilter: boolean;
}

export interface SelectorState {
  startCountry: string;
  startPosQuery: string;
  startPosOptions: any;
  startGeoPosition: ParsedGeoLocation | null;
  startRadius: number;
  startLocations: string[];
  endCountry: string;
  endPosQuery: string;
  endPosOptions: any;
  endGeoPosition: ParsedGeoLocation | null;
  endRadius: number;
  endLocations: string[];
  locationStore: PlaceAndLocationStore<any>;
}

export type SelectorQuery = SelectorState & {
  startLat: string | number;
  startLng: string | number;
  startRadius: string | number;
  endLat: string | number;
  endLng: string | number;
  endRadius: string | number;
};

export enum MapZoomType {
  ZOOM_IN = 'map_command::zoom_in',
  ZOOM_OUT = 'map_command::zoom_out',
  ZOOM_TO_WORLD = 'map_command::zoom_to_world',
  ZOOM_TO_PLACES = 'map_command::zoom_to_places',
  ZOOM_TO_CONNECTIONS = 'map_command::zoom_to_connections',
  ZOOM_TO_START_COUNTRY = 'map_command::zoom_to_start_country',
}

export enum MapInteractionType {
  COUNTRY_CLICKED = 'map_event::country_clicked',
  LOCATION_CLICKED = 'map_event::location_clicked',
  LOCATION_CLOSED = 'map_event::location_closed',
  PLACE_CLICKED = 'map_event::place_clicked',
  COUNTRY_ITEM_CLICKED = 'map_event::country_item_clicked',
}

export interface MapInteraction {
  type: MapInteractionType;
  payload: any;
}

export interface MapState {
  zoom: { type: MapZoomType } | null;
  interaction: MapInteraction | null;
  renderItems: MapRenderSpec;
}

export interface RootState extends BaseState {
  api: ApiState;
  ui: UIState;
  companies: CompanyState;
  connections: ConnectionState;
  countries: CountryState;
  locations: LocationState;
  loadingPoints: LoadingPointState;
  wagonLoad: WagonLoadState;
  terminals: TerminalState;
  trains: TrainState;
  conveyance: ConveyanceState;
  news: NewsState;
  misc: MiscState;
  route: Route;
  selector: SelectorState;
  map: MapState;
}

// ===== Connection Rendering Details ======

export type StoredTrain = StoredEntity<Train & { operator: { uid: string } }>;

export interface ConnectionResultTrain extends ConnectionResultZug {
  train: StoredTrain;
  transportStatus: TransportFeatureStatus;
}

export interface BookingLink {
  id: string;
  label: string;
  urlDE: string;
  urlEN: string;
}

export interface ConnectionHop {
  active: any;
  distance: number;
  fromLocationUid: string;
  toLocationUid: string;
  trains: ConnectionResultTrain[];
  transportStatus: TransportFeatureStatus;
  bookableBy?: BookingLink[];
}

export interface ConnectionListItem {
  label: string;
  connections: ReadonlyArray<Partial<ConnectionHop>>;
  realDistance: number;
  operatorChanges: number;
  terminalChanges: number;
  transferNumber: number;
  transportStatus: TransportFeatureStatus;
  bookableBy?: BookingLink[];
}

export interface TrainDetails {
  terminalsFrom: StoredArray<Terminal>;
  terminalsTo: StoredArray<Terminal>;
  transportStatus: TransportFeatureStatus;
  train: StoredTrain;
}

export interface TrainOperatorDetails {
  operator: StoredEntity<Company>;
  terminals: {
    from: StoredHashmap<Terminal>;
    to: StoredHashmap<Terminal>;
  };
  trains: TrainDetails[];
}

export interface TrainConnectionDetail {
  from: StoredEntity<Location>;
  to: StoredEntity<Location>;
  trains: { [operatorUid: string]: TrainOperatorDetails };
  transportStatus: TransportFeatureStatus;
}

export interface ConnectionDetails {
  label: string;
  connections: ReadonlyArray<TrainConnectionDetail>;
  transportStatus: TransportFeatureStatus;
  bookableBy?: BookingLink[];
}

// ==== Map rendering ====

export interface MapRenderSpec {
  locations?: LocationRenderOptions[];
  places?: PlaceRenderOptions[];
  connections?: ConnectionRenderOptions[];
  countries?: CountryRenderOptions[];
}

export interface LocationRenderOptions {
  uid: string;
  name: string;
  className?: LocationClasses;
  placePositions?: Array<GeoLocation | ParsedGeoLocation>;
  position?: GeoLocation | ParsedGeoLocation;
  selectable?: boolean;
}

export interface PlaceRenderOptions {
  uid: string;
  name: string;
  className?: PlaceClasses;
  position: GeoLocation | ParsedGeoLocation;
  color?: string;
  selectable?: boolean;
}

export interface ConnectionRenderOptions {
  uid: string;
  name?: string;
  className?: ConnectionClasses;
  from: GeoLocation | ParsedGeoLocation;
  to: GeoLocation | ParsedGeoLocation;
}

export interface CountryRenderOptions {
  countryCode: string;
  center?: GeoLocation | ParsedGeoLocation;
  className?: CountryClasses;
  items?: CountryItemRenderOptions[];
}

export interface CountryItemRenderOptions {
  uid: string;
  name?: string;
  className?: PlaceClasses;
  color?: string;
  selectable?: boolean;
}

export enum PlaceClasses {
  DEFAULT_BLUE = 'blue',
  DEFAULT_BLUE_HIGHLIGHT = 'blue-highlight',
  DEFAULT_RED = 'red',
  DEFAULT_RED_HIGHLIGHT = 'red-highlight',
  CUSTOM_COLOR = 'custom',
  CUSTOM_COLOR_HIGHLIGHT = 'custom-highlight',
}

export enum LocationClasses {
  DEFAULT_BLUE = 'blue',
  DEFAULT_RED = 'red',
  START = 'start-location',
  DESTINATION = 'destination-location',
  HOP = 'hop-location',
  DIRECT_CONNECTION = 'direct-connection-location',
  DIRECT_CONNECTION_START = 'direct-connection-start',
  DIRECT_CONNECTION_END = 'direct-connection-end',
}

export enum ConnectionClasses {
  DEFAULT = 'connection-line',
  HOP = 'hop-line',
  HOP_HIGHLIGHT = 'hop-line-highlight',
  HOP_NON_HIGHLIGHT = 'hop-line-non-highlight',
  DIRECT_CONNECTION = 'direct-connection-line',
  DIRECT_CONNECTION_HIGHLIGHT = 'direct-connection-line-highlight',
}

export enum CountryClasses {
  DEFAULT = 'country-default',
  HIGHLIGHT = 'country-highlight',
}

// ===== Vue compent definition =====

export class KVComponent extends Vue {
  // tslint:disable-next-line:semicolon whitespace
  $store!: Store<RootState>;
  // tslint:disable-next-line:align semicolon whitespace
  $t!: (param: string, bindings?: object) => string;
}

// ===== Custom data transformations

export interface PlaceAndLocationStore<T extends Place> {
  placesByLocation: { [uid: string]: StoredArray<T> };
  placesByCountry: { [uid: string]: StoredArray<T> };
  locationsByCountry: { [uid: string]: StoredArray<Location> };
}

export interface LocationQueryOption {
  value: ParsedGeoLocation;
  label: string;
  id?: string;
}
