import { ComponentOptions } from 'vue';
import { KVComponent, RootState } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { setContentView } from '../mixins/set-content-view';
import { mapState } from 'vuex';
import marked from 'marked';

interface Component extends KVComponent {
  factors: {
    pressure: number;
    LOHC: number;
    Methanol: number;
    Ammoniak: number;
    Eisenoxid: number;
  };
  trainFactor: {
    pressure: number;
    LOHC: number;
    Methanol: number;
    Ammoniak: number;
    Eisenoxid: number;
  };
  selectedFactor: { value: string; label: string };
  energy: number;
  netVal: number;
  massPerYear: number;
  resultTrain: number;
  resultWagon: number;

  infoDE: string;
  infoEN: string;
}

const infoDE = `
## Information zu Trägermedien

### Methanol

Chemischer Träger für Wasserstoff und wir heute schon über die Schiene transportiert.

Transporteffizienz: ca. 1kg Methanol /130g Wasserstoff. Ca. 8,5t Wasserstoff je Kesselwagen; Gefahrstoffklasse: 6

### Ammoniak

Chemischer Träger für Wasserstoff und wir heute schon über die Schiene transportiert und bspw. in der Düngemittelindustrie verwendet.

Transporteffizienz: ca. 1kg Ammoniak /177g Wasserstoff. Ca. 9,3t Wasserstoff je Kesselwagen; Gefahrstoffklasse: 3

### LOHC (Liquid Organic Hydrogen Carrier)

Kein Gefahrstoff, Handling wie bei Rohöl.

Transporteffizienz: ca. 1kg LOHC/60g Wasserstoff. 3,9t Wasserstoff je Kesselwagen

### HyCS (Hydrogen Compact Storage)

Modulare Speicher- und Transportlösung. Je nach Containergröße ca. 1,2t Wasserstoff je Container.

### Gasförmig (vers. Druckstufen)

Je nach Containergröße ca. 1,2t Wasserstoff je Container. Gefahrstoffklasse: 2


## Information Berechnungslogik

Der hier berechnete Ganzzug hat eine Länge von 600m.

Je nach Transportform wurde von verschiedenen Wagentypen mit unterschiedlichen Parametern ausgegangen:

* Kesselwagen (für den Transport von Ammoniak, Methanol und LOHC):
  * Länge 16,24m
  * Wagen je Ganzzug: 35

* Flachwagen (für den Transport von HyCS und MEGC Containern):
  * Länge: 26,7m
  * Wagen je Ganzzug: 22
`;

const infoEN = `
## Information on carrier media

### Methanol

Chemical carrier for hydrogen and is already transported by rail today.

Transport efficiency: approx. 1kg methanol /130g hydrogen. Approx. 8.5 tons of hydrogen per tank wagon; hazardous substance class: 6

### Ammonia

Chemical carrier for hydrogen and is already transported by rail and used in the fertilizer industry, e.g.

Transport efficiency: approx. 1kg ammonia /177g hydrogen. Approx. 9.3 tons of hydrogen per tank wagon; hazardous substance class: 3

### LOHC (Liquid Organic Hydrogen Carrier)

No hazardous substance, handling compared to crude oil.

Transport efficiency: approx. 1kg LOHC/60g hydrogen. 3.9 tons of hydrogen per tank wagon

### HyCS (Hydrogen Compact Storage)

Modular storage and transport solution. Depending on container size, approx. 1.2 tons of hydrogen per container.

### Gaseous (various pressure levels)

Depending on container size, approx. 1.2 tons of hydrogen per container. Hazardous substance class: 2


## Information on the calculation logic

The block train calculated here has a length of 600 meters.

Depending on the form of transport, different wagon types with different parameters were assumed:

* Tank wagon (for the transport of ammonia, methanol and LOHC):
  * Length 16.24m
  * Wagons per block train: 35

* Flat wagon (for the transport of HyCS and MEGC containers):
  * Length: 26.7m
  * Wagons per block train: 22
`;

export default {
  mixins: [perfectScrollbarComponent(), setContentView('detail')],

  data() {
    return {
      showInfo: false,
      factors: {
        pressure: 0.416666667,
        LOHC: 0.256410256,
        Methanol: 0.118343195,
        Ammoniak: 0.10731053,
        Eisenoxid: 0.416666667,
      },
      trainFactor: {
        pressure: 0.045454545,
        LOHC: 0.028571429,
        Methanol: 0.028571429,
        Ammoniak: 0.028571429,
        Eisenoxid: 0.045454545,
      },
      selectedFactor: '',
      energy: 0,
      massPerYear: 0,
      netVal: 33.33,
      infoDE: marked.parse(infoDE),
      infoEN: marked.parse(infoEN),
    };
  },

  computed: {
    ...mapState<RootState>({
      uiCloseDetails: state => state.ui.closeDetails,
    }),

    factorOptions(this: Component) {
      return [
        {
          value: 'pressure',
          label: this.$t('Druck'),
        },
        {
          value: 'LOHC',
          label: this.$t('LOHC'),
        },
        {
          value: 'Methanol',
          label: this.$t('Methanol'),
        },
        {
          value: 'Ammoniak',
          label: this.$t('Ammoniak'),
        },
        {
          value: 'Eisenoxid',
          label: this.$t('Eisenoxid'),
        },
      ];
    },

    resultWagon(this: Component) {
      console.log(this.selectedFactor);
      let factor = this.factors[this.selectedFactor.value];
      if (!factor) {
        return 0;
      }
      return this.massPerYear * factor;
    },

    resultTrain(this: Component) {
      let factor = this.trainFactor[this.selectedFactor.value];
      if (!factor) {
        return 0;
      }
      return this.resultWagon * factor;
    },

    infoMarkup(this: Component) {
      return this.$store.state.locale === 'de' ? this.infoDE : this.infoEN;
    },
  },

  watch: {
    uiCloseDetails: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.closeParent();
        }
      },
    },
  },

  methods: {
    closeDetails() {
      this.$emit('close');
    },

    closeParent() {
      this.$emit('closeParent');
    },

    onMassPerYearChange(e) {
      const val = parseFloat(e.target.value);
      this.massPerYear = Math.round(val);
      this.energy = Math.round(this.massPerYear * this.netVal);
    },

    onEnergyChange(e) {
      const val = parseFloat(e.target.value);
      this.energy = Math.round(val);
      this.massPerYear = Math.round(this.energy / this.netVal);
    },
  },
} as ComponentOptions<Component>;
