import { ComponentOptions } from 'vue';
import { KVComponent, RootState, ApplicationMode } from 'types';
import { GLOBAL as GLOBAL_GETTERS } from 'store/getters';
import { mapState, mapGetters } from 'vuex';
import { UI } from 'store//actions';

interface Component extends KVComponent {
  availableLanguages: string[];
  currentLanguage: string;
  showMobileMenu: boolean;
  closeMainContent: boolean;
}

export default {
  data() {
    return {
      appModes: ApplicationMode,
      showMobileMenu: false,
    };
  },

  computed: {
    ...mapState<RootState>({
      availableLanguages: state => state.availableLanguages,
      currentLanguage: state => state.locale,
    }),

    ...mapGetters({
      currentAppMode: GLOBAL_GETTERS.APPLICATION_MODE,
    }),

    closeMainContent: {
      get() {
        return this.$store.state.ui.closeMainContent;
      },

      set(value) {
        this.$store.dispatch(UI.SET_CLOSE_MAIN_CONTENT, value);
      },
    },
  },

  watch: {
    $route(to, from) {
      this.showMobileMenu = false;
    },
  },
} as ComponentOptions<Component>;
