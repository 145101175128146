import { KVComponent, ApplicationMode, SelectorState, RootState } from 'types';
import { ComponentOptions } from 'vue';
import { UI } from 'store/actions';
// tslint:disable-next-line:max-line-length
import { mapGetters, mapState } from 'vuex';
import {
  GLOBAL as GLOBAL_GETTERS,
  SELECTOR as SELECTOR_GETTERS,
} from 'store/getters';
import BookingButton from '../ui/BookingButton.vue';

interface Component extends KVComponent {
  closeMainContent: boolean;
  isConnectionsMode: boolean;
  isListActive: boolean;
  appMode: ApplicationMode;
  currentSelection: SelectorState;
  isSelectorCollapseClosed: boolean;
  resetSelector: boolean;
  currentContentView: string;
  closeDetailView: () => {};
  closeFilterView: () => {};
  openSingleCollapseOnly: (name) => {};
  toggleSelectorCollapse: (direction) => {};
  toggleListCollapse: (direction) => {};
}

export default {
  components: { BookingButton },

  props: {
    results: {
      default: 0,
      type: Number,
    },
    noResults: {
      default: false,
      type: Boolean,
    },
    isListFilterActive: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      isListActive: false,
      isSelectorCollapseClosed: false,
      resetSelector: false,
    };
  },

  mounted() {
    this.$store.dispatch(UI.SET_CLOSE_MAIN_CONTENT, false);
  },

  computed: {
    ...mapGetters({
      appMode: GLOBAL_GETTERS.APPLICATION_MODE,
      currentSelectionTexts: SELECTOR_GETTERS.GET_CURRENT_SELECTION_AS_TEXT,
    }),

    ...mapState<RootState>({
      currentSelection: state => state.selector,
      currentContentView: state => state.ui.currentContentView,
    }),

    isConnectionsMode() {
      return this.appMode === ApplicationMode.CONNECTIONS;
    },

    closeMainContent: {
      get() {
        return this.$store.state.ui.closeMainContent;
      },

      set(value) {
        this.$store.dispatch(UI.SET_CLOSE_MAIN_CONTENT, value);
      },
    },
  },

  watch: {
    currentSelection: {
      deep: true,
      handler() {
        this.isListActive = false;
      },
    },
    currentContentView: 'changeCollapse',
    isListFilterActive: {
      handler(newValue, oldValue) {
        if (newValue === true) {
          // this.showList();
          this.openSingleCollapseOnly('list');
        }
      },
    },
  },

  methods: {
    showList() {
      this.isListActive = true;
      this.openSingleCollapseOnly('list');
    },

    doSelectorReset() {
      this.resetSelector = true;
      this.closeDetailView();

      if (this.isConnectionsMode) {
        this.openSingleCollapseOnly('selector');
      } else {
        this.toggleSelectorCollapse('open');
        this.toggleListCollapse('open');
      }
    },

    checkSelectorCollapseStatus(payload) {
      if (payload.status) {
        this.closeDetailView();
        this.closeFilterView();
      }

      this.isSelectorCollapseClosed = !payload.status;
    },

    checkListCollapseStatus(payload) {
      if (payload.status) {
        this.closeDetailView();
      }
    },

    closeDetailView() {
      if (this.currentContentView === 'detail') {
        this.$store.dispatch(UI.SET_CLOSE_DETAILS, true);
      }
    },

    closeFilterView() {
      this.$store.dispatch(UI.SET_CLOSE_CONNECTION_FILTER, true);
    },

    changeCollapse(newValue, oldValue) {
      if (newValue === 'detail') {
        this.toggleSelectorCollapse('close');
        this.toggleListCollapse('close');
      }

      if (oldValue === 'detail' && newValue === '') {
        this.toggleListCollapse('open');
      }
    },

    openSingleCollapseOnly(collapseName) {
      if (collapseName === 'selector') {
        this.toggleSelectorCollapse('open');
        this.toggleListCollapse('close');
      }

      if (collapseName === 'list') {
        this.toggleSelectorCollapse('close');
        this.toggleListCollapse('open');
      }
    },

    toggleSelectorCollapse(direction) {
      const selectorCollapse = this.$refs.selectorCollapse as any;

      if (direction === 'open') {
        selectorCollapse.open();
      } else {
        selectorCollapse.close();
      }
    },

    toggleListCollapse(direction) {
      const listCollapse = this.$refs.listCollapse as any;

      if (direction === 'open') {
        listCollapse.open();
      } else {
        listCollapse.close();
      }
    },
  },
} as ComponentOptions<Component>;
