import 'element-closest'; // polyfil form HTMLElement.prototype.closest()
// import * as ol from 'openlayers';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import View from 'ol/View';
import Point from 'ol/geom/Point';
import * as Extent from 'ol/extent';
import * as Format from 'ol/format';
import * as Control from 'ol/control';
import * as Interaction from 'ol/interaction';
import { Cluster, Vector as VectorSource, XYZ } from 'ol/source';
import { Vector as VectorLayer, Tile, Layer } from 'ol/layer';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import * as _ from 'lodash';
import {
  ApiConfig,
  PlaceRenderOptions,
  LocationRenderOptions,
  ConnectionRenderOptions,
  MapRenderSpec,
  PlaceClasses,
  ConnectionClasses,
  LocationClasses,
  CountryRenderOptions,
  CountryClasses,
} from 'types';
import { Component, MapEvents } from './map';
import {
  GeoLocation,
  ParsedGeoLocation,
  defaultGeoLocation,
} from 'kv_shared/lib/data-types';
import Overlay from 'ol/Overlay';
import { transform, fromLonLat, toLonLat } from 'ol/proj';
import LineString from 'ol/geom/LineString';
import { trainSvgTemplate } from './mapIconTemplates';
import Circle from 'ol/geom/Circle';
import { Geometry } from 'ol/geom';
import { composeCssTransform } from 'ol/transform';

type GeoData = [number, number];

const mapAttributionEn =
  '&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap contributors</a>';
const mapAttributionDe =
  '&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap-Mitwirkende</a>';
const hydrogenMapAttribution =
  '&copy; <a href="https://www.ehb.eu/" target="_blank">EHB</a> &nbsp;';

export class MapController {
  options: any = {
    enableGeoLocation: false,
  };

  domElement: HTMLElement;
  tooltip: HTMLElement;
  isMobile = false;
  vueComponent: Component;

  startZoom = 5;
  maxZoom = 18;
  minZoom = 4;
  isZooming = false;
  defaultMapViewPadding = [150, 300, 20, 30];
  mapCenterPosition: GeoData = [defaultGeoLocation.lng, defaultGeoLocation.lat]; // center of Germany

  locationLabels: any[] = [];
  lineFeatures: any[] = [];
  placeFeatures: any[] = [];

  visibleCountries: CountryRenderOptions[] = [];

  locMapLayer!: Tile<XYZ>;
  deMapLayer!: Tile<XYZ>;
  enMapLayer!: Tile<XYZ>;

  vectorLinesSource;
  vectorPlacesSource;
  clusteredPlacesLayer;
  connectionLinesLayer;
  countriesLayer!: VectorLayer<VectorSource<Geometry>>;
  hydrogenPipelineLayer!: Layer;

  map!: Map;
  mapView!: View;
  tooltipOverlay;

  connectionLocationCoordinates: ParsedGeoLocation[] = [];

  featureStyles;
  styleVars = {
    'brand-blue': '#004d73',
    'brand-blue-light': 'rgba(60, 145, 185, 1)',
    'brand-blue-lighter': 'rgba(60, 145, 185, 0.8)',
    'brand-orange': '#f08f00',
    // 'brand-red': '#cc0000',
    'brand-red': '#7d2a36',
    'gray-dark': '#444444',
    gray: '#808080',
    'text-color': '#ff0000',
    'brand-highlight': '#f08f00',
    'radius-base': 8,
    'radius-small': 6,
    'radius-cluster': 22,
    'radius-cluster-small': 14,
    'line-base': 2,
    'line-thin': 1,
    'line-thick': 3,
    'border-width': 2,
    'font-cluster': '18px Ubuntu',
    'font-cluster-small': '14px Ubuntu',
  };

  config: ApiConfig = {
    version: '0.0.1',
    backendApiUrl: 'https://railway.tools/api',
    map: {
      loc: 'https://maps.railway.tools/rendered/loc/{z}/{x}/{y}.png',
      de: 'https://maps.railway.tools/rendered/de/{z}/{x}/{y}.png',
      en: 'https://maps.railway.tools/rendered/en/{z}/{x}/{y}.png',
    },
  };

  constructor(component: Component, config: ApiConfig | null, options?) {
    this.options = {
      ...this.options,
      ...options,
    };

    this.config = {
      ...this.config,
      ...config,
    };

    this.domElement = component.$refs.map as HTMLElement;
    // Map Options:
    this.tooltip = component.$refs.tooltip as HTMLElement;

    this.isMobile = window.innerWidth < 768;
    this.prepareMapStyles();
    this.detectGeoLocationAndInitMap();

    this.vueComponent = component;
  }

  detectGeoLocationAndInitMap() {
    if (this.options.enableGeoLocation && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          console.log('got geo data of browser', position);
          this.initializeMap([
            position.coords.longitude,
            position.coords.latitude,
          ]);
        },
        error => {
          console.warn('could not retrieve geo data. error:', error);
          this.initializeMap();
        },
      );
    } else {
      this.initializeMap();
    }

    this.initializeMapEventListener();
  }

  initializeMap(geoData?: GeoData) {
    if (geoData) {
      this.mapCenterPosition = geoData;
    }

    this.vectorLinesSource = new VectorSource();
    this.vectorPlacesSource = new VectorSource();

    this.clusteredPlacesLayer = new VectorLayer({
      source: new Cluster({
        distance: 30,
        source: this.vectorPlacesSource,
      }),
      style: this.getFeatureStyle.bind(this),
      // maxResolution: 2000
    });

    this.connectionLinesLayer = new VectorLayer({
      source: this.vectorLinesSource,
      style: this.getFeatureStyle.bind(this),
    });

    this.countriesLayer = new VectorLayer({
      source: new VectorSource({
        url: './geo.json',
        format: new Format.GeoJSON(),
      }),
      style: feature => {
        const style = new Style({
          fill: new Fill({ color: 'rgba(0,0,0,0.0)' }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.0)',
            width: 0,
          }),
        });

        const countryCode = feature.get('iso_a2');
        const visible = this.visibleCountries.find(
          c => c.countryCode === countryCode,
        );
        if (visible) {
          style.setFill(
            visible.className === CountryClasses.HIGHLIGHT
              ? new Fill({ color: 'rgba(0, 77, 115, 0.4)' })
              : new Fill({ color: 'rgba(170, 170, 170, 0.3)' }),
          );
        }

        return style;
      },
    });

    const svgContainer = document.createElement('div');
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/img/pipeline.svg');
    xhr.addEventListener('load', function() {
      const svg = xhr.responseXML!.documentElement;
      svgContainer.ownerDocument!.importNode(svg, true);
      svgContainer.appendChild(svg);
    });
    xhr.send();

    const width = 1793;
    const height = 2420;
    const svgResolution = 582;
    svgContainer.style.width = width + 'px';
    svgContainer.style.height = height + 'px';
    svgContainer.style.transformOrigin = 'top left';
    svgContainer.style.position = 'absolute';
    svgContainer.style.pointerEvents = 'none';
    svgContainer.className = 'hydrogen-pipeline-layer';

    this.hydrogenPipelineLayer = new Layer({
      render: function(frameState) {
        const scale = svgResolution / frameState.viewState.resolution;
        const center = {
          x: frameState.viewState.center[0] - 1160000,
          y: frameState.viewState.center[1] - 6692000,
        };
        const size = frameState.size;
        const cssTransform = composeCssTransform(
          size[0] / 2,
          size[1] / 2,
          scale,
          scale,
          frameState.viewState.rotation + 0.0,
          -center.x / svgResolution - width / 2,
          center.y / svgResolution - height / 2,
        );
        svgContainer.style.transform = cssTransform;
        svgContainer.style.opacity = this.getOpacity();
        return svgContainer;
      },
      visible: false,
    });

    const interactions = Interaction.defaults({
      altShiftDragRotate: false,
      pinchRotate: false,
    });

    this.mapView = new View({
      center: fromLonLat(this.mapCenterPosition),
      zoom: this.startZoom,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
    });

    this.locMapLayer = new Tile({
      source: new XYZ({
        url: this.config.map.loc,
        attributions: [mapAttributionEn],
        transition: 200,
      }),
      visible: false,
    });

    this.deMapLayer = new Tile({
      source: new XYZ({
        url: this.config.map.de,
        attributions: [mapAttributionDe],
        transition: 200,
      }),
      visible: false,
    });

    this.enMapLayer = new Tile({
      source: new XYZ({
        url: this.config.map.en,
        attributions: [mapAttributionEn],
        transition: 200,
      }),
      visible: false,
    });

    this.map = new Map({
      interactions,
      target: this.domElement,
      layers: [
        this.locMapLayer,
        this.enMapLayer,
        this.deMapLayer,
        this.hydrogenPipelineLayer,
        this.countriesLayer,
        this.connectionLinesLayer,
        this.clusteredPlacesLayer,
      ],
      view: this.mapView,
      controls: Control.defaults({
        attribution: true,
        attributionOptions: {
          collapsible: false,
        },
        rotate: false,
        zoom: false, // use custom buttons instead
      }).extend([]),
    });

    this.tooltipOverlay = new Overlay({
      element: this.tooltip,
      offset: [10, 0],
      positioning: 'bottom-left',
    });

    this.map.addOverlay(this.tooltipOverlay);
  }

  prepareMapStyles() {
    this.featureStyles = {
      default: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          fill: new Fill({
            color: '#ff0000',
          }),
        }),
      }),
      [PlaceClasses.DEFAULT_BLUE]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          fill: new Fill({
            color: this.styleVars['brand-blue'],
          }),
        }),
      }),
      [PlaceClasses.DEFAULT_BLUE_HIGHLIGHT]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          stroke: new Stroke({
            color: this.styleVars['brand-highlight'],
            width: 6,
          }),
          fill: new Fill({
            color: '#ffffff',
          }),
        }),
      }),
      [PlaceClasses.DEFAULT_RED]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          fill: new Fill({
            color: this.styleVars['brand-red'],
          }),
        }),
      }),
      [PlaceClasses.DEFAULT_RED_HIGHLIGHT]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          stroke: new Stroke({
            color: this.styleVars['brand-highlight'],
            width: 6,
          }),
          fill: new Fill({
            color: '#ffffff',
          }),
        }),
      }),
      [ConnectionClasses.DEFAULT]: new Style({
        stroke: new Stroke({
          color: this.styleVars['brand-blue'],
          width: this.styleVars['line-base'],
        }),
      }),
      [ConnectionClasses.HOP]: new Style({
        stroke: new Stroke({
          color: this.styleVars.gray,
          width: this.styleVars['line-thin'],
        }),
      }),
      [ConnectionClasses.HOP_HIGHLIGHT]: new Style({
        stroke: new Stroke({
          color: this.styleVars['brand-blue'],
          width: this.styleVars['line-thick'],
        }),
      }),
      [ConnectionClasses.HOP_NON_HIGHLIGHT]: new Style({
        stroke: new Stroke({
          color: this.styleVars['brand-blue-lighter'],
          width: this.styleVars['line-thin'],
        }),
      }),
      [LocationClasses.HOP]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-small'],
          fill: new Fill({
            color: this.styleVars['brand-blue-light'],
          }),
        }),
      }),
      [LocationClasses.DIRECT_CONNECTION]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          fill: new Fill({
            color: this.styleVars['brand-orange'],
          }),
          stroke: new Stroke({
            color: this.styleVars['brand-blue'],
            width: this.styleVars['border-width'],
          }),
        }),
      }),
      [LocationClasses.DIRECT_CONNECTION_START]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          fill: new Fill({
            color: this.styleVars['brand-orange'],
          }),
          stroke: new Stroke({
            color: this.styleVars['brand-blue'],
            width: this.styleVars['border-width'],
          }),
        }),
      }),
      [LocationClasses.DIRECT_CONNECTION_END]: new Style({
        image: new CircleStyle({
          radius: this.styleVars['radius-base'],
          fill: new Fill({
            color: this.styleVars['brand-orange'],
          }),
        }),
      }),
      [ConnectionClasses.DIRECT_CONNECTION]: new Style({
        stroke: new Stroke({
          color: this.styleVars['brand-orange'],
          width: this.styleVars['line-base'],
        }),
      }),
      [ConnectionClasses.DIRECT_CONNECTION_HIGHLIGHT]: new Style({
        stroke: new Stroke({
          color: this.styleVars['brand-orange'],
          width: this.styleVars['line-thick'],
        }),
      }),
    };
  }

  getFeatureStyle(clusterFeature) {
    const nrOfFeatures = clusterFeature.get('features')
      ? clusterFeature.get('features').length
      : 1;

    if (nrOfFeatures > 1) {
      let directFeaturesType = true;
      let clusterStyleType = 'direct';

      _.every(clusterFeature.get('features'), (feature: any) => {
        if (feature.get('className').indexOf('direct') === -1) {
          clusterStyleType = 'normal';
          directFeaturesType = false;
          return false;
        }
        return true;
      });

      let style = this.featureStyles[
        'cluster-' + clusterStyleType + '-' + String(nrOfFeatures)
      ];

      if (!style) {
        const fillColor = directFeaturesType
          ? this.styleVars['brand-orange']
          : this.styleVars['brand-blue'];

        style = new Style({
          image: new CircleStyle({
            radius: this.isMobile
              ? this.styleVars['radius-cluster-small']
              : this.styleVars['radius-cluster'],
            fill: new Fill({
              color: fillColor,
            }),
          }),
          text: new Text({
            text: String(nrOfFeatures),
            font: this.isMobile
              ? this.styleVars['font-cluster-small']
              : this.styleVars['font-cluster'],
            fill: new Fill({
              color: '#ffffff',
            }),
          }),
        });
        this.featureStyles[
          'cluster-' + clusterStyleType + '-' + String(nrOfFeatures)
        ] = style;
      }

      return style;
    } else {
      const feature = clusterFeature.get('features')
        ? clusterFeature.get('features')[0]
        : clusterFeature;
      const className: string = feature.get('className') || '';
      const color = feature.get('color');

      // check for custom color
      if (color) {
        if (className.indexOf('highlight') >= 0) {
          return new Style({
            image: new CircleStyle({
              radius: this.styleVars['radius-base'],
              stroke: new Stroke({
                color,
                width: 6,
              }),
              fill: new Fill({
                color: '#ffffff',
              }),
            }),
          });
        } else {
          return new Style({
            image: new CircleStyle({
              radius: this.styleVars['radius-base'],
              fill: new Fill({
                color,
              }),
            }),
          });
        }
      } else {
        return this.featureStyles[className] || this.featureStyles.default;
      }
    }
  }

  initializeMapEventListener() {
    this.map.getView().on('propertychange', event => {
      switch (event.key) {
        case 'resolution':
          this.setMapViewZoomClasses();
          break;
        case 'center':
          this.setMapViewZoomClasses();
          break;
      }
    });

    this.map.on('moveend', () => {
      if (this.isZooming) {
        this.isZooming = false;
        this.clusteredPlacesLayer.setVisible(true);
        this.connectionLinesLayer.setVisible(true);
      }
    });

    this.map.on('singleclick', event => {
      this.map.forEachFeatureAtPixel(event.pixel, clusterFeature => {
        if (
          clusterFeature.get('features') &&
          clusterFeature.get('features').length > 1 &&
          this.clusterCanExpand(clusterFeature.get('features'))
        ) {
          const features = clusterFeature.get('features');
          this.zoomIntoClusterFeature(features);
        } else {
          const feature = clusterFeature.get('features')
            ? clusterFeature.get('features')[0]
            : clusterFeature;

          const countryCode = feature.get('iso_a2');
          if (countryCode) {
            const lonlat = transform(
              event.coordinate,
              'EPSG:3857',
              'EPSG:4326',
            );
            const lng = lonlat[0];
            const lat = lonlat[1];
            this.vueComponent.$emit(MapEvents.COUNTRY_CLICKED, {
              countryCode,
              position: { lng, lat },
            });
          } else if (feature.get('selectable')) {
            this.vueComponent.$emit(MapEvents.PLACE_CLICKED, {
              uid: feature.get('id'),
            });
          }
        }
        return true;
      });
    });

    this.map.on('pointermove', event => {
      let overFeature = false;
      let showTooltip = false;
      let tooltipClass = '';
      let tooltipText = '';

      this.map.forEachFeatureAtPixel(event.pixel, clusterFeature => {
        if (
          clusterFeature.get('features') &&
          clusterFeature.get('features').length > 1
        ) {
          const features = clusterFeature.get('features');
          const names: string[] = [];
          for (const feature of features) {
            if (feature.get('type') === 'point') {
              names.push(feature.get('name'));
            }
          }
          if (names.length) {
            this.tooltipOverlay.setPosition(event.coordinate);
            tooltipText = names.join('<br>');
            tooltipClass = 'terminal';
            showTooltip = true;
          }
          overFeature = true;
          return true;
        } else if (
          !clusterFeature.get('features') ||
          clusterFeature.get('features').length === 1
        ) {
          const feature = clusterFeature.get('features')
            ? clusterFeature.get('features')[0]
            : clusterFeature;
          const featureType = feature && feature.get('type');
          if (featureType === 'point') {
            this.tooltipOverlay.setPosition(event.coordinate);
            tooltipText = feature.get('name');
            tooltipClass = feature.get('className');
            showTooltip = true;
          }

          if (feature.get('selectable') === true) {
            overFeature = true;
            return true;
          }
        }
      });

      (this.map.getTarget() as HTMLElement).style.cursor = overFeature
        ? 'pointer'
        : '';
      if (showTooltip) {
        this.tooltip.innerHTML = tooltipText;
        this.tooltip.classList.add('visible');
        this.tooltip.classList.add(tooltipClass);
      } else {
        this.tooltip.className = '';
      }
    });

    // Overlay click events, on label an close buttons
    const overlayContainer = document.querySelector(
      '.ol-overlaycontainer-stopevent',
    );
    if (overlayContainer) {
      overlayContainer.addEventListener('click', ev => {
        // Label buttons
        const label = (ev.target as HTMLElement).closest(
          '.overlay-label .btn-label',
        );

        if (
          label &&
          label.parentElement &&
          label.classList.contains('is-selectable')
        ) {
          this.vueComponent.$emit(MapEvents.LOCATION_CLICKED, {
            uid: label.parentElement.id,
          });
        }

        // Close buttons
        const close = (ev.target as HTMLElement).closest(
          '.overlay-label .btn-close',
        );

        if (close) {
          const id = close.parentElement && close.parentElement.id;
          this.vueComponent.$emit(MapEvents.LOCATION_CLOSED, {
            uid: id,
          });
        }

        // Close buttons
        const train = (ev.target as HTMLElement).closest(
          '.overlay-train-icon .btn-label',
        ) as HTMLElement;

        if (train) {
          const data = train.dataset.clickData;
          this.vueComponent.$emit(MapEvents.COUNTRY_ITEM_CLICKED, {
            data: data && JSON.parse(data),
          });
        }
      });
    }
  }

  resetMapView(animate = false) {
    this.hideTooltip();

    if (this.mapView) {
      this.mapView.animate({
        center: fromLonLat(this.mapCenterPosition),
        zoom: this.startZoom,
        duration: animate ? 2000 : 0,
      });
    }
  }

  hideTooltip() {
    this.tooltip.classList.remove('visible');
  }

  clusterCanExpand(features) {
    let canExpand = true;

    // If all clustered features have the same geo coordinates, we should not expand any further
    const positions = _.map(features, (feature: any) => {
      return feature.get('position');
    });

    const latitudes = _.map(positions, (pos: ParsedGeoLocation) => {
      return pos.lat.toFixed(3);
    });

    const longitudes = _.map(positions, (pos: ParsedGeoLocation) => {
      return pos.lng.toFixed(3);
    });

    if (
      _.uniq(latitudes).length === 1 &&
      _.uniq(longitudes).length === 1 &&
      this.mapView.getZoom()! >=
        (this.maxZoom - this.minZoom) / 2 + this.minZoom
    ) {
      canExpand = false;
    }

    return canExpand;
  }

  setMapViewZoomClasses() {
    const zoom = this.mapView.getZoom()!;
    if (zoom <= 6) {
      this.domElement.classList.add('zoom-small');
    } else {
      this.domElement.classList.remove('zoom-small');
    }

    if (zoom >= 9.2 && zoom < 10) {
      this.domElement.classList.add('zoom-lg');
    } else {
      this.domElement.classList.remove('zoom-lg');
    }

    if (zoom >= 10) {
      this.domElement.classList.add('zoom-xl');
    } else {
      this.domElement.classList.remove('zoom-xl');
    }
  }

  featureHasClass(feature, className) {
    if (feature && feature.get('className').indexOf(className) > -1) {
      return true;
    }
    return false;
  }

  zoomIntoClusterFeature(features: any[]) {
    if (!features || features.length === 0) {
      return;
    }

    const extent = Extent.createEmpty();

    features.forEach((feature: any) => {
      const featureGeometry = feature.getGeometry();
      Extent.extend(extent, featureGeometry.getExtent());
    });

    this.animateMapToExtent(extent);
  }

  zoomIntoCoordinates(coordinates: ParsedGeoLocation[]) {
    if (!coordinates || coordinates.length === 0) {
      return;
    }

    const coords: number[][] = [];

    coordinates.forEach(coord => {
      const c = fromLonLat([coord.lng, coord.lat]);
      coords.push(c);
    });

    const extent = Extent.boundingExtent(coords);
    this.animateMapToExtent(extent);
  }

  animateMapToExtent(extent, padding?) {
    padding = padding || this.defaultMapViewPadding;
    this.hideTooltip();

    setTimeout(() => {
      this.mapView.fit(extent, {
        size: this.map.getSize(),
        padding,
        duration: 1500,
        maxZoom: this.maxZoom, // set excplicit zoom level to prevent getZoom() = undefined
      });
    }, 30); // Animate zoom in different loop cycle to prevent weird rendeing behavior
  }

  private addPlace(place: PlaceRenderOptions) {
    const pos = this.parseGeoLocation(place.position as GeoLocation);
    if (pos.lng && pos.lat) {
      const feature = new Feature({
        ...place,
        id: place.uid,
        type: 'point',
        geometry: new Point(fromLonLat([pos.lng, pos.lat])),
      });
      this.placeFeatures.push(feature);
    }
  }

  getLabelOverlayPosition(options: LocationRenderOptions) {
    let labelPosition = {} as ParsedGeoLocation;

    if (options.placePositions && options.placePositions.length > 1) {
      const center = this.getCenterOfPositions(
        options.placePositions.map(this.parseGeoLocation.bind(this)),
      );

      if (center.length) {
        labelPosition.lng = center[0];
        labelPosition.lat = center[1];
      }
    } else if (options.position) {
      labelPosition = this.parseGeoLocation(options.position as GeoLocation);
      // We need to change the label position slightly to the north, so the ol-overlay-container DOM element will not
      // overlay the feature (point) on the map and thus making the feature unclickable.
      labelPosition.lat += 0.001;
    }

    return labelPosition;
  }

  getCenterOfPositions(positions: ParsedGeoLocation[]) {
    const coordinates: any[] = [];

    positions.forEach(pos => {
      coordinates.push([pos.lng, pos.lat]);
    });

    const extent = Extent.boundingExtent(coordinates);
    return Extent.getCenter(extent);
  }

  parseGeoLocation(location?: GeoLocation | ParsedGeoLocation) {
    const loc: ParsedGeoLocation = {
      lng: this.mapCenterPosition[0],
      lat: this.mapCenterPosition[1],
    };

    if (location && location.lng) {
      loc.lng = parseFloat(location.lng as string);
    }

    if (location && location.lat) {
      loc.lat = parseFloat(location.lat as string);
    }

    return loc;
  }

  addLocation(options: LocationRenderOptions) {
    // Set position of the overlay label to be in the center of the terminals
    const labelPosition = this.getLabelOverlayPosition(options);

    const config = {
      ...options,
      position: fromLonLat([labelPosition.lng, labelPosition.lat]),
    };

    this.addLabelOverlay(config);

    // Cache
    this.locationLabels.push(config);
  }

  addLabelOverlay(options) {
    const container = document.createElement('div');
    container.id = options.uid;
    container.className = 'overlay-label ' + options.className;

    const close = document.createElement('button');
    close.innerText = '×';
    close.className = 'btn-close';

    const label = document.createElement('button');
    label.className = 'btn-label';
    label.innerText = options.name;

    if (options.selectable) {
      label.classList.add('is-selectable');
      label.dataset.clickData = options.clickData;
    }

    container.appendChild(label);
    container.appendChild(close);

    let stopEvent = true;

    if (options.className === 'hop-location') {
      stopEvent = false;
    }

    const popup = new Overlay({
      id: options.uid,
      element: container,
      insertFirst: false,
      offset: [0, 0],
      positioning: 'center-center',
      stopEvent,
    });

    popup.setPosition(options.position);

    this.map.addOverlay(popup);
  }

  addTrainIconOverlay(options: PlaceRenderOptions & { country: string }) {
    const container = document.createElement('div');
    const id = options.country + options.uid;
    container.id = id;
    container.className = 'overlay-train-icon ' + options.className;

    const icon = document.createElement('button');
    icon.className = 'btn-label';
    icon.innerHTML = trainSvgTemplate;

    if (options.name) {
      icon.setAttribute('aria-label', options.name);
      icon.setAttribute('title', options.name);
    }

    if (options.selectable) {
      icon.classList.add('is-selectable');
      icon.dataset.clickData = JSON.stringify({
        uid: options.uid,
        country: options.country,
      });
    }

    if (options.color) {
      icon.style.color = options.color;
    }

    container.appendChild(icon);

    const popup = new Overlay({
      id,
      element: container,
      insertFirst: false,
      offset: [0, 0],
      positioning: 'center-center',
      stopEvent: true,
    });

    const pos = this.parseGeoLocation(options.position);

    popup.setPosition(fromLonLat([pos.lng, pos.lat]));

    this.map.addOverlay(popup);
  }

  addConnectionLine(options: ConnectionRenderOptions) {
    const startLoc = this.parseGeoLocation(options.from as GeoLocation);
    const endLoc = this.parseGeoLocation(options.to as GeoLocation);
    const startLocationCoordinates = fromLonLat([startLoc.lng, startLoc.lat]);
    const destinationLocationCoordinates = fromLonLat([endLoc.lng, endLoc.lat]);
    const lineFeature = new Feature({
      ...options,
      id: options.uid,
      geometry: new LineString([
        startLocationCoordinates,
        destinationLocationCoordinates,
      ]),
    });

    this.connectionLocationCoordinates.push(startLoc, endLoc);
    this.lineFeatures.push(lineFeature);
  }

  setPlaces(places?: PlaceRenderOptions[]) {
    if (!places) {
      return;
    }

    for (const place of places) {
      this.addPlace(place);
    }

    // Change Cluster Distance for very large amounts of features
    if (places.length > 1500) {
      this.clusteredPlacesLayer.getSource().setDistance(50);
    } else {
      this.clusteredPlacesLayer.getSource().setDistance(30);
    }
  }

  removeAllLabelOverlays() {
    _.each(document.querySelectorAll('.overlay-label'), overlay => {
      this.removeLabelOverlay(overlay.id);
    });
    _.each(document.querySelectorAll('.overlay-train-icon'), overlay => {
      this.removeLabelOverlay(overlay.id);
    });

    this.locationLabels = [];
  }

  removeLabelOverlay(uid?: string | null) {
    if (uid) {
      const popup = this.map.getOverlayById(uid);
      this.map.removeOverlay(popup);
      this.locationLabels = _.reject(this.locationLabels, label => {
        return label.uid === uid;
      });
    }
  }

  getCountryCenter(countryCode: string) {
    const feature = this.countriesLayer
      .getSource()!
      .getFeatures()
      .find(f => f.get('iso_a2') === countryCode);

    if (feature) {
      const geo = feature.getGeometry()!;
      const extent = geo.getExtent();
      return toLonLat(Extent.getCenter(extent));
    }
  }

  setCountries(countries: CountryRenderOptions[]) {
    this.visibleCountries = countries;

    for (const country of countries) {
      if (country.items) {
        const feature = this.countriesLayer
          .getSource()!
          .getFeatures()
          .find(f => f.get('iso_a2') === country.countryCode);

        if (feature) {
          let givenCenter: ParsedGeoLocation;
          const center =
            country.center && country.center.lat && country.center.lng
              ? ((givenCenter = this.parseGeoLocation(country.center)),
                [givenCenter.lng, givenCenter.lat])
              : this.getCountryCenter(country.countryCode)!;

          country.items.forEach((item, i) => {
            this.addTrainIconOverlay({
              ...item,
              name: item.name!,
              position: {
                lng: center[0],
                lat: center[1] - country.items!.length / 3 + 0.75 * i,
              },
              country: country.countryCode,
            });
          });
        }
      }
    }
  }

  cleanMap() {
    // Make sure only the label overlays will be cleared!
    // The tooltip overlay is added only once in the template and should not be removed.
    this.removeAllLabelOverlays();

    this.vectorLinesSource.clear();
    this.vectorPlacesSource.clear();

    this.placeFeatures = [];
    this.lineFeatures = [];
    this.connectionLocationCoordinates = [];
    this.visibleCountries = [];
  }

  updateMap() {
    this.vectorLinesSource.addFeatures(this.lineFeatures);
    this.vectorPlacesSource.addFeatures(this.placeFeatures);
    this.countriesLayer.getSource()!.dispatchEvent('change');
  }

  /// === Public interface ===

  setLanguage(lang: string) {
    switch (lang) {
      case 'de':
        this.locMapLayer && this.locMapLayer.setVisible(false);
        this.deMapLayer && this.deMapLayer.setVisible(true);
        this.enMapLayer && this.enMapLayer.setVisible(false);
        break;
      case 'en':
        this.locMapLayer && this.locMapLayer.setVisible(false);
        this.deMapLayer && this.deMapLayer.setVisible(false);
        this.enMapLayer && this.enMapLayer.setVisible(true);
        break;
      default:
        this.locMapLayer && this.locMapLayer.setVisible(true);
        this.deMapLayer && this.deMapLayer.setVisible(false);
        this.enMapLayer && this.enMapLayer.setVisible(false);
    }
  }

  zoom(zoomStep: number) {
    this.mapView.animate({ zoom: this.mapView.getZoom()! + zoomStep });
  }

  zoomToCountry = _.debounce((posOrCountryCode: string | GeoLocation) => {
    let center: number[] | undefined;
    if (typeof posOrCountryCode === 'string') {
      center = this.getCountryCenter(posOrCountryCode);
    } else {
      const pos = this.parseGeoLocation(posOrCountryCode);
      center = [pos.lng, pos.lat];
    }
    if (center) {
      this.mapView.fit(new Circle(fromLonLat(center), 1000000), {
        padding: this.defaultMapViewPadding,
        duration: 1500,
        maxZoom: this.startZoom,
      });
    }
  }, 240);

  zoomToWorld = _.debounce(() => {
    this.resetMapView(true);
  }, 240); // make shure zooming comes after rendering

  zoomToPlaces = _.debounce(() => {
    const features = this.vectorPlacesSource.getFeatures();
    this.zoomIntoClusterFeature(features);
  }, 240); // make shure zooming comes after rendering

  zoomToConnections = _.debounce(() => {
    this.zoomIntoCoordinates(this.connectionLocationCoordinates);
  }, 240); // make shure zooming comes after rendering

  showHydrogenPipelines(visible: boolean) {
    this.hydrogenPipelineLayer.setVisible(visible);
    if (visible) {
      this.deMapLayer
        .getSource()!
        .setAttributions([hydrogenMapAttribution, mapAttributionDe]);
      this.enMapLayer
        .getSource()!
        .setAttributions([hydrogenMapAttribution, mapAttributionEn]);
      this.locMapLayer
        .getSource()!
        .setAttributions([hydrogenMapAttribution, mapAttributionEn]);
    } else {
      this.deMapLayer.getSource()!.setAttributions([mapAttributionDe]);
      this.enMapLayer.getSource()!.setAttributions([mapAttributionEn]);
      this.locMapLayer.getSource()!.setAttributions([mapAttributionEn]);
    }
  }

  render = _.debounce((spec: MapRenderSpec) => {
    this.cleanMap();
    this.setPlaces(spec.places);

    if (spec.connections) {
      for (const con of spec.connections) {
        this.addConnectionLine(con);
      }
    }

    if (spec.locations) {
      for (const loc of getUniqueLocationOptions(spec.locations)) {
        this.addLocation(loc);
      }
    }

    if (spec.countries) {
      this.setCountries(spec.countries);
    }

    this.updateMap();
  }, 200);
}

function getUniqueLocationOptions(options: LocationRenderOptions[]) {
  // filter out duplicate Location IDs
  return Object.values(
    options.reduce(
      (obj, opt) => {
        const old = obj[opt.uid];
        // make sure that start and destination locations are not overwritten
        if (
          !(
            old &&
            (old.className === LocationClasses.DESTINATION ||
              old.className === LocationClasses.START)
          )
        ) {
          obj[opt.uid] = opt;
        }
        return obj;
      },
      {} as { [id: string]: LocationRenderOptions },
    ),
  );
}
