var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operator-trains"},[_c('header',{staticClass:"train-header"},[_c('div',{staticClass:"train-operator"},[_c('button',{staticClass:"icon",attrs:{"title":_vm.$t('Operator-Infos öffnen')},on:{"click":function($event){return _vm.openOperatorDetails(_vm.data.operator.uid)}}}),_vm._v(" "),_c('span',{staticClass:"operator-name",attrs:{"title":_vm.data.operator.name}},[_vm._v(_vm._s(_vm.data.operator.name))])]),_vm._v(" "),_c('div',{staticClass:"train-selector"},[_c('button',{class:['icon', 'train-data', _vm.marker.class],attrs:{"title":_vm.$t('Zug-Infos öffnen')},on:{"click":function($event){return _vm.openTrainDetails(_vm.currentTrain.train.uid)}}}),_vm._v(" "),_c('button',{staticClass:"icon skip-back",attrs:{"type":"button","disabled":_vm.operatorTrainCurrentIndex === 0},on:{"click":function($event){_vm.operatorTrainCurrentIndex--}}}),_vm._v(" "),_c('span',{staticClass:"train-index"},[_vm._v(_vm._s(_vm.$t('Zug'))+" "+_vm._s(_vm.operatorTrainCurrentIndex + 1)+" /\n        "+_vm._s(_vm.data.trains.length))]),_vm._v(" "),_c('button',{class:['icon', 'skip-next'],attrs:{"type":"button","disabled":_vm.operatorTrainCurrentIndex === _vm.data.trains.length - 1},on:{"click":function($event){_vm.operatorTrainCurrentIndex++}}})])]),_vm._v(" "),_c('div',{staticClass:"train"},[_c('div',{staticClass:"terminals departure"},_vm._l((_vm.currentTrain.terminalsFrom),function(operatorFromTerminal){return _c('div',{key:operatorFromTerminal.uid,staticClass:"terminal",on:{"click":function($event){return _vm.openTerminalDetails(operatorFromTerminal.uid)}}},[_c('div',{staticClass:"terminal-name"},[_vm._v(_vm._s(operatorFromTerminal.name))]),_vm._v(" "),_c('div',{staticClass:"schedule"},[_vm._v("\n          "+_vm._s(_vm.getTerminalTiming(
              'delivery',
              _vm.currentTrain,
              operatorFromTerminal.uid
            ))+"\n        ")])])}),0),_vm._v(" "),_c('div',{staticClass:"terminals arrival"},_vm._l((_vm.currentTrain.terminalsTo),function(operatorToTerminal){return _c('div',{key:operatorToTerminal.uid,staticClass:"terminal",attrs:{"title":_vm.$t('Terminal-Infos öffnen')},on:{"click":function($event){return _vm.openTerminalDetails(operatorToTerminal.uid)}}},[_c('div',{staticClass:"terminal-name"},[_vm._v(_vm._s(operatorToTerminal.name))]),_vm._v(" "),_c('div',{staticClass:"schedule"},[_vm._v("\n          "+_vm._s(_vm.getTerminalTiming(
              'collection',
              _vm.currentTrain,
              operatorToTerminal.uid
            ))+"\n        ")])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }