import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';

interface Component extends KVComponent {}

export default {
  props: {
    tooltip: {
      default: '',
      type: String,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
} as ComponentOptions<Component>;
