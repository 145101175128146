function createItemActions(prefix: string) {
  return {
    LOAD_COMPLETE_LIST: prefix + '_load_complete_list',
    LOAD_ITEM: prefix + '_load_by_id',

    API_GET_LIST: prefix + '_api_get_list',
    API_GET_BY_ID: prefix + '_api_get_by_id',
  };
}

function createSelectionListActions(prefix: string) {
  return {
    SELECT_ITEM: prefix + '_select_item',
    RESET_ITEM: prefix + '_reset_item',
  };
}

export const GLOBAL = {
  ADD_CONFIG: 'global_add_api_config',
  CHANGE_LANGUAGE: 'global_change_language',
};

export const UI = {
  SHOW_LOADER: 'ui_show_loader',
  HIDE_LOADER: 'ui_hide_loader',
  SET_CONTENT_VIEW: 'ui_set_content_view',
  SET_MOUSE_DRAGGING_STATE: 'ui_set_mouse_dragging_state',
  SET_CLOSE_DETAILS: 'ui_set_close_details',
  SET_CLOSE_MAIN_CONTENT: 'ui_set_close_main_content',
  SET_CLOSE_CONNECTION_FILTER: 'ui_set_close_connection_filter',
  SET_WINDOW_SIZE: 'ui_window_size',
};

export const API = {
  GET: 'api_get',
};

export const MISC = {
  GET_IMPRINT: 'misc_get_imprint',
  HIDE_COOKIE_NOTICE: 'misc_hide_cookie_notice',
  GET_COOKIE_NOTICE_VISIBILITY: 'misc_get_cookie_notice_visibility',
  GET_MATOMO_TRACKING: 'misc_get_matomo_tracking',
  SET_MATOMO_TRACKING: 'misc_set_matomo_tracking',
};

export const LOCATION = {
  ...createItemActions('location'),
  LOAD_BY_COUNTRY: 'location_load_by_country',
};

export const COUNTRY = {
  ...createItemActions('country'),
};

export const COMPANY = {
  ...createItemActions('company'),
  GET_CONFIG_BY_TYPE: 'company_get_config_by_type',

  API_GET_CONFIG_BY_TYPE: 'company_api_get_config_by_type',
};

export const CONNECTION = {
  ...createItemActions('connection'),
  ...createSelectionListActions('connection'),
  LOAD_TERMINALS_WITH_TRAINS: 'connection_load_terminals_with_trains',
  SEARCH_CONNECTIONS: 'connection_search_connections',
  SEARCH_DIRECT_CONNECTIONS: 'connection_search_direct_connections',

  SHOW_TRAIN_DETAILS: 'connection_show_train_details',
  SHOW_TERMINAL_DETAILS: 'connection_show_terminal_details',
  SHOW_OPERATOR_DETAILS: 'connection_show_operator_details',
  SET_DIRECT_CONNECTIONS_VISIBILITY:
    'connection_set_direct_connections_visibility',
  RESET_SEARCH_RESULTS: 'connection_reset_search_results',
  RESET_DIRECT_CONNECTIONS: 'connection_reset_direct_connections',
  LOAD_ALL_TRAINS_FOR_SEARCH_RESULTS:
    'connections_load_all_trains_for_search_results',

  SET_FILTER: 'connection_set_filter',
  SET_USABLE_UNITS: 'connection_set_usable_units',

  API_GET_SELECTED_CONNECTIONS: 'connection_api_get_selected_connections',
  API_GET_DIRECT_CONNECTIONS: 'connection_api_get_direct_connections',
  API_GET_USED_TERMINALS: 'connection_api_get_used_terminals',
};

export const TRAIN = {
  ...createItemActions('train'),
  SELECT_TRAIN: 'train_select_item',
  LOAD_BY_OPERATOR: 'train_load_by_operator',
};

export const LOADING_POINT = {
  ...createItemActions('loadingpoint'),
  ...createSelectionListActions('loadingpoint'),
  LOAD_BY_LOCATION: 'loadingpoint_load_by_location',
  LOAD_COMPANY_TYPES: 'loadingpoint_load_company_types',

  API_GET_COMPANY_TYPES: 'loadingpoint_api_get_company_types',
};

export const TERMINAL = {
  ...createItemActions('terminal'),
  ...createSelectionListActions('terminal'),
  LOAD_BY_LOCATION: 'terminal_load_by_location',
  SET_HYDROGEN_PIPELINES_VISIBILITY:
    'terminal_set_hydrogen_pipelines_visibility',
};

export const NEWS = {
  ...createItemActions('news'),
};

export const SELECTOR = {
  SELECT_START_COUNTRY: 'selector_select_start_country',
  SELECT_START_POS_QUERY: 'selector_select_start_pos_query',
  SELECT_START_RADIUS: 'selector_select_start_radius',
  SELECT_START_POSITION: 'selector_select_start_position',
  SELECT_START_LOCATIONS: 'selector_select_start_location',
  UPDATE_START_POSITION_OPTIONS: 'selector_update_start_position_options',
  SELECT_DESTINATION_COUNTRY: 'selector_select_destination_country',
  SELECT_DESTINATION_POS_QUERY: 'selector_select_destination_pos_query',
  SELECT_DESTINATION_RADIUS: 'selector_select_destination_radius',
  SELECT_DESTINATION_POSITION: 'selector_select_destination_position',
  SELECT_DESTINATION_LOCATIONS: 'selector_select_destination_location',
  UPDATE_DESTINATIOIN_POSITION_OPTIONS:
    'selector_update_destination_position_options',
  RESET_SEARCH: 'selector_reset_search',
  UPDATE_ROUTE_QUERY: 'selector_update_route_query',
  PROCESS_ROUTE_QUERY: 'selector_process_route_query',
  SET_CURRENT_LOCATION_STORE: 'selector_set_current_location_store',
  API_GET_POSITIONS: 'selector_load_positions',
};

export const MAP = {
  RENDER: 'map_render',
  ZOOM_IN: 'map_zoom_in',
  ZOOM_OUT: 'map_zoom_out',
  ZOOM_TO_WORLD: 'map_zoom_to_world',
  ZOOM_TO_PLACES: 'map_zoom_to_places',
  ZOOM_TO_CONNECTIONS: 'map_zoom_to_connections',
  ZOOM_TO_START_COUNTRY: 'map_zoom_to_start_country',
  REGISTER_INTERACTION: 'map_register_interaction',
};

export const CONVEYANCE = {
  ...createItemActions('conveyance'),
};

export const WAGON_LOAD = {
  ...createItemActions('wagon_load'),
  ...createSelectionListActions('wagon_load'),
};
