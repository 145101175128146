import { mapState, mapGetters } from 'vuex';
import { RootState, KVComponent, ApplicationMode } from 'types';
import { ComponentOptions } from 'vue';
import { GLOBAL as GLOBAL_GETTERS, UI as UI_GETTERS } from 'store/getters';
import { LOCATION, COUNTRY, TERMINAL, COMPANY, UI } from 'store/actions';
import CookieBlock from 'components/ui/CookieBlock.vue';

interface Component extends KVComponent {
  setWindowSize: () => {};
  windowResizeHandler: () => {};
  resizeTimer: any;
}

export default {
  components: {
    CookieBlock,
  },

  created() {
    this.$store.dispatch(LOCATION.LOAD_COMPLETE_LIST);
    this.$store.dispatch(COUNTRY.LOAD_COMPLETE_LIST);
    this.$store.dispatch(TERMINAL.LOAD_COMPLETE_LIST);
    this.$store.dispatch(COMPANY.LOAD_COMPLETE_LIST);
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.windowResizeHandler);
      this.setWindowSize();
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.windowResizeHandler);
  },

  computed: {
    ...mapState<RootState>({
      showLoader: state => state.ui.showLoader,
      currentContentView: state => state.ui.currentContentView,
      uiCloseDetails: state => state.ui.closeDetails,
      uiWindowSize: state => state.ui.windowSize,
    }),

    ...mapGetters({
      uiHasMainContentMobileScrollbar:
        UI_GETTERS.HAS_MAIN_CONTENT_MOBILE_SCROLLBAR,
    }),

    contentViewClass() {
      // Set main content view class at the app root element in order to do more advanced styling (mobile)
      return this.currentContentView
        ? 'has-' + this.currentContentView + '-view'
        : '';
    },

    appModeClasses() {
      const mode = this.$store.getters[GLOBAL_GETTERS.APPLICATION_MODE];
      return {
        'mode-start': mode === ApplicationMode.START,
        'mode-kv-connections': mode === ApplicationMode.CONNECTIONS,
        'mode-kv-terminals': mode === ApplicationMode.TERMINALS,
        'mode-hydrogen-terminals': mode === ApplicationMode.HYDROGEN_TERMINALS,
        'mode-loading-points': mode === ApplicationMode.LOADING_POINTS,
        'mode-wagon-load': mode === ApplicationMode.WAGON_LOAD,
        'mode-about': mode === ApplicationMode.ABOUT,

        // Development only
        'mode-styleguide': mode === ApplicationMode.STYLEGUIDE,
      };
    },

    windowSizeClass() {
      return {
        'main-content-mobile-scrollbar': this.uiHasMainContentMobileScrollbar,
      };
    },
  },

  methods: {
    windowResizeHandler() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.setWindowSize();
      }, 250);
    },

    setWindowSize() {
      const windowSize = {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      };
      this.$store.dispatch(UI.SET_WINDOW_SIZE, windowSize);
    },
  },
} as ComponentOptions<Component>;
