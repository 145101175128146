import { ComponentOptions } from 'vue';
import { mapState } from 'vuex';
import { KVComponent, RootState } from 'types';

interface Component extends KVComponent {
  currentLanguage: string;
}

export default {
  data() {
    return {};
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
    }),
  },
} as ComponentOptions<Component>;
