import { CONVEYANCE as ACTIONS, API } from 'store/actions';
import { CONVEYANCE as MUTATIONS } from 'store/mutations';
// tslint:disable-next-line:max-line-length
import { CONVEYANCE as GETTERS, GLOBAL as GLOBAL_GETTERS } from 'store/getters';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-store';
import {
  ConveyanceState,
  RootState,
  ApplicationMode,
  StoredArray,
  StoredHashmap,
} from 'types';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { defaultValueOnHttpError } from 'utils/helpers';
import { Company, ConveyanceArea } from 'kv_shared/lib/data-types';

const state: ConveyanceState = {
  ...defaultItemState(),
};

const getters: GetterTree<ConveyanceState, RootState> = {
  [GETTERS.START_COUNTRY_FORWARDERS]: (state, getters, rootState) => {
    if (
      getters[GLOBAL_GETTERS.APPLICATION_MODE] ===
        ApplicationMode.CONNECTIONS &&
      rootState.selector.startCountry &&
      rootState.selector.startLocations &&
      rootState.selector.startLocations.length
    ) {
      return getSelectedForwarders(
        rootState.selector.startCountry,
        rootState.selector.startLocations,
        state.items,
        rootState,
      );
    }

    return [];
  },

  [GETTERS.DESTINATION_COUNTRY_FORWARDERS]: (state, getters, rootState) => {
    if (
      getters[GLOBAL_GETTERS.APPLICATION_MODE] ===
        ApplicationMode.CONNECTIONS &&
      rootState.selector.endCountry &&
      rootState.selector.endLocations &&
      rootState.selector.endLocations.length
    ) {
      return getSelectedForwarders(
        rootState.selector.endCountry,
        rootState.selector.endLocations,
        state.items,
        rootState,
      );
    }

    return [];
  },
};

const actions: ActionTree<ConveyanceState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'area/conveyance' })
      .then(response => {
        return response.areaList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'area/conveyance/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },
};

const mutations: MutationTree<ConveyanceState> = {
  ...defaultItemMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};

// helpers

// tslint:disable-next-line:max-line-length
function getSelectedForwarders(
  selectedCountry: string,
  selectedLocations: string[],
  areas: StoredHashmap<ConveyanceArea>,
  rootState: RootState,
) {
  const forwarders: StoredArray<Company> = [];
  const companyIds: { [id: string]: boolean } = {};
  const country = rootState.countries.items[selectedCountry];

  if (country && selectedLocations.length > 0) {
    for (const areaId in areas) {
      const area = areas[areaId];
      const countryAreas = area.areas.find(a => a.country === country.code);
      // all locations of a country selected, or locationId in areas localitylist
      if (
        countryAreas &&
        (countryAreas.all ||
          selectedLocations.some(
            locationId =>
              !!area.localities.find(entity => entity.uid === locationId),
          ))
      ) {
        companyIds[area.company.uid as string] = true;
      }
    }
    for (const companyId in companyIds) {
      forwarders.push(rootState.companies.items[companyId]);
    }
    forwarders.sort((c1, c2) => c1.name.localeCompare(c2.name));
  }
  return forwarders;
}
