import { ComponentOptions } from 'vue';
import { Address } from 'kv_shared/lib/data-types';
import { KVComponent } from 'types';
import { COUNTRY } from 'store/getters';
import { normalizeUrl } from 'utils/helpers';

interface Component extends KVComponent {
  address: Address;
}

export default {
  props: ['address', 'heading', 'urls'],

  computed: {
    countryNames(this: Component) {
      return this.$store.getters[COUNTRY.NAMES_BY_ID];
    },

    country() {
      if (!this.address.country) {
        return '';
      }
      if (typeof this.address.country === 'string') {
        return this.address.country;
      }
      return this.countryNames[this.address.country.uid];
    },
  },

  methods: {
    normalizeUrl(url) {
      return normalizeUrl(url);
    },
  },
} as ComponentOptions<Component>;
