<template>
  <section
    :class="[
      'vc-' + $options.$vc.settings.basename,
      { 'is-collapsed': !status },
    ]"
  >
    <slot></slot>
  </section>
</template>

<script>
import { toggleElement, closeElement, openElement } from './../defaults';

export default {
  props: {
    openOnLoad: {
      default: false,
      type: Boolean,
    },
  },

  data: function() {
    return {
      nodes: {},
      status: false,
    };
  },

  // status watcher - change toggle element when status changes
  watch: {
    status: function(new_value, old_value) {
      this.$emit('onStatusChange', {
        vm: this,
        status: new_value,
        old_status: old_value,
      });

      if (this.$parent.onlyOneActive === false) {
        toggleElement(this.nodes, this.$options.$vc.settings);
      } else {
        if (new_value === true && old_value === false) {
          let active = this.$parent.$children.filter(function(el) {
            return el.status === true;
          });

          if (active.length > 1) {
            active.forEach(
              function(el) {
                el.close();
                closeElement(el.nodes, this.$options.$vc.settings);
              }.bind(this),
            );
          }

          openElement(this.nodes, this.$options.$vc.settings);
          this.open();
        } else if (old_value === true && new_value === false) {
          closeElement(this.nodes, this.$options.$vc.settings);
          this.close();
        }
      }
    },
  },

  // collapse basic instance methods
  methods: {
    toggle() {
      this.$emit('beforeToggle', this);
      this.status = !this.status;
      this.$emit('afterToggle', this);
    },

    close() {
      this.$emit('beforeClose', this);
      this.status = false;
      this.$emit('afterClose', this);
    },

    open() {
      this.$emit('beforeOpen', this);
      this.status = true;
      this.$emit('afterOpen', this);
    },

    afterTransitionEnd() {
      if (this.status) {
        this.nodes.content.classList.add('after-transition');
      } else {
        this.nodes.content.classList.remove('after-transition');
      }
    },

    clickToggler() {
      if (!this.$el.classList.contains('is-disabled')) {
        this.toggle();
      }
    },
  },

  // mounting
  mounted: function() {
    this.nodes.toggle = this.$el.querySelector(
      '.' + this.$options.$vc.settings.togglerClassDefault,
    );

    this.nodes.content = this.$el.querySelector(
      '.' + this.$options.$vc.settings.contentClassDefault,
    );

    [
      'webkitTransitionEnd',
      'otransitionend',
      'oTransitionEnd',
      'msTransitionEnd',
      'transitionend',
    ].forEach(event => {
      this.nodes.content.addEventListener(
        event,
        this.afterTransitionEnd,
        false,
      );
    });

    this.$emit('afterNodesBinding', { vm: this, nodes: this.nodes });

    if (this.nodes.toggle !== null) {
      this.nodes.toggle.addEventListener('click', () => {
        this.clickToggler();
      });
    }

    if (this.openOnLoad) {
      this.clickToggler();
    }
  },
};
</script>
