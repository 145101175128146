import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import { sortListBy } from 'utils/helpers';

interface ListData extends KVComponent {
  sorted: boolean;
}

export default {
  props: ['list', 'unsorted'],

  computed: {
    sortedList() {
      if (this.unsorted) {
        return this.list;
      } else {
        return sortListBy(this.list, 'name');
      }
    },
  },

  methods: {
    selectItem(uid) {
      this.$emit('select', uid);
    },
  },
} as ComponentOptions<ListData>;
