import { ComponentOptions } from 'vue';
import { KVComponent, RootState, SelectorState } from 'types';
import ImprintContactBlock from 'components/ui/ImprintContactBlock.vue';
import { mapState, mapGetters } from 'vuex';
import { MAP } from 'store/actions';
import { UI as UI_GETTERS } from 'store/getters';

interface Component extends KVComponent {
  footerWidgetCollapsed: boolean;
  currentSelection: SelectorState;
  isMobile: boolean;
}

export default {
  components: {
    ImprintContactBlock,
  },

  data() {
    return {
      footerWidgetCollapsed: false,
      show: true,
    };
  },

  computed: {
    ...mapState<RootState>({
      currentSelection: state => state.selector,
      uiWindowSize: state => state.ui.windowSize,
    }),

    ...mapGetters({
      isMobile: UI_GETTERS.IS_MOBILE,
    }),
  },

  watch: {
    currentSelection: {
      deep: true,
      handler() {
        this.updateFooterWidget();
      },
    },
  },

  methods: {
    updateFooterWidget() {
      if (this.isMobile) {
        this.footerWidgetCollapsed = this.currentSelection.startLocations.length
          ? true
          : false;
      }
    },

    zoom(direction) {
      if (direction === 'in') {
        this.$store.dispatch(MAP.ZOOM_IN);
      } else {
        this.$store.dispatch(MAP.ZOOM_OUT);
      }
    },
  },
} as ComponentOptions<Component>;
