import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import { LOCATION } from 'store/getters';

interface Component extends KVComponent {}

export default {
  props: ['trainData'],

  computed: {
    locationNames(this: Component) {
      return this.$store.getters[LOCATION.LOCALE_NAMES];
    },
  },

  methods: {
    nameHasWhitespace(name) {
      return name.indexOf(' ') >= 0;
    },
  },
} as ComponentOptions<Component>;
