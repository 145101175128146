import { ComponentOptions } from 'vue';
import { mapState } from 'vuex';
import { KVComponent, RootState } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';

interface Component extends KVComponent {
  text: {};
}

export default {
  props: {
    scrollbar: {
      type: Boolean,
      default: true,
    },
  },

  mixins: [perfectScrollbarComponent()],

  data() {
    return {
      text: {
        // tslint:disable:max-line-length
        de:
          'railway.tools ist ein Produkt der DB InfraGo AG, das in Kooperation mit den Branchenverbänden Allianz pro Schiene und Bundesverband Güterkraftverkehr Logistik und Entsorgung (BGL) e.V. unter der Schirmherrschaft des Bundesministeriums für Verkehr und digitale Infrastruktur (BMVI) vermarktet wird, um den Zugang zum Kombinierten Verkehr mithilfe einer digitalen Plattform zu erleichtern, Transportalternativen aufzuzeigen und die Verkehrsträger Straße und Schiene intelligent zu verzahnen.',
        en:
          'railway.tools is a product developed by DB InfraGo AG. It is marketed in cooperation with the industry associations Allianz pro Schiene and Bundesverband Güterkraftverkehr Logistik und Entsorgung (BGL) e.V. Its objective is to facilitate access to multimodal transport with the help of a digital platform, highlight transport alternatives and intelligently interlink road and rail transport.',
        it:
          'railway.tools è un prodotto della DB InfraGo AG e viene commercializzato in cooperazione con le associazioni di settore Allianz pro Schiene e Bundesverband Güterkraftverkehr Logistik und Entsorgung (BGL) e.V.. Ha lo scopo di facilitare l’accesso al trasporto combinato con l’aiuto di una piattaforma digitale, di mostrare alternative di trasporto e di connettere in modo intelligente le modalità di trasporto su strada e su rotaia.',
        no:
          'railway.tools er et produkt fra DB InfraGo AG som markedsføres i samarbeid med bransjeforbundene Allianz pro Schiene og Bundesverband Güterkraftverkehr Logistik und Entsorgung (BGL) e.V. for å forenkle tilgangen til kombinert trafikk ved hjelp av en digital plattform, vise til transportalternativer og integrere transportmåtene vei og skinne på intelligent vis.',
        fr:
          'railway.tools est un produit de la DB InfraGo AG qui est commercialisé en coopération avec les organisations sectorielles Allianz pro Schiene et Bundesverband Güterkraftverkehr Logistik und Entsorgung (BGL) e.V., afin de faciliter l’accès au transport combiné à l’aide d’une plateforme numérique, de présenter les alternatives de transport et de renforcer de façon intelligente l’interaction entre le mode de transport du rail et de la route.',
        // tslint:enable:max-line-length
      },
    };
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
    }),

    getText() {
      return this.text[this.currentLanguage] || this.text.en;
    },
  },
} as ComponentOptions<Component>;
