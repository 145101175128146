import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';

interface ListViewData extends KVComponent {}

export default {
  mixins: [perfectScrollbarComponent()],

  methods: {},
} as ComponentOptions<ListViewData>;
