import * as types from 'kv_shared/lib/data-types';
import i18n from 'kv_shared/lib/vue/i18n';
import { TransportFeatureStatus } from 'types';

export const API_ENDPOINT = {
  TERMINAL: 'place/terminal',
  LOADING_POINT: 'place/loadingPoint',
  SERVICE_STATION: 'place/serviceStation',
};

export const COMPANY_TYPE_OBJECT = {
  OPERATOR: {
    name: i18n.t('Operator'),
    type: types.CompanyType.OPERATOR,
  },
  TERMINAL_CARRIER: {
    name: i18n.t('Terminalbetreiber'),
    type: types.CompanyType.TERMINAL_CARRIER,
  },
  LOADING_POINT_CARRIER: {
    name: i18n.t('Ladestellenbetreiber'),
    type: types.CompanyType.LOADING_POINT_CARRIER,
  },
  SERVICE_STATION_CARRIER: {
    name: i18n.t('Tankstellenbetreiber'),
    type: types.CompanyType.SERVICE_STATION_CARRIER,
  },
};

export const USABLE_UNITS = {
  container20: i18n.t("Container 20'"),
  container30: i18n.t("Container 30'"),
  container40: i18n.t("Container 40'"),
  container45: i18n.t("Container 45'"),
  exchangeableContainer: i18n.t('Wechselbehälter'),
  tankContainer: i18n.t('Tankcontainer'),
  semiTrailer: i18n.t('Sattelanhänger'),
  nikrasa: i18n.t('Nichtkranbare Sattelanhänger'),
  bulk: i18n.t('Bulk'),
  rola: i18n.t('RoLa'),
};

export const TRANSPORT_FEATURE_CLASSED = {
  [TransportFeatureStatus.CAPACITIES]: 'capacities',
  [TransportFeatureStatus.SOME_CAPACITIES]: 'some-capacities',
  [TransportFeatureStatus.NO_CAPACITIES]: 'no-capacities',
  [TransportFeatureStatus.NO_DATA]: 'no-data',
};

export const TRANSPORT_FEATURE_MARKERS = {
  [TransportFeatureStatus.CAPACITIES]: {
    label: i18n.t('Transportanforderungen erfüllt'),
    class: TRANSPORT_FEATURE_CLASSED[TransportFeatureStatus.CAPACITIES],
  },

  [TransportFeatureStatus.SOME_CAPACITIES]: {
    label: i18n.t('Transportanforderungen teilweise erfüllt'),
    class: TRANSPORT_FEATURE_CLASSED[TransportFeatureStatus.SOME_CAPACITIES],
  },

  [TransportFeatureStatus.NO_CAPACITIES]: {
    label: i18n.t('Transportanforderungen nicht erfüllt'),
    class: TRANSPORT_FEATURE_CLASSED[TransportFeatureStatus.NO_CAPACITIES],
  },

  [TransportFeatureStatus.NO_DATA]: {
    label: i18n.t('Keine Daten vorhanden'),
    class: TRANSPORT_FEATURE_CLASSED[TransportFeatureStatus.NO_DATA],
  },
};

export const DIRECT_CONNECTION_MARKER = {
  label: i18n.t('Direktverbindung'),
  class: 'direct-connection',
};

export const LEGEND_DEFAULT = [
  DIRECT_CONNECTION_MARKER,
  TRANSPORT_FEATURE_MARKERS[TransportFeatureStatus.NO_DATA],
  TRANSPORT_FEATURE_MARKERS[TransportFeatureStatus.CAPACITIES],
  TRANSPORT_FEATURE_MARKERS[TransportFeatureStatus.SOME_CAPACITIES],
  TRANSPORT_FEATURE_MARKERS[TransportFeatureStatus.NO_CAPACITIES],
];

export const TIME_REGEX = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
export const PRICE_REGEX = /^\d+[\.|\,]?\d{0,2}$/;

export const DEFAULT_SELECTOR_RADIUS = 25;
