import { ApplicationMode, KVComponent } from 'types';
import { ComponentOptions } from 'vue';
import NewsBlock from 'components/ui/NewsBlock.vue';
import ImprintBlock from 'components/ui/ImprintBlock.vue';
import PartnerBlock from 'components/ui/PartnerBlock.vue';
import PrivacyBlock from 'components/ui/PrivacyBlock.vue';
import { MAP } from 'store/actions';

interface Component extends KVComponent {
  info: string;
  openPopup: (type: string) => {};
  closePopup: (type: string) => {};
}

export default {
  components: {
    NewsBlock,
    ImprintBlock,
    PartnerBlock,
    PrivacyBlock,
  },

  props: ['info', 'scrollTo'],

  data() {
    return {};
  },

  created() {
    this.$store.dispatch(MAP.RENDER, {});
    this.$store.dispatch(MAP.ZOOM_TO_WORLD);
  },

  methods: {
    openPopup(type) {
      this.$router.push({
        name: ApplicationMode.START,
        params: { info: type },
      });
    },

    closePopup(type) {
      this.$router.push({ name: ApplicationMode.START });
    },

    togglePopup(type) {
      if (this.info === type) {
        this.closePopup(type);
      } else {
        this.openPopup(type);
      }
    },
  },
} as ComponentOptions<Component>;
