export const trainSvgTemplate = `
<svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 141.73 85.04">
<g>
	<rect x="11.78" y="19.14" class="st1" width="115.88" height="38.77"/>
</g>
<path class="st1" d="M136.02,58.56h-6.06v2.93H10.06v-2.93H3.98v12.05h3.42c-0.07,0.42-0.12,0.86-0.12,1.3
	c0,4.14,3.35,7.49,7.49,7.49c3.55,0,6.52-2.47,7.29-5.79c0.77,3.32,3.74,5.79,7.29,5.79c4.14,0,7.49-3.35,7.49-7.49
	c0-2.03-0.81-3.86-2.12-5.21h70.86c-1.31,1.35-2.12,3.19-2.12,5.21c0,4.14,3.35,7.49,7.49,7.49c3.55,0,6.52-2.47,7.29-5.79
	c0.77,3.32,3.74,5.79,7.29,5.79c4.14,0,7.49-3.35,7.49-7.49c0-0.45-0.05-0.88-0.12-1.3h3.1V58.56z M14.76,75.69
	c-2.08,0-3.77-1.69-3.77-3.77s1.69-3.77,3.77-3.77s3.77,1.69,3.77,3.77S16.85,75.69,14.76,75.69z M29.35,75.69
	c-2.08,0-3.77-1.69-3.77-3.77s1.69-3.77,3.77-3.77s3.77,1.69,3.77,3.77S31.43,75.69,29.35,75.69z M110.96,75.69
	c-2.08,0-3.77-1.69-3.77-3.77s1.69-3.77,3.77-3.77s3.77,1.69,3.77,3.77S113.05,75.69,110.96,75.69z M125.55,75.69
	c-2.08,0-3.77-1.69-3.77-3.77s1.69-3.77,3.77-3.77s3.77,1.69,3.77,3.77S127.63,75.69,125.55,75.69z"/>
</svg>
`;
