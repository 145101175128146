import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { COMPANY as ACTIONS, API } from 'store/actions';
import { COMPANY as MUTATIONS } from 'store/mutations';
import { COMPANY as GETTERS } from 'store/getters';
import { COMPANY_TYPE_OBJECT } from 'utils/constants';
import { defaultValueOnHttpError, sortListBy } from 'utils/helpers';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-store';
import Vue from 'vue';
import i18n from 'kv_shared/lib/vue/i18n';
import {
  CompanyType,
  Company,
  CompanyHackType,
} from 'kv_shared/lib/data-types';
import { CompanyState, RootState, StoredEntity } from 'types';
import { isCompanyBookingProvider } from 'kv_shared/lib/bookingProviderHack';

const state: CompanyState = {
  ...defaultItemState<Company>(),
  configurations: {},
  companyTypesLoaded: {},
};

const getters: GetterTree<CompanyState, RootState> = {
  [GETTERS.TYPE_NAMES](_, __, rootState) {
    const names = {};
    for (const key in COMPANY_TYPE_OBJECT) {
      const typeObj = COMPANY_TYPE_OBJECT[key];
      names[typeObj.type] = i18n.t(typeObj.name);
    }
    return names;
  },

  [GETTERS.LIST_BY_TYPE]() {
    const companies = {
      [CompanyType.OPERATOR]: [] as StoredEntity<Company>[],
      [CompanyType.TERMINAL_CARRIER]: [] as StoredEntity<Company>[],
      [CompanyType.LOADING_POINT_CARRIER]: [] as StoredEntity<Company>[],
      [CompanyType.SERVICE_STATION_CARRIER]: [] as StoredEntity<Company>[],
      [CompanyType.FORWARDER]: [] as StoredEntity<Company>[],
      [CompanyHackType.BOOKING_PROVIDER]: [] as StoredEntity<Company>[],
    };

    for (const cid in state.items) {
      const company = state.items[cid];
      if (company.companyType.indexOf(CompanyType.OPERATOR) >= 0) {
        companies[CompanyType.OPERATOR].push(company);
      }
      if (company.companyType.indexOf(CompanyType.TERMINAL_CARRIER) >= 0) {
        companies[CompanyType.TERMINAL_CARRIER].push(company);
      }
      if (company.companyType.indexOf(CompanyType.LOADING_POINT_CARRIER) >= 0) {
        companies[CompanyType.LOADING_POINT_CARRIER].push(company);
      }
      if (
        company.companyType.indexOf(CompanyType.SERVICE_STATION_CARRIER) >= 0
      ) {
        companies[CompanyType.SERVICE_STATION_CARRIER].push(company);
      }
      if (company.companyType.indexOf(CompanyType.FORWARDER) >= 0) {
        companies[CompanyType.FORWARDER].push(company);
      }
      if (isCompanyBookingProvider(company)) {
        companies[CompanyHackType.BOOKING_PROVIDER].push(company);
      }
    }

    return companies;
  },

  [GETTERS.OPERATORS]: (_, getters) =>
    sortListBy(getters[GETTERS.LIST_BY_TYPE][CompanyType.OPERATOR], 'name'),

  [GETTERS.TERMINAL_CARRIERS]: (_, getters) =>
    sortListBy(
      getters[GETTERS.LIST_BY_TYPE][CompanyType.TERMINAL_CARRIER],
      'name',
    ),

  [GETTERS.LOADING_POINT_CARRIERS]: (_, getters) =>
    sortListBy(
      getters[GETTERS.LIST_BY_TYPE][CompanyType.LOADING_POINT_CARRIER],
      'name',
    ),

  [GETTERS.SERVICE_STATION_CARRIERS]: (_, getters) =>
    sortListBy(
      getters[GETTERS.LIST_BY_TYPE][CompanyType.SERVICE_STATION_CARRIER],
      'name',
    ),

  [GETTERS.FORWARDERS]: (_, getters) =>
    sortListBy(getters[GETTERS.LIST_BY_TYPE][CompanyType.FORWARDER], 'name'),

  [GETTERS.BOOKING_PROVIDERS]: (_, getters) =>
    sortListBy(
      getters[GETTERS.LIST_BY_TYPE][CompanyHackType.BOOKING_PROVIDER],
      'name',
    ),
};

const actions: ActionTree<CompanyState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  [ACTIONS.GET_CONFIG_BY_TYPE]({ commit, dispatch }, type) {
    if (!Object.values(CompanyType).includes(type)) {
      return;
    }

    return dispatch(ACTIONS.API_GET_CONFIG_BY_TYPE, type).then(config =>
      commit(MUTATIONS.SET_CONFIG_BY_TYPE, { type, config }),
    );
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'company' })
      .then(response => {
        return response.companyList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'company/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_GET_CONFIG_BY_TYPE]({ commit, dispatch }, type) {
    return dispatch(API.GET, { path: 'company/' + type + '/configuration' });
  },
};

const mutations: MutationTree<CompanyState> = {
  ...defaultItemMutations(MUTATIONS),

  [MUTATIONS.SET_CONFIG_BY_TYPE](state, { type, config }) {
    // Use Vue.set on dynamic property names for reactive updates
    Vue.set(state.configurations, type, config);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
