import { ComponentOptions } from 'vue';
import { KVComponent, ConnectionFilter, RootState } from 'types';
import { CONNECTION, UI } from 'store/actions';
import { mapState } from 'vuex';

interface FilterViewData extends KVComponent {
  filter?: ConnectionFilter;
  currentFilter: ConnectionFilter;
  yesnoOptions: Array<{ value: boolean; label: string }>;
  changesOptions: Array<{ value: number; label: string }>;
  closeFilter(): void;
}

export default {
  props: ['headline', 'filter'],

  destroyed() {
    this.$store.dispatch(UI.SET_CLOSE_CONNECTION_FILTER, false);
  },

  data() {
    return {
      currentFilter: this.filter ? { ...this.filter } : new ConnectionFilter(),
      yesnoOptions: [
        { value: true, label: this.$t('Ja') },
        { value: false, label: this.$t('Nein') },
      ],
      changesOptions: [
        { value: 0, label: '0' },
        { value: 1, label: '1' },
        { value: 2, label: '2' },
      ],
    };
  },

  computed: {
    ...mapState<RootState>({
      uiCloseFilter: state => state.ui.closeConnectionFilter,
    }),

    changesSelection: {
      get() {
        return this.changesOptions.find(
          opt => opt.value === this.currentFilter.changeCount,
        );
      },
      set(value) {
        this.currentFilter.changeCount = value && value.value;
      },
    },

    changeTerminalSelection: {
      get() {
        return this.yesnoOptions.find(
          opt => opt.value === this.currentFilter.changeTerminal,
        );
      },
      set(value) {
        this.currentFilter.changeTerminal = value && value.value;
      },
    },

    changeOperatorSelection: {
      get() {
        return this.yesnoOptions.find(
          opt => opt.value === this.currentFilter.changeOperator,
        );
      },
      set(value) {
        this.currentFilter.changeOperator = value && value.value;
      },
    },
  },

  watch: {
    uiCloseFilter: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.closeFilter();
        }
      },
    },
  },

  methods: {
    closeFilter() {
      this.$emit('close');
    },

    submitFilter() {
      this.$store.dispatch(CONNECTION.SET_FILTER, this.currentFilter);
      this.closeFilter();
    },
  },
} as ComponentOptions<FilterViewData>;
