// tslint:disable-next-line:max-line-length
import {
  KVComponent,
  StoredEntity,
  StoredHashmap,
  SelectorState,
  RootState,
  PlaceAndLocationStore,
  StoredArray,
  SelectionMode,
  MapInteraction,
} from 'types';
import { ComponentOptions } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Company, Place, Location } from 'kv_shared/lib/data-types';
import { COMPANY, SELECTOR } from 'store/actions';
import {
  SELECTOR as SELECTOR_GETTERS,
  LOCATION as LOCATION_GETTERS,
} from 'store/getters';

export interface SelectionListMixin<T extends Place> extends KVComponent {
  item: StoredEntity<T>;
  items: StoredHashmap<T>;
  list: StoredArray<T>;
  companies: StoredHashmap<Company>;
  locations: StoredHashmap<Location>;
  currentSelection: SelectorState;
  locationStore: PlaceAndLocationStore<T>;
  selectionMode: SelectionMode;
  locationNames: { [uid: string]: string };
  mapInteraction: MapInteraction;

  handleStateChange(selectionHasChanged?: boolean): void;
  onMapInteraction(): void;
  selectItem(uid: string): void;
  resetItem(): void;
}

export function selectionListComponent(ACTIONS, GETTERS) {
  return {
    created() {
      this.$store.dispatch(COMPANY.LOAD_COMPLETE_LIST);
      this.$store.dispatch(ACTIONS.LOAD_COMPLETE_LIST);
      this.resetItem();
    },

    mounted() {
      this.$store.dispatch(
        SELECTOR.SET_CURRENT_LOCATION_STORE,
        this.locationStore,
      );
    },

    beforeDestroy() {
      this.resetItem();
      this.$store.dispatch(SELECTOR.SET_CURRENT_LOCATION_STORE, null);
    },

    computed: {
      ...mapState<RootState>({
        currentSelection: state => state.selector,
        companies: state => state.companies.items,
        locations: state => state.locations.items,
        mapInteraction: state => state.map.interaction,
      }),

      ...mapGetters({
        list: GETTERS.SELECTION_LIST,
        locationStore: GETTERS.PLACE_AND_LOCATION_STORE,
        selectionMode: SELECTOR_GETTERS.SELECTION_MODE,
        locationNames: LOCATION_GETTERS.LOCALE_NAMES,
      }),
    },

    methods: {
      selectItem(uid) {
        this.$store.dispatch(ACTIONS.SELECT_ITEM, uid);
      },

      resetItem() {
        this.$store.dispatch(ACTIONS.RESET_ITEM);
      },

      handleStateChange(selectionHasChanged?: boolean) {
        // Override in root component to react on SelectionMode changes
      },

      onMapInteraction() {
        // Override in root component to react on map intertions
      },
    },

    watch: {
      currentSelection: {
        handler(newItem, oldItem) {
          this.resetItem();
          this.handleStateChange(true);
        },
        deep: true,
      },
      item() {
        this.handleStateChange(false);
      },
      locationStore() {
        this.$store.dispatch(
          SELECTOR.SET_CURRENT_LOCATION_STORE,
          this.locationStore,
        );
      },
      mapInteraction() {
        this.onMapInteraction();
      },
    },
  } as ComponentOptions<SelectionListMixin<any>>;
}
