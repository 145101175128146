import {
  KVComponent,
  SelectorState,
  ApplicationMode,
  StoredArray,
  SelectionMode,
  LocationQueryOption,
} from 'types';
import Vue, { ComponentOptions } from 'vue';
import { SELECTOR, CONNECTION } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  GLOBAL as GLOBAL_GETTERS,
  LOCATION as LOCATION_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
  SELECTOR as SELECTOR_GETTERS,
} from 'store/getters';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { mapGetters } from 'vuex';
import { sortListBy } from 'utils/helpers';
import { TransportUsableUnits, Location } from 'kv_shared/lib/data-types';
import isEqual from 'lodash/isEqual';

interface Component extends KVComponent {
  countryNamesById: { [uid: string]: string };
  locationsLocaleNames: { [uid: string]: string };
  currentSelectionAsText: { [uid: string]: string };
  appMode: ApplicationMode;
  isConnectionsMode: boolean;
  selectionMode: SelectionMode;

  countryOptions: Array<{ value: string; label: string }>;
  currentSelection: SelectorState;
  currentSelectionText: (type) => {};
  checkActivateSelectGroup: (type) => {};
  startLocationsInArea: StoredArray<Location>;
  endLocationsInArea: StoredArray<Location>;

  activateStartSelectGroup: boolean;
  activateDestinationSelectGroup: boolean;
  disableDestinationSelector: boolean;

  startCountrySelection: any;
  startLocationSelection: any;
  endCountrySelection: any;
  endLocationSelection: any;

  startPositionOptions: LocationQueryOption[];
  endPositionOptions: LocationQueryOption[];

  startTerminalOptions: Array<{ value: string; label: string }>;

  resetSelection: () => {};
  resetSelector: boolean;

  featureList: Array<{ value: boolean; label: string }>;
  usableUnits: TransportUsableUnits;

  getLocationOptions(locations: StoredArray<Location>): any[];
  updateActivateSelectGroups(): void;
}

export default {
  props: ['resetSelector'],

  mixins: [perfectScrollbarComponent()],

  data() {
    return {
      activateStartSelectGroup: false,
      activateDestinationSelectGroup: false,
    };
  },

  computed: {
    ...mapGetters({
      countryNamesById: COUNTRY_GETTERS.NAMES_BY_ID,
      locationsLocaleNames: LOCATION_GETTERS.LOCALE_NAMES,
      appMode: GLOBAL_GETTERS.APPLICATION_MODE,
      currentSelectionAsText: SELECTOR_GETTERS.GET_CURRENT_SELECTION_AS_TEXT,
      startLocationsInArea: SELECTOR_GETTERS.START_LOCATIONS_IN_AREA,
      endLocationsInArea: SELECTOR_GETTERS.DESTINATIONS_LOCATIONS_IN_AREA,
      selectionMode: SELECTOR_GETTERS.SELECTION_MODE,
    }),

    featureList() {
      return [
        {
          value: 'container20',
          label: this.$t("20' Ct"),
        },
        {
          value: 'container30',
          label: this.$t("30' Ct"),
        },
        {
          value: 'container40',
          label: this.$t("40' Ct"),
        },
        {
          value: 'container45',
          label: this.$t("45' Ct"),
        },
        {
          value: 'exchangeableContainer',
          label: this.$t('Wechselbehälter'),
        },
        {
          value: 'tankContainer',
          label: this.$t('Tankcontainer'),
        },
        {
          value: 'semiTrailer',
          label: this.$t('Sattelanhänger'),
        },
        {
          value: 'nikrasa',
          label: this.$t('Nichtkranbare Sattelanhänger'),
        },
        {
          value: 'bulk',
          label: this.$t('Bulk'),
        },
        {
          value: 'roLa',
          label: this.$t('RoLa'),
        },
      ];
    },

    currentSelection(this: Component) {
      return this.$store.state.selector;
    },

    isConnectionsMode() {
      return this.appMode === ApplicationMode.CONNECTIONS;
    },

    isWagonLoadMode() {
      return this.appMode === ApplicationMode.WAGON_LOAD;
    },

    usableUnits(this: Component) {
      return (
        this.$store.state.connections.usableUnits || new TransportUsableUnits()
      );
    },

    enableDestinationSelector(this: Component) {
      if (
        this.currentSelection.startCountry &&
        this.currentSelection.startLocations.length
      ) {
        return true;
      } else {
        return false;
      }
    },

    enableFeatureSelector(this: Component) {
      if (
        this.currentSelection.endCountry &&
        this.currentSelection.endLocations.length
      ) {
        return true;
      } else {
        return false;
      }
    },

    // Options

    countryOptions(this: Component) {
      const locationKey = Object.keys(
        this.currentSelection.locationStore.locationsByCountry,
      );
      const options = locationKey.map(countryId => ({
        value: countryId,
        label: this.countryNamesById[countryId],
      }));
      return sortListBy(options, ['label']);
    },

    startLocationOptions(this: Component) {
      const locations = this.isConnectionsMode
        ? this.startLocationsInArea
        : this.currentSelection.locationStore.locationsByCountry[
            this.currentSelection.startCountry
          ];
      return this.getLocationOptions(locations);
    },

    endLocationOptions(this: Component) {
      const locations = this.isConnectionsMode
        ? this.endLocationsInArea
        : this.currentSelection.locationStore.locationsByCountry[
            this.currentSelection.endCountry
          ];
      return this.getLocationOptions(locations);
    },

    startPositionOptions(this: Component) {
      return this.currentSelection.startPosOptions || [];
    },

    endPositionOptions(this: Component) {
      return this.currentSelection.endPosOptions || [];
    },

    // Selection

    startCountrySelection() {
      return this.countryOptions.find(
        opt => opt.value === this.currentSelection.startCountry,
      );
    },

    startLocationSelection() {
      return this.startLocationOptions.filter(opt =>
        this.currentSelection.startLocations.find(id => id === opt.value),
      );
    },

    startPositionSelection(this: Component) {
      return this.startPositionOptions.find(opt =>
        isEqual(opt.value, this.currentSelection.startGeoPosition),
      );
    },

    startRadiusSelection() {
      return this.currentSelection.startRadius;
    },

    endCountrySelection() {
      return this.countryOptions.find(
        opt => opt.value === this.currentSelection.endCountry,
      );
    },

    endLocationSelection() {
      return this.endLocationOptions.filter(opt =>
        this.currentSelection.endLocations.find(id => id === opt.value),
      );
    },

    endPositionSelection(this: Component) {
      return this.endPositionOptions.find(opt =>
        isEqual(opt.value, this.currentSelection.endGeoPosition),
      );
    },

    endRadiusSelection() {
      return this.currentSelection.endRadius;
    },
  },

  watch: {
    resetSelector: 'resetSelection',
    currentSelection: {
      deep: true,
      handler(this: Component) {
        this.updateActivateSelectGroups();
      },
    },
  },

  methods: {
    currentSelectionText(type) {
      let currentText = '';
      const countryProp = type + 'Country';
      const locationProp = type + 'Location';

      if (this.currentSelectionAsText[countryProp]) {
        currentText += this.currentSelectionAsText[countryProp];
      }

      if (this.currentSelectionAsText[locationProp]) {
        currentText += ', ' + this.currentSelectionAsText[locationProp];
      }
      return currentText;
    },

    updateActivateSelectGroups() {
      this.activateStartSelectGroup =
        this.selectionMode === SelectionMode.START_CITY;
      this.activateDestinationSelectGroup =
        this.selectionMode === SelectionMode.DESTINATION_CITY;
    },

    getLocationOptions(locations) {
      const list = locations
        ? locations.map(loc => ({
            value: loc.uid,
            label: this.locationsLocaleNames[loc.uid],
          }))
        : [];
      return sortListBy(list, 'label');
    },

    // Set Actions
    // ------------------------------------------------------

    selectStartCountry(value) {
      this.$store.dispatch(SELECTOR.SELECT_START_COUNTRY, value && value.value);
    },

    selectStartGeoLocation(this: Component, value) {
      this.$store.dispatch(
        SELECTOR.SELECT_START_POSITION,
        value && value.value,
      );

      // preserve query after selection, it is otherwise set to empty string
      const query = this.currentSelection.startPosQuery;
      Vue.nextTick().then(() => {
        this.$store.dispatch(
          SELECTOR.SELECT_START_POS_QUERY,
          value ? query : '',
        );
        // preselect location if it has a terminal
        if (value && value.id) {
          this.$store.dispatch(SELECTOR.SELECT_START_LOCATIONS, [value.id]);
        }
      });
    },

    selectStartRadius(value) {
      this.$store.dispatch(SELECTOR.SELECT_START_RADIUS, value && value.value);
    },

    selectStartLocation(value) {
      const result = Array.isArray(value)
        ? value.map(v => v.value)
        : value && value.value && [value.value];
      this.$store.dispatch(SELECTOR.SELECT_START_LOCATIONS, result);
    },

    setStartPosQuery(value) {
      this.$store.dispatch(SELECTOR.SELECT_START_POS_QUERY, value);
    },

    // ----------

    selectEndCountry(value) {
      this.$store.dispatch(
        SELECTOR.SELECT_DESTINATION_COUNTRY,
        value && value.value,
      );
    },

    selectEndGeoLocation(value) {
      this.$store.dispatch(
        SELECTOR.SELECT_DESTINATION_POSITION,
        value && value.value,
      );

      // preserve query after selection, it is otherwise set to empty string
      const query = this.currentSelection.endPosQuery;
      Vue.nextTick().then(() => {
        this.$store.dispatch(
          SELECTOR.SELECT_DESTINATION_POS_QUERY,
          value ? query : null,
        );
        // preselect location if it has a terminal
        if (value && value.id) {
          this.$store.dispatch(SELECTOR.SELECT_DESTINATION_LOCATIONS, [
            value.id,
          ]);
        }
      });
    },

    selectEndRadius(value) {
      this.$store.dispatch(
        SELECTOR.SELECT_DESTINATION_RADIUS,
        value && value.value,
      );
    },

    selectEndLocation(value) {
      const result = Array.isArray(value)
        ? value.map(v => v.value)
        : value && value.value && [value.value];
      this.$store.dispatch(SELECTOR.SELECT_DESTINATION_LOCATIONS, result);
    },

    setEndPosQuery(value) {
      this.$store.dispatch(SELECTOR.SELECT_DESTINATION_POS_QUERY, value);
    },

    // Global Reset via "reset" button
    // ------------------------------------------------------
    resetSelection() {
      if (this.resetSelector) {
        this.$store.dispatch(SELECTOR.RESET_SEARCH);
        this.$store.dispatch(CONNECTION.SET_USABLE_UNITS, null);
        this.$store.dispatch(CONNECTION.RESET_DIRECT_CONNECTIONS);

        const featureSelectorCollapse = this.$refs
          .featureSelectorCollapse as any;
        if (featureSelectorCollapse) {
          featureSelectorCollapse.close();
        }

        this.$emit('selectorResetted');
      }
    },

    setUsableUnits(event) {
      this.$store.dispatch(CONNECTION.SET_USABLE_UNITS, {
        ...this.usableUnits,
        [event.target.value]: event.target.checked,
      });
    },
  },
} as ComponentOptions<Component>;
