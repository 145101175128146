import { ComponentOptions } from 'vue';
import { Contact } from 'kv_shared/lib/data-types';
import { KVComponent } from 'types';
import { normalizeUrl, normalizePhone } from 'utils/helpers';

interface Component extends KVComponent {
  contact: Contact;
  fallback?: Contact;
}

export default {
  props: ['contact', 'fallback', 'headline'],

  computed: {
    contactData(this: Component) {
      const data = new Contact();

      for (const key in data) {
        data[key] =
          (this.contact && this.contact[key]) ||
          (this.fallback && this.fallback[key]) ||
          data[key];
      }

      return data;
    },

    normalizedUrl() {
      return normalizeUrl(this.contactData.url);
    },
    normalizedUrl2() {
      return normalizeUrl(this.contactData.url2);
    },
  },

  methods: {
    normalizePhone(phonenumber) {
      return normalizePhone(phonenumber);
    },
  },
} as ComponentOptions<Component>;
