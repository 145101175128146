import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState, ApplicationMode, SelectionListState } from 'types';
import { GLOBAL as GLOBAL_GETTERS } from 'store/getters';
import { Entity } from 'kv_shared/lib/data-types';
import { getPlacesByLocationAndCountry } from 'utils/data-transformations';

export function defaultSelectionListState<
  T extends Entity
>(): SelectionListState<T> {
  return {
    selectedItem: null,
  };
}

export function defaultSelectionListActions(ACTIONS, MUTATIONS) {
  return {
    [ACTIONS.SELECT_ITEM]({ commit, dispatch }, uid) {
      return dispatch(ACTIONS.LOAD_ITEM, uid).then(item =>
        commit(MUTATIONS.SET_CURRENT_ITEM, item),
      );
    },

    [ACTIONS.RESET_ITEM]({ commit, dispatch }) {
      commit(MUTATIONS.SET_CURRENT_ITEM, null);
    },
  } as ActionTree<any, RootState>;
}

export function defaultSelectionListGetters(
  GETTERS,
  applicationMode: ApplicationMode,
) {
  return {
    /**
     * Getter that generates lists of places grouped by location and grouped by country.
     */
    [GETTERS.PLACE_AND_LOCATION_STORE](state, getter, rootState) {
      return getPlacesByLocationAndCountry(
        state.items,
        rootState.locations.items,
        rootState.countries.items,
      );
    },

    [GETTERS.SELECTION_LIST](state, getter, rootState) {
      if (getter[GLOBAL_GETTERS.APPLICATION_MODE] !== applicationMode) {
        return [];
      }

      const locId = rootState.selector.startLocations[0];

      return (
        getter[GETTERS.PLACE_AND_LOCATION_STORE].placesByLocation[locId] || []
      );
    },
  } as GetterTree<any, RootState>;
}

export function defaultSelectionListMutations<T extends Entity>(MUTATIONS) {
  return {
    [MUTATIONS.SET_CURRENT_ITEM](state, item) {
      state.selectedItem = item;
    },
  } as MutationTree<SelectionListState<T>>;
}
