import { COUNTRY as ACTIONS, API } from 'store/actions';
import { COUNTRY as MUTATIONS } from 'store/mutations';
import { COUNTRY as GETTERS } from 'store/getters';
import { defaultValueOnHttpError } from 'utils/helpers';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-store';
import i18n from 'kv_shared/lib/vue/i18n';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';
import { CountryState, RootState } from 'types';
import { GetterTree, ActionTree } from 'vuex';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';

const state: CountryState = {
  ...defaultItemState(),
};

const getters: GetterTree<CountryState, RootState> = {
  [GETTERS.NAMES_BY_ID]: (state, getters, rootState) => {
    const name = {};
    for (const cid in state.items) {
      const country = state.items[cid];
      const localeName = country
        ? country.name[rootState.locale] || country.name[DEFAULT_LANGUAGE]
        : i18n.t('(unbekannt)');

      name[cid] = localeName;
    }

    return freezeToStopVueReactivity(name);
  },

  [GETTERS.BY_COUNTRY_CODE]: state => {
    const codes = {};

    for (const cid in state.items) {
      const country = state.items[cid];
      codes[country.code] = country;
    }

    return freezeToStopVueReactivity(codes);
  },
};

const actions: ActionTree<CountryState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'location/country' })
      .then(response => {
        return response.countryList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },
};

const mutations = {
  ...defaultItemMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
