import { WAGON_LOAD as ACTIONS, API } from 'store/actions';
import { WAGON_LOAD as MUTATIONS } from 'store/mutations';
import { WAGON_LOAD as GETTERS } from 'store/getters';
import { defaultValueOnHttpError } from 'utils/helpers';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-store';
import { WagonLoadState, RootState, PlaceAndLocationStore } from 'types';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import {
  defaultSelectionListState,
  defaultSelectionListActions,
  defaultSelectionListMutations,
} from 'store/commons/place-selection-list';
import { WagonLoad } from 'kv_shared/lib/data-types';

const state: WagonLoadState = {
  ...defaultItemState<WagonLoad>(),
  ...defaultSelectionListState<WagonLoad>(),
};

const actions: ActionTree<WagonLoadState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSelectionListActions(ACTIONS, MUTATIONS),

  [ACTIONS.SELECT_ITEM]({ state, rootState, commit, dispatch }, uid) {
    return dispatch(ACTIONS.LOAD_ITEM, uid).then(item =>
      commit(MUTATIONS.SET_CURRENT_ITEM, item),
    );
  },

  // API ACTIONS

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'area/wagonLoad' })
      .then(response => {
        return response.areaList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'area/wagonLoad/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },
};

const getters: GetterTree<WagonLoadState, RootState> = {
  [GETTERS.ITEMS_BY_COUNTRY](state, getter, rootState) {
    const items: { [id: string]: WagonLoad[] } = {};
    for (const id in state.items) {
      const wl = state.items[id];
      wl.country.forEach(c => {
        if (!items[c.uid!]) {
          items[c.uid!] = [wl];
        } else {
          items[c.uid!].push(wl);
        }
      });
    }

    for (const id in items) {
      items[id].sort((a, b) => a.name.localeCompare(b.name));
    }
    return items;
  },

  [GETTERS.SELECTION_LIST](state, getter, rootState) {
    return (
      getter[GETTERS.ITEMS_BY_COUNTRY][rootState.selector.startCountry] || []
    );
  },

  [GETTERS.PLACE_AND_LOCATION_STORE](state, getter, rootState) {
    const cIds = getter[GETTERS.ITEMS_BY_COUNTRY];
    const countries: { [id: string]: any } = {};
    for (const countryId in cIds) {
      countries[countryId] = [];
    }

    const locs: PlaceAndLocationStore<any> = {
      placesByCountry: countries,
      placesByLocation: {},
      locationsByCountry: countries,
    };
    return locs;
  },
};

const mutations: MutationTree<WagonLoadState> = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSelectionListMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
