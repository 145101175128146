import { ComponentOptions } from 'vue';
import { KVComponent, StoredHashmap } from 'types';
import { normalizeUrl, stripTags, sortListBy } from 'utils/helpers';
import { NEWS } from 'store/actions';
import { News } from 'kv_shared/lib/data-types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { getTranslation } from 'kv_shared/lib/utils/localization';

interface Component extends KVComponent {
  activeNewsUid: string;
  isActiveItem: (uid: string) => boolean;
  news: StoredHashmap<News>;
}

export default {
  created() {
    this.$store.dispatch(NEWS.LOAD_COMPLETE_LIST);
  },

  mixins: [perfectScrollbarComponent()],

  data() {
    return {
      activeNewsUid: '',
    };
  },

  computed: {
    news(this: Component) {
      return this.$store.state.news.items;
    },

    sortedNews(this: Component) {
      const lang = this.$store.state.locale;
      return sortListBy(Object.values(this.news), 'publishDate')
        .reverse()
        .map(n => ({
          ...n,
          title: getTranslation(n.localeTitle, lang, n.title),
          message: getTranslation(n.localeMessage, lang, n.message),
        }));
    },
  },

  methods: {
    selectItem(uid) {
      if (this.isActiveItem(uid)) {
        this.activeNewsUid = '';
      } else {
        this.activeNewsUid = uid;
      }
    },

    isActiveItem(uid) {
      return this.activeNewsUid === uid;
    },

    getDateString(date) {
      const newsDate = new Date(date);
      return (
        String('00' + newsDate.getDate()).substr(-2) +
        '.' +
        String('00' + (newsDate.getMonth() + 1)).substr(-2) +
        '.' +
        newsDate.getFullYear()
      );
    },

    normalizeUrl(url) {
      return normalizeUrl(url);
    },

    stripTags(message) {
      return stripTags(message);
    },
  },
} as ComponentOptions<Component>;
