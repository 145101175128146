import { ComponentOptions } from 'vue';
import { KVComponent, RootState } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { setContentView } from '../mixins/set-content-view';
import { mapState } from 'vuex';
import marked from 'marked';

interface Component extends KVComponent {
  infoDE: string;
  infoEN: string;
}

const infoDE = `
## Information zu Trägermedien

### Methanol

Chemischer Träger für Wasserstoff und wir heute schon über die Schiene transportiert.

Transporteffizienz: ca. 1kg Methanol /130g Wasserstoff. Ca. 8,5t Wasserstoff je Kesselwagen; Gefahrstoffklasse: 6

### Ammoniak

Chemischer Träger für Wasserstoff und wir heute schon über die Schiene transportiert und bspw. in der Düngemittelindustrie verwendet.

Transporteffizienz: ca. 1kg Ammoniak /177g Wasserstoff. Ca. 9,3t Wasserstoff je Kesselwagen; Gefahrstoffklasse: 3

### LOHC (Liquid Organic Hydrogen Carrier)

Kein Gefahrstoff, Handling wie bei Rohöl.

Transporteffizienz: ca. 1kg LOHC/60g Wasserstoff. 3,9t Wasserstoff je Kesselwagen

### HyCS (Hydrogen Compact Storage)

Modulare Speicher- und Transportlösung. Je nach Containergröße ca. 1,2t Wasserstoff je Container.

### Gasförmig (vers. Druckstufen)

Je nach Containergröße ca. 1,2t Wasserstoff je Container. Gefahrstoffklasse: 2
`;

const infoEN = `
## Information on carrier media

### Methanol

Chemical carrier for hydrogen and is already transported by rail today.

Transport efficiency: approx. 1kg methanol /130g hydrogen. Approx. 8.5 tons of hydrogen per tank wagon; hazardous substance class: 6

### Ammonia

Chemical carrier for hydrogen and is already transported by rail and used in the fertilizer industry, e.g.

Transport efficiency: approx. 1kg ammonia /177g hydrogen. Approx. 9.3 tons of hydrogen per tank wagon; hazardous substance class: 3

### LOHC (Liquid Organic Hydrogen Carrier)

No hazardous substance, handling compared to crude oil.

Transport efficiency: approx. 1kg LOHC/60g hydrogen. 3.9 tons of hydrogen per tank wagon

### HyCS (Hydrogen Compact Storage)

Modular storage and transport solution. Depending on container size, approx. 1.2 tons of hydrogen per container.

### Gaseous (various pressure levels)

Depending on container size, approx. 1.2 tons of hydrogen per container. Hazardous substance class: 2
`;

export default {
  mixins: [perfectScrollbarComponent(), setContentView('detail')],

  data() {
    return {
      showInfo: false,
      infoDE: marked.parse(infoDE),
      infoEN: marked.parse(infoEN),
    };
  },

  computed: {
    ...mapState<RootState>({
      uiCloseDetails: state => state.ui.closeDetails,
    }),

    wagonProvider(this: Component) {
      const $t = this.$t;

      return [
        // Template
        // {
        //   name: 'Waggonhersteller Norman Schulze AG',
        //   contact: { url: 'www.norman-schulze-ag.de', email: 'info@norman-schulze-ag.de' },
        //   types: [$t('Kesselwagen für LOHC, Methanol, Ammoniak'),],
        // },

        // Waggonbau Graaf
        // https://www.waggonbau-graaff.de/
        // info@waggonbau-graaff.de
        // Kesselwagen für LOHC, Methanol, Ammoniak
        {
          name: 'Waggonbau Graaf',
          contact: {
            url: 'https://www.waggonbau-graaff.de',
            email: 'info@waggonbau-graaff.de',
          },
          types: [$t('Kesselwagen für LOHC, Methanol, Ammoniak')],
        },

        // On - Rail
        // https://on-rail.com/
        //  info@on-rail.com
        // Verkauf von gebrauchten Kesselwagen für LOHC, Methanol, Ammoniak
        {
          name: 'On - Rail',
          contact: {
            url: 'https://on-rail.com',
            email: 'info@on-rail.com',
          },
          types: [
            // prettier-ignore
            $t('Verkauf von gebrauchten Kesselwagen für LOHC, Methanol, Ammoniak'),
          ],
        },

        // Franz Kaminski Gruppe
        // https://kaminski-hameln.de/
        // service@kaminski-hameln.de
        // Kesselwagen für LOHC, Methanol, Ammoniak
        {
          name: 'Franz Kaminski Gruppe',
          contact: {
            url: 'https://kaminski-hameln.de',
            email: 'service@kaminski-hameln.de',
          },
          types: [$t('Kesselwagen für LOHC, Methanol, Ammoniak')],
        },

        // VTG
        // https://www.vtg.de/
        // https://www.vtg.de/kontakt
        // Kesselwagen für LOHC, Methanol, Ammoniak
        {
          name: 'VTG',
          contact: {
            url: 'https://www.vtg.de',
            url2: 'https://www.vtg.de/kontakt',
          },
          types: [$t('Kesselwagen für LOHC, Methanol, Ammoniak')],
        },

        // GATX
        // https://www.gatx.eu/de/
        // https://www.gatx.eu/de/ueber-uns/kontakt/angebot-anfordern/
        // Kesselwagen für LOHC, Methanol, Ammoniak
        {
          name: 'GATX',
          contact: {
            url: 'https://www.gatx.eu/de',
            url2: 'https://www.gatx.eu/de/ueber-uns/kontakt/angebot-anfordern',
          },
          types: [$t('Kesselwagen für LOHC, Methanol, Ammoniak')],
        },

        // Wascosa
        // https://www.wascosa.ch/de
        // info@wascosa.com
        // Kesselwagen für LOHC, Methanol, Ammoniak
        {
          name: 'Wascosa',
          contact: {
            url: 'https://www.wascosa.ch/de',
            email: 'info@wascosa.com',
          },
          types: [$t('Kesselwagen für LOHC, Methanol, Ammoniak')],
        },

        // Feldbinder
        // https://www.feldbinder.com/de/home__2/
        // https://www.feldbinder.com/de/lieferantenanfrage__166/
        // Kesselwagen für LOHC, Methanol, Ammoniak
        {
          name: 'Feldbinder',
          contact: {
            url: 'https://www.feldbinder.com/de',
            url2: 'https://www.feldbinder.com/de/lieferantenanfrage__166',
          },
          types: [$t('Kesselwagen für LOHC, Methanol, Ammoniak')],
        },

        // Ambartec
        // https://www.ambartec.de/
        // post@ambartec.de
        // Transport- und Speichercontainer für HyCS Technologie
        {
          name: 'Ambartec',
          contact: {
            url: 'https://www.ambartec.de',
            email: 'post@ambartec.de',
          },
          types: [$t('Transport- und Speichercontainer für HyCS Technologie')],
        },

        // NPROXX
        // https://www.nproxx.com/de/
        // contact@nproxx.com
        // Gascontainer mit mehreren Elementen (MEGC)
        {
          name: 'NPROXX',
          contact: {
            url: 'https://www.nproxx.com/de',
            email: 'contact@nproxx.com',
          },
          types: [$t('Gascontainer mit mehreren Elementen (MEGC)')],
        },

        // Wystrach
        // https://www.wystrach.gmbh/
        // info@wystrach-gmbh.de
        // Gascontainer mit mehreren Elementen (MEGC)
        {
          name: 'Wystrach',
          contact: {
            url: 'https://www.wystrach.gmbh',
            email: 'info@wystrach-gmbh.de',
          },
          types: [$t('Gascontainer mit mehreren Elementen (MEGC)')],
        },

        // Hexagon Purus
        // https://hexagonpurus.com/
        // contact@hexagonpurus.com
        // Gascontainer mit mehreren Elementen (MEGC)
        {
          name: 'Hexagon Purus',
          contact: {
            url: 'https://hexagonpurus.com',
            email: 'contact@hexagonpurus.com',
          },
          types: [$t('Gascontainer mit mehreren Elementen (MEGC)')],
        },
      ];
    },

    infoMarkup(this: Component) {
      return this.$store.state.locale === 'de' ? this.infoDE : this.infoEN;
    },
  },

  watch: {
    uiCloseDetails: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.closeParent();
        }
      },
    },
  },

  methods: {
    closeDetails() {
      this.$emit('close');
    },

    closeParent() {
      this.$emit('closeParent');
    },
  },
} as ComponentOptions<Component>;
