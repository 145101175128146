var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"grid-row connection-header"},[_c('div',{staticClass:"proportion-keeper"}),_vm._v(" "),_vm._l((_vm.trainData),function(connection,index){return _c('div',{key:'sh' + index,staticClass:"grid-col"},[_c('div',{class:['location', index === 0 ? 'location-first' : '']},[_c('div',{class:[
          'location-name',
          _vm.nameHasWhitespace(_vm.locationNames[connection.from.uid])
            ? ''
            : 'truncate' ]},[_vm._v("\n        "+_vm._s(_vm.locationNames[connection.from.uid])+"\n      ")]),_vm._v(" "),(index === _vm.trainData.length - 1)?[_c('div',{class:[
            'location-name',
            'end-location',
            _vm.nameHasWhitespace(_vm.locationNames[connection.to.uid])
              ? ''
              : 'truncate' ]},[_vm._v("\n          "+_vm._s(_vm.locationNames[connection.to.uid])+"\n        ")])]:_vm._e()],2)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }