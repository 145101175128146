var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"appear":"","appear-class":"main-appear","appear-to-class":"main-appear-to","appear-active-class":"main-appear-active"}},[_c('main',{staticClass:"main-content",class:{ 'is-closed': _vm.closeMainContent }},[_c('button',{staticClass:"toggle-main",attrs:{"type":"button"},on:{"click":function($event){_vm.closeMainContent = !_vm.closeMainContent}}},[_c('span',{staticClass:"icon-arrow"})]),_vm._v(" "),_c('div',{staticClass:"wrap"},[_c('v-collapse-group',[_c('v-collapse-wrapper',{ref:"selectorCollapse",attrs:{"openOnLoad":true},on:{"onStatusChange":_vm.checkSelectorCollapseStatus}},[_c('header',{staticClass:"v-collapse-header"},[_c('h3',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}]},[_c('span',{staticClass:"icon"}),_vm._v(" "),(
                  _vm.isConnectionsMode &&
                    _vm.isSelectorCollapseClosed &&
                    _vm.currentSelectionTexts.startCountry
                )?_c('span',{staticClass:"selector-summary"},[_c('span',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('Start')))]),_vm._v(_vm._s(_vm.currentSelectionTexts.startCountry +
                      (_vm.currentSelectionTexts.startLocation
                        ? ', ' + _vm.currentSelectionTexts.startLocation
                        : '')))]),_vm._v(" "),_c('span',[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('Ziel')))]),_vm._v(_vm._s((_vm.currentSelectionTexts.endCountry
                      ? _vm.currentSelectionTexts.endCountry
                      : '') +
                      (_vm.currentSelectionTexts.endLocation
                        ? ', ' + _vm.currentSelectionTexts.endLocation
                        : '')))])]):_c('span',[_vm._t("selector-header")],2)]),_vm._v(" "),_c('button',{staticClass:"btn-default",attrs:{"type":"button"},on:{"click":_vm.doSelectorReset}},[_vm._v("\n              "+_vm._s(_vm.$t('Reset'))+"\n            ")])]),_vm._v(" "),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}]},[_c('div',{staticClass:"collapse-content"},[_c('selector',{attrs:{"resetSelector":_vm.resetSelector},on:{"selectorResetted":function($event){_vm.resetSelector = false}}})],1)])]),_vm._v(" "),(_vm.isConnectionsMode)?[_c('section',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isListActive),expression:"!isListActive"}],staticClass:"search-result",class:{ 'is-disabled': _vm.results === 0 && !_vm.noResults }},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.results === 0 && !_vm.noResults),expression:"results === 0 && !noResults"}],staticClass:"btn-secondary",attrs:{"type":"button","disabled":""}},[_vm._v("\n              "+_vm._s(_vm.$t('Keine Verbindungen gefunden'))+"\n            ")]),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.results > 0 && !_vm.noResults),expression:"results > 0 && !noResults"}],staticClass:"btn-primary",attrs:{"type":"button"},on:{"click":_vm.showList}},[_vm._v("\n              "+_vm._s(_vm.results + ' ' + _vm.$t('Verbindungen gefunden'))+"\n            ")]),_vm._v(" "),_c('booking-button'),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.results === 0 && _vm.noResults),expression:"results === 0 && noResults"}],staticClass:"btn-warning",attrs:{"type":"button","disabled":""}},[_vm._v("\n              "+_vm._s(_vm.$t('Keine Verbindungen gefunden'))+"\n            ")])],1)]:_vm._e(),_vm._v(" "),_c('v-collapse-wrapper',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.isConnectionsMode
              ? _vm.results > 0
              : _vm.isListActive && (_vm.results > 0 || !_vm.noResults)
          ),expression:"\n            !isConnectionsMode\n              ? results > 0\n              : isListActive && (results > 0 || !noResults)\n          "}],ref:"listCollapse",staticClass:"collapse-scroll",attrs:{"openOnLoad":true},on:{"onStatusChange":_vm.checkListCollapseStatus}},[_c('header',{staticClass:"v-collapse-header"},[_c('h3',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}]},[_c('span',{staticClass:"icon"}),_vm._t("list-header")],2),_vm._v(" "),_vm._t("list-header-button")],2),_vm._v(" "),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}]},[_c('div',{staticClass:"collapse-content"},[_vm._t("list")],2)])])],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }