import { TERMINAL } from 'store/actions';
import { TERMINAL as GETTERS } from 'store/getters';
// tslint:disable-next-line:max-line-length
import { RootState, StoredEntity } from 'types';
import { ComponentOptions } from 'vue';
import {
  selectionListComponent,
  SelectionListMixin,
} from 'components/mixins/selection-list-component';
import { Terminal, ParsedGeoLocation } from 'kv_shared/lib/data-types';
import { API_ENDPOINT } from 'utils/constants';
import { terminalFeatures } from 'components/mixins/terminal-features';
import { createHydrogenTerminalRenderOption } from 'utils/map-helpers';
import { placeMapInteractions } from 'components/mixins/place-map-setup';
import { mapGetters, mapState } from 'vuex';
import HydrogenCalculator from 'components/ui/HydrogenCalculator.vue';
import HydrogenProviders from 'components/ui/HydrogenProviders.vue';

interface Component extends SelectionListMixin<Terminal> {
  positions: { [id: string]: ParsedGeoLocation };
  showPipelines: boolean;
}

export default {
  components: {
    HydrogenCalculator,
    HydrogenProviders,
  },

  data() {
    return {
      documentsApiEndpoint: API_ENDPOINT.TERMINAL,
      isOpenCalculator: false,
      isOpenProviderInfo: false,
    };
  },

  mixins: [
    selectionListComponent(TERMINAL, GETTERS),
    terminalFeatures(),
    placeMapInteractions(),
  ],

  computed: {
    ...mapState<RootState>({
      item: state => state.terminals.selectedItem,
      showPipelines: state => state.terminals.requestHydrogenPipelines,
    }),

    ...mapGetters({
      items: GETTERS.HYDROGEN_TERMINALS, // needed for map rendering
      list: GETTERS.HYDROGEN_SELECTION_LIST,
      locationStore: GETTERS.HYDROGEN_PLACE_AND_LOCATION_STORE,
      positions: GETTERS.PARSED_POSITIONS,
    }),

    keysActive() {
      return true;
    },

    legendKeysList(this: Component) {
      return [
        {
          label: this.$t('H\u2082-HUB/Hersteller'),
          class: 'blue',
        },
        {
          label: this.$t('H\u2082-Umschlag'),
          class: 'red',
        },
      ];
    },
  },

  methods: {
    getPlaceMapRenderOptions(t: StoredEntity<Terminal>) {
      return createHydrogenTerminalRenderOption(
        t,
        this.positions[t.uid],
        this.item && this.item.uid === t.uid,
      );
    },

    togglePipelines() {
      this.$store.dispatch(
        TERMINAL.SET_HYDROGEN_PIPELINES_VISIBILITY,
        !this.showPipelines,
      );
    },
  },
} as ComponentOptions<Component>;
