import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import ConnectionHeaderBlock from 'components/ui/ConnectionHeaderBlock.vue';
import OperatorTrainBlock from 'components/ui/OperatorTrainBlock.vue';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { setContentView } from '../mixins/set-content-view';
import { dragscroll } from 'vue-dragscroll';

interface OperatorViewData extends KVComponent {}

export default {
  props: ['trainData'],

  directives: {
    dragscroll,
  },

  components: {
    ConnectionHeaderBlock,
    OperatorTrainBlock,
  },

  mixins: [perfectScrollbarComponent(), setContentView('detail')],
} as ComponentOptions<OperatorViewData>;
