import { ComponentOptions } from 'vue';
import { Terminal, TerminalBusinessHours } from 'kv_shared/lib/data-types';
import { RootState, KVComponent } from 'types';
import { mapState } from 'vuex';
import { weekDaysMini } from 'utils/moment-service';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';

type BusinessHours = Terminal['businessHours'];

interface BusinessHoursData extends KVComponent {
  businessHours: BusinessHours;
  currentLanguage: string;
}

export default {
  props: ['businessHours'],

  data() {
    return {
      dayKeys: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
      ],
    };
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
    }),
  },

  methods: {
    getBusinessHours(data: TerminalBusinessHours) {
      let hours = '';

      if (data.open === true) {
        if (data.allDay) {
          hours = '00:00 h - 24:00 h';
        } else {
          hours = data.begin + ' h - ' + data.end + ' h';
        }
      } else if (data.open === false) {
        hours = this.$t('geschl.');
      } else {
        hours = this.$t('keine Angaben');
      }

      return hours;
    },

    getWeekday(index) {
      return (
        weekDaysMini[index].label[this.currentLanguage] ||
        weekDaysMini[index].label[DEFAULT_LANGUAGE]
      );
    },
  },
} as ComponentOptions<BusinessHoursData>;
