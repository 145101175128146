import { TRAIN as ACTIONS, API } from 'store/actions';
import { TRAIN as MUTATIONS } from 'store/mutations';
import { defaultValueOnHttpError } from 'utils/helpers';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-store';
import { TrainState, RootState } from 'types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';

const state: TrainState = {
  ...defaultItemState(),
  operatorsLoaded: {},
};

const getters: GetterTree<TrainState, RootState> = {};

const actions: ActionTree<TrainState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  [ACTIONS.LOAD_BY_OPERATOR]({ dispatch, commit, state }, operatorUid) {
    if (!state.operatorsLoaded[operatorUid]) {
      return dispatch(ACTIONS.API_GET_LIST, {
        'filter[operator.uid]': operatorUid,
      }).then(list => {
        commit(MUTATIONS.UPDATE_ITEMS, list);
        commit(MUTATIONS.SET_LOADED_OPERATORS, { [operatorUid]: true });
      });
    }
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }, filter) {
    return dispatch(API.GET, { path: 'schedule/train', query: filter })
      .then(response => {
        return response.trainList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'schedule/train/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },
};

const mutations: MutationTree<TrainState> = {
  ...defaultItemMutations(MUTATIONS),

  [MUTATIONS.SET_LOADED_OPERATORS](state, operators) {
    state.operatorsLoaded = freezeToStopVueReactivity({
      ...state.operatorsLoaded,
      ...operators,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
