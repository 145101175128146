import { ComponentOptions } from 'vue';
import { mapState } from 'vuex';
import { KVComponent, RootState } from 'types';
import { perfectScrollbarComponent } from '../mixins/perfect-scrollbar-component';
import { MISC } from 'store/actions';

interface Component extends KVComponent {
  text: {};
  isTrackingChecked: boolean;
}

export default {
  props: {
    scrollbar: {
      type: Boolean,
      default: true,
    },
  },

  mixins: [perfectScrollbarComponent()],

  data() {
    return {
      text: {
        // tslint:disable:max-line-length
        de: 'Datenschutz',
        en: 'privacy',
        it: 'privacie',
        no: 'priate',
        fr: 'privee',
        // tslint:enable:max-line-length
      },
    };
  },

  mounted() {
    this.$store.dispatch(MISC.GET_MATOMO_TRACKING);
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
      isMatomoTrackingActive: state => state.misc.isMatomoTrackingActive,
    }),

    getText() {
      return this.text[this.currentLanguage] || this.text.en;
    },
  },

  methods: {
    changeTrackingOption(event) {
      if (event.target.checked === true) {
        this.$store.dispatch(MISC.SET_MATOMO_TRACKING, true);
      } else {
        this.$store.dispatch(MISC.SET_MATOMO_TRACKING, false);
      }
    },
  },
} as ComponentOptions<Component>;
