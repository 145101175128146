import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { LOADING_POINT as ACTIONS, API } from 'store/actions';
import { LOADING_POINT as MUTATIONS } from 'store/mutations';
import { LOADING_POINT as GETTERS } from 'store/getters';
import { listToFrozenMap, defaultValueOnHttpError } from 'utils/helpers';
import i18n from 'kv_shared/lib/vue/i18n';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-store';
import { LoadingPointState, RootState, ApplicationMode } from 'types';
import {
  defaultSelectionListState,
  defaultSelectionListActions,
  defaultSelectionListGetters,
  defaultSelectionListMutations,
} from 'store/commons/place-selection-list';
import { LoadingPoint, CompanyType } from 'kv_shared/lib/data-types';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';

const state: LoadingPointState = {
  ...defaultItemState<LoadingPoint>(),
  ...defaultSelectionListState<LoadingPoint>(),
  companyTypes: [],
};

const getters: GetterTree<LoadingPointState, RootState> = {
  ...defaultSelectionListGetters(GETTERS, ApplicationMode.LOADING_POINTS),

  [GETTERS.COMPANY_TYPES_BY_ID]: state =>
    listToFrozenMap(state.companyTypes, 'id'),

  [GETTERS.COMPANY_TYPE_LABELS](state) {
    const lpLocale = i18n.t('Ladestelle');
    const labels = { default: lpLocale };

    for (const type of state.companyTypes) {
      labels[type.id] = i18n.t(type.label);
    }

    return freezeToStopVueReactivity(labels);
  },

  [GETTERS.COMPANY_TYPE_INDEX](state, getters, rootState) {
    const index = {} as { [uid: string]: CompanyType };
    const companies = rootState.companies.items;

    for (const uid in state.items) {
      const lp = state.items[uid];
      const company = companies[lp.company.uid as string];

      if (company && company.loadingPointCompanyType) {
        const type =
          getters[GETTERS.COMPANY_TYPES_BY_ID][company.loadingPointCompanyType];
        if (type) {
          index[uid] = type;
        }
      }
    }

    return freezeToStopVueReactivity(index);
  },
};

const actions: ActionTree<LoadingPointState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSelectionListActions(ACTIONS, MUTATIONS),

  [ACTIONS.LOAD_COMPANY_TYPES](
    { commit, dispatch, state },
    forceReload = false,
  ) {
    if (!forceReload && state.companyTypes && state.companyTypes.length) {
      return;
    }
    return dispatch(ACTIONS.API_GET_COMPANY_TYPES).then(list =>
      commit(MUTATIONS.SET_COMPANY_TYPES, list),
    );
  },

  // API ACTIONS

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'place/loadingPoint' })
      .then(response => {
        return response.placeList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'place/loadingPoint/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_GET_COMPANY_TYPES]({ dispatch }) {
    return dispatch(API.GET, {
      path: 'misc/singleton/loadingPointCompanyTypes',
    })
      .then(response => {
        return response.typeList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },
};

const mutations: MutationTree<LoadingPointState> = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSelectionListMutations(MUTATIONS),

  [MUTATIONS.SET_COMPANY_TYPES](state, newTypes) {
    state.companyTypes = freezeToStopVueReactivity(newTypes);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
