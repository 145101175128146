import { mapState } from 'vuex';
import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import { normalizeUrl } from 'utils/helpers';

interface InfosData extends KVComponent {
  infos: Array<{
    title: string;
    list: Array<{ label: string; value: string }>;
  }>;
  headline: string;
}

export default {
  props: ['infos', 'headline'],

  computed: {
    ...mapState<any>({}),
  },

  methods: {
    normalizeUrl(url) {
      return normalizeUrl(url);
    },

    isUrl(value) {
      return value.toLowerCase().indexOf('http') >= 0;
    },

    displayUrl(url) {
      return url.replace(/^http[s]?\:\/\//, '');
    },
  },
} as ComponentOptions<InfosData>;
