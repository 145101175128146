// tslint:disable-next-line:max-line-length
import {
  KVComponent,
  SelectorState,
  ConnectionListItem,
  StoredHashmap,
  PlaceAndLocationStore,
  RootState,
  StoredEntity,
  SelectionMode,
  ConnectionFilter,
  MapInteraction,
  MapInteractionType,
  MapRenderSpec,
  LocationClasses,
  ConnectionClasses,
  ConnectionRenderOptions,
  StoredArray,
  ConnectionDetails,
  TrainDetails,
} from 'types';
import { ComponentOptions } from 'vue';
import {
  LEGEND_DEFAULT,
  API_ENDPOINT,
  TRANSPORT_FEATURE_MARKERS,
  DIRECT_CONNECTION_MARKER,
} from 'utils/constants';
import { selectionListComponent } from 'components/mixins/selection-list-component';
import FilterView from 'components/ui/FilterView.vue';
import ConnectionView from 'components/ui/ConnectionView.vue';
import TrainDetailsBlock from 'components/ui/TrainDetailsBlock.vue';
import {
  CONNECTION,
  TERMINAL,
  SELECTOR,
  MAP,
  UI,
  CONVEYANCE,
} from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  CONNECTION as CONNECTION_GETTERS,
  SELECTOR as SELECTOR_GETTERS,
  COUNTRY as COUNTRY_GETTERS,
  TERMINAL as TERMINAL_GETTERS,
} from 'store/getters';
import {
  Company,
  Location,
  Terminal,
  Train,
  ParsedGeoLocation,
} from 'kv_shared/lib/data-types';
import { mapState, mapGetters } from 'vuex';
import { terminalFeatures } from 'components/mixins/terminal-features';
// tslint:disable-next-line:max-line-length
import {
  createTerminalRenderOption,
  createStartLocationRenderOption,
  createEndLocationRenderOption,
  createHopLocationRenderOption,
} from 'utils/map-helpers';

interface Component extends KVComponent {
  currentSelection: SelectorState;
  item: ConnectionDetails | null;
  list: ConnectionListItem[];
  filteredList: ConnectionListItem[];
  filter: ConnectionFilter;
  companies: StoredHashmap<Company>;
  locationStore: PlaceAndLocationStore<Terminal>;
  isSearching: boolean;
  selectionMode: SelectionMode;
  terminals: StoredHashmap<Terminal>;
  locations: StoredHashmap<Location>;
  terminalPositions: { [id: string]: ParsedGeoLocation };
  startLocationsInArea: StoredArray<Location>;
  endLocationsInArea: StoredArray<Location>;

  locationNames: { [uid: string]: string };
  mapInteraction: MapInteraction;

  showFilter: boolean;
  selectedTerminal: StoredEntity<Terminal> | null;
  selectedOperator: StoredEntity<Company> | null;
  selectedTrain: StoredEntity<Train> | null;
  viewModes: typeof ViewMode;
  currentViewMode: ViewMode;
  trainDetails: { [uid: string]: TrainDetails };

  resetItem(): void;
  selectItem(uid: string): void;
  handleStateChange(selectionHasChanged: boolean): void;

  showDirectConnections: boolean;
  directConnectionList: Array<{ startId: string; destId: string }>;
  currentContentView: string;

  closeTerminal(): void;
  closeOperator(): void;
  closeTrain(): void;
}

enum ViewMode {
  LIST = 'list',
  NO_RESULTS = 'no_results',
  CONNECTION = 'connection',
  TERMINAL = 'terminal',
  OPERATOR = 'operator',
  TRAIN = 'train',
}

export default {
  components: {
    FilterView,
    ConnectionView,
    TrainDetailsBlock,
  },

  created() {
    this.$store.dispatch(TERMINAL.LOAD_COMPLETE_LIST);
    this.$store.dispatch(CONNECTION.LOAD_TERMINALS_WITH_TRAINS);
    this.$store.dispatch(CONVEYANCE.LOAD_COMPLETE_LIST);
  },

  data() {
    return {
      legendKeysList: LEGEND_DEFAULT,
      showFilter: false,
      viewModes: ViewMode,
      terminalDocumentsApiEndpoint: API_ENDPOINT.TERMINAL,
    };
  },

  mixins: [
    selectionListComponent(CONNECTION, CONNECTION_GETTERS),
    terminalFeatures(),
  ],

  computed: {
    ...mapState<RootState>({
      item: state => state.connections.selectedItem,
      isSearching: state => state.connections.isSearching,
      showDirectConnections: state => state.connections.showDirectConnections,
      directConnectionList: state => state.connections.directConnections,
      filter: state => state.connections.filter,
      terminals: state => state.terminals.items,
      locations: state => state.locations.items,
      currentContentView: state => state.ui.currentContentView,
      currentSelection: state => state.selector,
    }),
    ...mapGetters({
      selectionMode: SELECTOR_GETTERS.SELECTION_MODE,
      filteredList: CONNECTION_GETTERS.FILTERED_SEARCH_RESULTS,
      // override from mixin
      list: CONNECTION_GETTERS.TRANSFORMED_SEARCH_RESULTS,
      terminalPositions: TERMINAL_GETTERS.PARSED_POSITIONS,
      startLocationsInArea: SELECTOR_GETTERS.START_LOCATIONS_IN_AREA,
      endLocationsInArea: SELECTOR_GETTERS.DESTINATIONS_LOCATIONS_IN_AREA,
    }),

    displayList(this: Component) {
      return this.filteredList.map(connection => {
        let marker = TRANSPORT_FEATURE_MARKERS[connection.transportStatus];

        if (
          !marker &&
          connection.connections &&
          connection.connections.length === 1
        ) {
          marker = DIRECT_CONNECTION_MARKER;
        }

        return {
          uid: connection.label,
          name: connection.label,
          marker,
        };
      });
    },

    selectedOperator(this: Component) {
      const uid = this.$store.state.connections.selectedOperatorUid;
      if (this.item && uid) {
        return this.companies[uid];
      }
    },

    selectedTerminal(this: Component) {
      const uid = this.$store.state.connections.selectedTerminalUid;
      if (this.item && uid) {
        return this.$store.state.terminals.items[uid];
      }
    },

    selectedTrain(this: Component) {
      const uid = this.$store.state.connections.selectedTrainUid;
      if (this.item && uid) {
        return this.$store.state.trains.items[uid];
      }
    },

    trainDetails(this: Component) {
      return this.item
        ? this.item.connections.reduce(
            (trains, c) => {
              for (const opId in c.trains) {
                const details = c.trains[opId];
                details.trains.forEach(t => (trains[t.train.uid] = t));
              }
              return trains;
            },
            {} as any,
          )
        : {};
    },

    connectionMarker(this: Component) {
      return this.item && TRANSPORT_FEATURE_MARKERS[this.item.transportStatus];
    },

    trainMarker(this: Component) {
      return (
        this.selectedTrain &&
        TRANSPORT_FEATURE_MARKERS[
          this.trainDetails[this.selectedTrain.uid].transportStatus
        ]
      );
    },

    currentViewMode(this: Component) {
      if (this.selectedOperator) {
        return ViewMode.OPERATOR;
      }
      if (this.selectedTerminal) {
        return ViewMode.TERMINAL;
      }
      if (this.selectedTrain) {
        return ViewMode.TRAIN;
      }
      if (this.item) {
        return ViewMode.CONNECTION;
      }
      if (this.list.length) {
        return ViewMode.LIST;
      }
      if (
        !this.isSearching &&
        this.selectionMode === SelectionMode.ALL_SELECTED
      ) {
        return ViewMode.NO_RESULTS;
      }
    },

    directConnectionsActive(this: Component) {
      return this.selectionMode === SelectionMode.DESTINATION_COUNTRY;
    },
  },

  watch: {
    currentSelection: {
      deep: true,
      handler(this: Component) {
        this.resetItem();
        this.$store.dispatch(CONNECTION.RESET_SEARCH_RESULTS);
        this.handleStateChange(true);
      },
    },
    'currentSelection.startCountry': 'updateStartPositionOptions',
    'currentSelection.startPosQuery': 'updateStartPositionOptions',
    'currentSelection.endCountry': 'updateDestinationPositionOptions',
    'currentSelection.endPosQuery': 'updateDestinationPositionOptions',
    locationStore() {
      this.updateDestinationPositionOptions();
      this.updateStartPositionOptions();
    },
    directConnectionList() {
      this.handleStateChange(false);
    },
    showDirectConnections() {
      this.handleStateChange(false);
    },
    list() {
      this.list.length && this.handleStateChange(true);
    },
    filteredList() {
      this.list.length && this.handleStateChange(false);
    },
    item() {
      this.item && this.handleStateChange(false);
    },
  },

  methods: {
    handleStateChange(selectionHasChanged: boolean) {
      switch (this.selectionMode) {
        case SelectionMode.START_COUNTRY:
          this.$store.dispatch(MAP.RENDER, {});
          this.$store.dispatch(MAP.ZOOM_TO_WORLD);
          break;

        case SelectionMode.START_CITY:
          const startPlaces = this.startLocationsInArea.reduce(
            (list, loc) => {
              return list.concat(this.locationStore.placesByLocation[loc.uid]);
            },
            [] as StoredArray<Terminal>,
          );

          this.$store.dispatch(MAP.RENDER, {
            places: startPlaces.map(t =>
              createTerminalRenderOption(t, this.terminalPositions[t.uid]),
            ),
          } as MapRenderSpec);

          this.$store.dispatch(MAP.ZOOM_TO_PLACES);

          break;

        case SelectionMode.DESTINATION_COUNTRY:
          const mapRenderSpec: MapRenderSpec = {
            locations: this.currentSelection.startLocations.map(l =>
              createStartLocationRenderOption(
                this.locations[l],
                this.locationNames,
              ),
            ),
          };

          // Render direct connections if required
          if (this.showDirectConnections && this.directConnectionList.length) {
            mapRenderSpec.connections = [];
            for (const connection of this.directConnectionList) {
              const start = this.locations[connection.startId];
              const end = this.locations[connection.destId];
              mapRenderSpec.locations &&
                mapRenderSpec.locations.push({
                  uid: end.uid,
                  name: this.locationNames[end.uid],
                  className: LocationClasses.DIRECT_CONNECTION_END,
                  position: end.loc,
                  selectable: true,
                });

              mapRenderSpec.connections.push({
                uid: start.uid + '-' + end.uid,
                from: start.loc,
                to: end.loc,
                className: ConnectionClasses.DIRECT_CONNECTION,
              });
            }
          }

          this.$store.dispatch(MAP.RENDER, mapRenderSpec);

          if (selectionHasChanged) {
            this.$store.dispatch(CONNECTION.RESET_DIRECT_CONNECTIONS);
            this.$store.dispatch(MAP.ZOOM_TO_WORLD);
          }
          break;

        case SelectionMode.DESTINATION_CITY:
          const endPlaces = this.endLocationsInArea.reduce(
            (list, loc) => {
              return list.concat(this.locationStore.placesByLocation[loc.uid]);
            },
            [] as StoredArray<Terminal>,
          );

          this.$store.dispatch(MAP.RENDER, {
            locations: this.currentSelection.startLocations.map(l =>
              createStartLocationRenderOption(
                this.locations[l],
                this.locationNames,
              ),
            ),
            places: endPlaces.map(t =>
              createTerminalRenderOption(t, this.terminalPositions[t.uid]),
            ),
          });
          this.$store.dispatch(MAP.ZOOM_TO_PLACES);
          break;

        case SelectionMode.ALL_SELECTED:
          this.$store.dispatch(MAP.RENDER, {
            locations: this.currentSelection.startLocations
              .map(l =>
                createStartLocationRenderOption(
                  this.locations[l],
                  this.locationNames,
                ),
              )
              .concat(
                this.currentSelection.endLocations.map(l =>
                  createEndLocationRenderOption(
                    this.locations[l],
                    this.locationNames,
                  ),
                ),
              )
              .concat(
                Object.values(
                  this.filteredList.reduce(
                    (allHops, c) => {
                      // generate labels for all locations
                      const hops = c.connections.reduce(
                        (hops, hop) => {
                          const to = this.locations[
                            hop.toLocationUid as string
                          ];
                          hops[
                            hop.toLocationUid as string
                          ] = createHopLocationRenderOption(
                            to,
                            this.locationNames,
                          );
                          return hops;
                        },
                        {} as any,
                      );

                      // remove duplicated locations
                      return Object.assign(allHops, hops);
                    },
                    {} as any,
                  ),
                ),
              ),

            connections: Object.values(
              this.filteredList.reduce(
                (allHops, c) => {
                  const selected = this.item && this.item.label === c.label;

                  // get lines for each connection
                  const hops = c.connections.reduce(
                    (hops, hop) => {
                      const from = this.locations[
                        hop.fromLocationUid as string
                      ];
                      const to = this.locations[hop.toLocationUid as string];
                      const uid = from.uid + '-' + to.uid;
                      hops[uid] = {
                        uid,
                        className: selected
                          ? ConnectionClasses.HOP_HIGHLIGHT
                          : ConnectionClasses.HOP,
                        from: from.loc,
                        to: to.loc,
                      } as ConnectionRenderOptions;
                      return hops;
                    },
                    {} as any,
                  );

                  // remove duplicated lines, favorise selected
                  for (const uid in hops) {
                    if (!allHops[uid] || selected) {
                      allHops[uid] = hops[uid];
                    }
                  }

                  return allHops;
                },
                {} as any,
              ),
            ),
          } as MapRenderSpec);

          if (selectionHasChanged) {
            if (!this.list.length && !this.isSearching) {
              this.$store.dispatch(CONNECTION.SET_FILTER, null);
              this.$store.dispatch(CONNECTION.SET_USABLE_UNITS, null);
              this.$store.dispatch(CONNECTION.SEARCH_CONNECTIONS);
            } else {
              this.$store.dispatch(MAP.ZOOM_TO_CONNECTIONS);
            }
          }

          break;
      }
    },

    onMapInteraction(this: Component) {
      const { payload, type } = this.mapInteraction;

      switch (this.selectionMode) {
        case SelectionMode.START_COUNTRY:
          if (type === MapInteractionType.COUNTRY_CLICKED) {
            const country = this.$store.getters[
              COUNTRY_GETTERS.BY_COUNTRY_CODE
            ][payload.countryCode];
            this.$store.dispatch(
              SELECTOR.SELECT_START_COUNTRY,
              country && country.uid,
            );
          }
          break;

        case SelectionMode.START_CITY:
          if (type === MapInteractionType.PLACE_CLICKED) {
            const terminal = this.terminals[payload.uid];
            this.$store.dispatch(
              SELECTOR.SELECT_START_LOCATIONS,
              terminal && [terminal.locality.uid],
            );
          }
          break;

        case SelectionMode.DESTINATION_COUNTRY:
          if (type === MapInteractionType.COUNTRY_CLICKED) {
            const country = this.$store.getters[
              COUNTRY_GETTERS.BY_COUNTRY_CODE
            ][payload.countryCode];
            this.$store.dispatch(
              SELECTOR.SELECT_DESTINATION_COUNTRY,
              country && country.uid,
            );
          }

          // Direct connection click
          if (
            type === MapInteractionType.LOCATION_CLICKED &&
            payload.uid !== this.currentSelection.startLocations
          ) {
            const location = this.$store.state.locations.items[payload.uid];
            const country = this.$store.state.countries.items[
              location && (location.country.uid as string)
            ];
            this.$store.dispatch(
              SELECTOR.SELECT_DESTINATION_COUNTRY,
              country && country.uid,
            );
            this.$store.dispatch(SELECTOR.SELECT_DESTINATION_LOCATIONS, [
              payload.uid,
            ]);
          }

          break;

        case SelectionMode.DESTINATION_CITY:
          if (type === MapInteractionType.PLACE_CLICKED) {
            const terminal = this.terminals[payload.uid];
            this.$store.dispatch(
              SELECTOR.SELECT_DESTINATION_LOCATIONS,
              terminal && [terminal.locality.uid],
            );
          }
          break;

        case SelectionMode.ALL_SELECTED:
          break;
      }

      if (type === MapInteractionType.LOCATION_CLOSED) {
        this.$store.dispatch(
          SELECTOR.SELECT_START_LOCATIONS,
          this.currentSelection.startLocations.filter(
            uid => uid !== payload.uid,
          ),
        );
        this.$store.dispatch(
          SELECTOR.SELECT_DESTINATION_LOCATIONS,
          this.currentSelection.endLocations.filter(uid => uid !== payload.uid),
        );
      }
    },

    toggleDirectConnections() {
      if (this.directConnectionList.length) {
        this.$store.dispatch(
          CONNECTION.SET_DIRECT_CONNECTIONS_VISIBILITY,
          !this.showDirectConnections,
        );
      } else {
        this.$store.dispatch(CONNECTION.SEARCH_DIRECT_CONNECTIONS);
      }
    },

    getOperatorUrlInfos(urls) {
      const list = [] as any[];

      urls && Object.entries(urls).forEach(([key, value]) => {
        if (urls.hasOwnProperty(key + '_label') && value !== '') {
          list.push({
            label: urls[key + '_label'],
            value,
          });
        }
      });

      return list;
    },

    selectItem(label) {
      this.$store.dispatch(
        CONNECTION.SELECT_ITEM,
        this.list.find(item => item.label === label),
      );
    },

    resetItem() {
      this.$store.dispatch(CONNECTION.SELECT_ITEM, null);
      this.closeTerminal();
      this.closeOperator();
      this.closeTrain();
    },

    closeFilter() {
      this.showFilter = false;
    },

    toggleFilter() {
      if (this.currentContentView === 'detail') {
        this.$store.dispatch(UI.SET_CLOSE_DETAILS, true);
        this.showFilter = true;
      } else {
        this.showFilter = !this.showFilter;
      }
    },

    closeTerminal() {
      this.$store.dispatch(CONNECTION.SHOW_TERMINAL_DETAILS, null);
    },

    closeOperator() {
      this.$store.dispatch(CONNECTION.SHOW_OPERATOR_DETAILS, null);
    },

    closeTrain() {
      this.$store.dispatch(CONNECTION.SHOW_TRAIN_DETAILS, null);
    },

    updateDestinationPositionOptions() {
      this.$store.dispatch(SELECTOR.UPDATE_DESTINATIOIN_POSITION_OPTIONS);
    },

    updateStartPositionOptions() {
      this.$store.dispatch(SELECTOR.UPDATE_START_POSITION_OPTIONS);
    },
  },
} as ComponentOptions<Component>;
