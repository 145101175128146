import { ComponentOptions } from 'vue';
import { BookingLink, KVComponent } from 'types';
import { mapGetters } from 'vuex';
import { CONNECTION as CONNECTION_GETTERS } from 'store/getters';
import BookingModal from './BookingModal.vue';

interface Component extends KVComponent {
  isOpen: boolean;
  bookingLinks: BookingLink[];
}

export default {
  components: { BookingModal },

  props: [],

  data: () => ({ isOpen: false }),

  computed: {
    ...mapGetters({
      bookingLinks: CONNECTION_GETTERS.FILTERED_BOOKING_LINKS,
    }),
  },

  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
} as ComponentOptions<Component>;
