import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { LOCATION as ACTIONS, API } from 'store/actions';
import { LOCATION as MUTATIONS } from 'store/mutations';
import { LOCATION as GETTERS } from 'store/getters';
import { defaultValueOnHttpError } from 'utils/helpers';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-store';
import i18n from 'kv_shared/lib/vue/i18n';
import { LocationState, RootState } from 'types';
import { getCountriesOfLocations } from 'utils/data-transformations';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';
import {
  ParsedGeoLocation,
  defaultGeoLocation,
} from 'kv_shared/lib/data-types';

const state: LocationState = {
  ...defaultItemState(),
};

const getters: GetterTree<LocationState, RootState> = {
  [GETTERS.LOCALE_NAMES](state, getters, rootState) {
    const names: { [id: string]: string } = {};
    const placeUnavailable = i18n.t('(kein Ortsname verfügbar)');

    for (const lid in state.items) {
      const location = state.items[lid];
      const locationName =
        location.name[rootState.locale] ||
        location.name.locale ||
        placeUnavailable;

      names[lid] = locationName;
    }

    return freezeToStopVueReactivity(names);
  },

  [GETTERS.BY_COUNTRY]: state => getCountriesOfLocations(state.items),

  [GETTERS.PARSED_POSITIONS]: state => {
    const positions: { [id: string]: ParsedGeoLocation } = {};
    for (const locId in state.items) {
      const loc = state.items[locId];
      const pos = {
        lat: parseFloat(
          (loc && loc.loc && loc.loc.lat) || (defaultGeoLocation.lat as any),
        ),
        lng: parseFloat(
          (loc && loc.loc && loc.loc.lng) || (defaultGeoLocation.lng as any),
        ),
      };
      positions[locId] = pos;
    }
    return positions;
  },
};

const actions: ActionTree<LocationState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  [ACTIONS.LOAD_BY_COUNTRY]({ dispatch, commit }, countryId) {
    return dispatch(ACTIONS.API_GET_LIST, {
      filter: { country: countryId },
    }).then(list => {
      commit(MUTATIONS.UPDATE_ITEMS, list);
      return list;
    });
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }, query) {
    return dispatch(API.GET, { path: 'location/locality', query })
      .then(response => {
        return response.localityList || [];
      })
      .catch(error => {
        console.error('handleHttpError', error);
        return [];
      });
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'location/locality/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },
};

const mutations: MutationTree<LocationState> = {
  ...defaultItemMutations(MUTATIONS),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
