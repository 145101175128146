import {
  Location,
  Terminal,
  LoadingPoint,
  ParsedGeoLocation,
  Place,
} from 'kv_shared/lib/data-types';
// tslint:disable-next-line:max-line-length
import {
  StoredHashmap,
  PlaceClasses,
  PlaceRenderOptions,
  LocationClasses,
  StoredEntity,
} from 'types';

export function getPlaceGeoLocation(
  place: Place,
  locations: StoredHashmap<Location>,
): ParsedGeoLocation {
  const location = locations[place.locality && (place.locality.uid as string)];
  return {
    lng: parseFloat((place.loc && place.loc.lng) || location.loc.lng),
    lat: parseFloat((place.loc && place.loc.lat) || location.loc.lat),
  };
}

export function createTerminalRenderOption(
  t: Terminal,
  p: ParsedGeoLocation,
  highlight?: boolean,
) {
  return {
    name: t.name,
    uid: t.uid,
    className: highlight
      ? PlaceClasses.DEFAULT_BLUE_HIGHLIGHT
      : PlaceClasses.DEFAULT_BLUE,
    selectable: true,
    position: p,
  } as PlaceRenderOptions;
}

export function createHydrogenTerminalRenderOption(
  t: Terminal,
  p: ParsedGeoLocation,
  highlight?: boolean,
) {
  const red =
    t.features && t.features.hydrogen && t.features.hydrogen.isHandling;
  return {
    name: t.name,
    uid: t.uid,
    className: highlight
      ? red
        ? PlaceClasses.DEFAULT_RED_HIGHLIGHT
        : PlaceClasses.DEFAULT_BLUE_HIGHLIGHT
      : red
      ? PlaceClasses.DEFAULT_RED
      : PlaceClasses.DEFAULT_BLUE,
    selectable: true,
    position: p,
  } as PlaceRenderOptions;
}

// tslint:disable-next-line:max-line-length
export function createLoadingPointRenderOption(
  l: LoadingPoint,
  locations: StoredHashmap<Location>,
  color: string,
  highlight?: boolean,
) {
  return {
    color,
    name: l.name,
    uid: l.uid,
    className: highlight
      ? PlaceClasses.CUSTOM_COLOR_HIGHLIGHT
      : PlaceClasses.CUSTOM_COLOR,
    selectable: true,
    position: getPlaceGeoLocation(l, locations),
  } as PlaceRenderOptions;
}

export function createStartLocationRenderOption(
  location: StoredEntity<Location>,
  locationNames,
) {
  return {
    uid: location.uid,
    name: locationNames[location.uid],
    className: LocationClasses.START,
    position: location.loc,
  };
}

export function createEndLocationRenderOption(
  location: StoredEntity<Location>,
  locationNames,
) {
  return {
    uid: location.uid,
    name: locationNames[location.uid],
    className: LocationClasses.DESTINATION,
    position: location.loc,
  };
}

export function createHopLocationRenderOption(
  location: StoredEntity<Location>,
  locationNames,
) {
  return {
    uid: location.uid,
    name: locationNames[location.uid],
    className: LocationClasses.HOP,
    position: location.loc,
  };
}
