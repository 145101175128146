import { ActionTree, MutationTree } from 'vuex';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';
import { Entity } from 'kv_shared/lib/data-types';
import { ItemState, RootState, StoredHashmap } from 'types';
import { listToFrozenMap } from 'utils/helpers';

export function defaultItemState<T extends Entity>(): ItemState<T> {
  return {
    items: {},
    completelyLoaded: false,
  };
}

export function defaultItemActions<T extends Entity>(ACTIONS, MUTATIONS) {
  return {
    [ACTIONS.LOAD_COMPLETE_LIST]({ commit, dispatch, state }) {
      if (!state.completelyLoaded) {
        return dispatch(ACTIONS.API_GET_LIST).then(list => {
          commit(MUTATIONS.REPLACE_ITEMS, list);
          commit(MUTATIONS.SET_COMPLETELY_LOADED, true);
        });
      }
    },

    [ACTIONS.LOAD_ITEM]({ dispatch, commit, state }, uid: string) {
      if (uid) {
        if (state.items[uid]) {
          return state.items[uid];
        } else {
          return dispatch(ACTIONS.API_GET_BY_ID, uid).then(item => {
            if (item && item.uid) {
              commit(MUTATIONS.UPDATE_ITEMS, [item]);
            }
            return item;
          });
        }
      }
    },
  } as ActionTree<ItemState<T>, RootState>;
}

export function defaultItemMutations<T extends Entity>(MUTATIONS) {
  return {
    [MUTATIONS.REPLACE_ITEMS](state, newList: T[]) {
      return (state.items = listToFrozenMap(newList) as StoredHashmap<T>);
    },

    [MUTATIONS.UPDATE_ITEMS](state, newList: T[]) {
      const newItems = listToFrozenMap(newList) as StoredHashmap<T>;

      state.items = freezeToStopVueReactivity({
        ...state.items,
        ...newItems,
      });
    },

    [MUTATIONS.SET_COMPLETELY_LOADED](state, isLoaded) {
      state.completelyLoaded = isLoaded;
    },
  } as MutationTree<ItemState<T>>;
}
