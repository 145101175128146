import { ComponentOptions } from 'vue';
import { KVComponent, RootState } from 'types';
import { UI } from 'store/actions';
import OperatorView from 'components/ui/OperatorView.vue';
import ScheduleView from 'components/ui/ScheduleView.vue';
import FlmView from 'components/ui/FlmView.vue';
import BookingModal from 'components/ui/BookingModal.vue';
import { CONVEYANCE as CONVEYANCE_GETTERS } from 'store/getters';
import { mapState, mapGetters } from 'vuex';

interface ConnectionViewData extends KVComponent {
  activeViewOnLoad: string;
  activeView: string;
  draggingThreshold: number;
  mousePosition: { x: number; y: number };
  showBookingModal: boolean;
}

export default {
  props: [
    'trainData',
    'connectionName',
    'marker',
    'activeViewOnLoad',
    'bookingLinks',
  ],

  components: {
    OperatorView,
    ScheduleView,
    FlmView,
    BookingModal,
  },

  mounted() {
    this.$store.dispatch(UI.SET_CLOSE_CONNECTION_FILTER, true);
  },

  data() {
    return {
      activeView: this.activeViewOnLoad ? this.activeViewOnLoad : 'operator',
      draggingThreshold: 3,
      mousePosition: {
        x: 0,
        y: 0,
      },
      showBookingModal: false,
    };
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
      mouseIsDragging: state => state.ui.mouseIsDragging,
      uiCloseDetails: state => state.ui.closeDetails,
      currentSelection: state => state.selector,
    }),

    ...mapGetters({
      startForwarders: CONVEYANCE_GETTERS.START_COUNTRY_FORWARDERS,
      endForwarders: CONVEYANCE_GETTERS.DESTINATION_COUNTRY_FORWARDERS,
    }),

    flmData() {
      return {
        first: this.startForwarders,
        last: this.endForwarders,
      };
    },

    showBooking() {
      return this.bookingLinks && this.bookingLinks.length > 0;
    },
  },

  watch: {
    uiCloseDetails: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.closeDetails();
        }
      },
    },
  },

  methods: {
    closeDetails() {
      this.$emit('close');
    },

    mousedown(e) {
      this.mousePosition.x = e.pageX;
      this.mousePosition.y = e.pageY;
      this.$store.dispatch(UI.SET_MOUSE_DRAGGING_STATE, false);
    },

    mouseup(e) {
      const moved =
        Math.abs(this.mousePosition.x - e.pageX) > this.draggingThreshold ||
        Math.abs(this.mousePosition.y - e.pageY) > this.draggingThreshold;

      this.$store.dispatch(UI.SET_MOUSE_DRAGGING_STATE, moved);
    },

    openBooking() {
      this.showBookingModal = true;
    },

    closeBooking() {
      this.showBookingModal = false;
    },
  },
} as ComponentOptions<ConnectionViewData>;
