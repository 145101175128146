import { TERMINAL as ACTIONS, API } from 'store/actions';
import { TERMINAL as MUTATIONS } from 'store/mutations';
import {
  TERMINAL as GETTERS,
  LOCATION as LOCATION_GETTERS,
  GLOBAL as GLOBAL_GETTERS,
} from 'store/getters';
import { defaultValueOnHttpError } from 'utils/helpers';
import {
  defaultItemActions,
  defaultItemState,
  defaultItemMutations,
} from 'store/commons/item-store';
import {
  RootState,
  TerminalState,
  ApplicationMode,
  StoredHashmap,
} from 'types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  defaultSelectionListState,
  defaultSelectionListActions,
  defaultSelectionListGetters,
  defaultSelectionListMutations,
} from 'store/commons/place-selection-list';
import { Terminal, ParsedGeoLocation } from 'kv_shared/lib/data-types';
import { getPlacesByLocationAndCountry } from 'utils/data-transformations';

const state: TerminalState = {
  ...defaultItemState<Terminal>(),
  ...defaultSelectionListState<Terminal>(),

  requestHydrogenPipelines: false,
};

const getters: GetterTree<TerminalState, RootState> = {
  ...defaultSelectionListGetters(GETTERS, ApplicationMode.TERMINALS),

  [GETTERS.ACTIVE_TERMINALS](state) {
    const active: StoredHashmap<Terminal> = {};

    for (const uid in state.items) {
      const t = state.items[uid];

      if (t.active) {
        active[uid] = t;
      }
    }

    return active;
  },

  /**
   * Overrides default getter with only active terminals to be sorted
   */
  [GETTERS.PLACE_AND_LOCATION_STORE](state, getters, rootState) {
    return getPlacesByLocationAndCountry(
      getters[GETTERS.ACTIVE_TERMINALS],
      rootState.locations.items,
      rootState.countries.items,
    );
  },

  [GETTERS.PARSED_POSITIONS](state, getters) {
    const locPos = getters[LOCATION_GETTERS.PARSED_POSITIONS];
    const terminals = getters[GETTERS.ACTIVE_TERMINALS] as StoredHashmap<
      Terminal
    >;

    const positions: { [id: string]: ParsedGeoLocation } = {};
    for (const id in terminals) {
      const t = terminals[id];
      const lid = t && t.locality && t.locality.uid;
      const lp = locPos[lid as string];
      const pos = {
        lat: parseFloat((t && t.loc && t.loc.lat) || (lp && (lp.lat as any))),
        lng: parseFloat((t && t.loc && t.loc.lng) || (lp && (lp.lng as any))),
      };
      positions[id] = pos;
    }
    return positions;
  },

  // HYDROGEN TERMINALS

  [GETTERS.HYDROGEN_TERMINALS](state, getters) {
    const terminals = getters[GETTERS.ACTIVE_TERMINALS] as StoredHashmap<
      Terminal
    >;
    const hydrogenTerminals: StoredHashmap<Terminal> = {};

    for (const uid in terminals) {
      const t = terminals[uid];
      if (
        t.features &&
        t.features.hydrogen &&
        (t.features.hydrogen.isProvider || t.features.hydrogen.isHandling)
      ) {
        hydrogenTerminals[uid] = t;
      }
    }

    return hydrogenTerminals;
  },

  [GETTERS.HYDROGEN_PLACE_AND_LOCATION_STORE](state, getters, rootState) {
    return getPlacesByLocationAndCountry(
      getters[GETTERS.HYDROGEN_TERMINALS],
      rootState.locations.items,
      rootState.countries.items,
    );
  },

  [GETTERS.HYDROGEN_SELECTION_LIST](state, getters, rootState) {
    if (
      getters[GLOBAL_GETTERS.APPLICATION_MODE] !==
      ApplicationMode.HYDROGEN_TERMINALS
    ) {
      return [];
    }

    const locId = rootState.selector.startLocations[0];

    return (
      getters[GETTERS.HYDROGEN_PLACE_AND_LOCATION_STORE].placesByLocation[
        locId
      ] || []
    );
  },

  [GETTERS.RENDER_HYDROGEN_PIPELINES_MAP](state, getters) {
    return (
      state.requestHydrogenPipelines &&
      getters[GLOBAL_GETTERS.APPLICATION_MODE] ===
        ApplicationMode.HYDROGEN_TERMINALS
    );
  },
};

const actions: ActionTree<TerminalState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),
  ...defaultSelectionListActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'place/terminal' })
      .then(response => {
        // TODO: Remove test data in production!!

        // return (response.placeList || []).map((t: Terminal) => {
        //   if (t.features && Math.random() > 0.75) {
        //     t.features.hydrogen = {
        //       ...t.features.hydrogen,
        //       isProvider: true,
        //     };
        //   } else if (t.features && Math.random() > 0.75) {
        //     t.features.hydrogen = {
        //       ...t.features.hydrogen,
        //       isHandling: true,
        //     };
        //   }
        //   return t;
        // });

        return response.placeList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'place/terminal/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.SET_HYDROGEN_PIPELINES_VISIBILITY]({ commit }, status) {
    commit(MUTATIONS.SET_HYDROGEN_PIPELINES_VISIBILITY, status);
  },
};

const mutations: MutationTree<TerminalState> = {
  ...defaultItemMutations(MUTATIONS),
  ...defaultSelectionListMutations(MUTATIONS),

  [MUTATIONS.SET_HYDROGEN_PIPELINES_VISIBILITY](state, status: boolean) {
    state.requestHydrogenPipelines = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
