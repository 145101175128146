import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import { UI } from 'store/actions';

export function setContentView(view) {
  return {
    mounted() {
      this.$store.dispatch(UI.SET_CONTENT_VIEW, view);
    },

    destroyed() {
      this.$store.dispatch(UI.SET_CONTENT_VIEW, '');
      this.$store.dispatch(UI.SET_CLOSE_DETAILS, false);
    },
  } as ComponentOptions<KVComponent>;
}
