// Api module uses the new fetch API and a polyfill for older browsers
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API

import { API as ACTIONS, UI } from 'store/actions';
import { API as MUTATIONS } from 'store/mutations';
import { ApiState, RootState } from 'types';
import * as stringify from 'qs/lib/stringify';
import { ActionTree, MutationTree } from 'vuex';

// Interfaces and Types

interface NetError extends Error {
  response?: any;
  dataPromise?: any;
}

// Module Definition

const state: ApiState = {
  apiUrl: 'https://' + window.location.hostname + '/api/',
};

const actions = {
  [ACTIONS.GET](ctx, { path, query, silent }) {
    const config: RequestInit = {
      method: 'GET',
    };
    if (query) {
      path += '?' + stringify(query);
    }
    return httpRequest(path, config, ctx, silent);
  },
} as ActionTree<ApiState, RootState>;

const mutations = {
  [MUTATIONS.SET_URL](state, url) {
    state.apiUrl = sanitizeApiUrl(url);
  },
} as MutationTree<ApiState>;

export default {
  state,
  actions,
  mutations,
};

// Private helper functions

function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return (
      response
        .json()
        // catch empty body exeption with .json()
        .catch(e => {
          console.warn && console.warn('parsing empty response json', e);
          return {};
        })
    );
  } else {
    const error: NetError = new Error(response.statusText);
    error.response = response;
    error.dataPromise = response.json().catch(e => {
      console.warn && console.warn('parsing empty response json', e);
      return {};
    });
    throw error;
  }

  // TODO: catch 401 and do Reauthorization with roat
  // see old app.module.js
}

function sanitizeApiUrl(url: string) {
  url = url.trim();
  if (url[url.length - 1] !== '/') {
    url += '/';
  }
  return url;
}

function sanitizeEntpoint(url: string) {
  url = url.trim();
  if (url[0] === '/') {
    url = url.substr(1);
  }
  return url;
}

function httpRequest(path, config: RequestInit, actionContext, silent?) {
  const { dispatch, state } = actionContext;

  if (!silent) {
    dispatch(UI.SHOW_LOADER);
  }

  return fetch(state.apiUrl + sanitizeEntpoint(path), config)
    .catch(e => {
      if (!silent) {
        setTimeout(dispatch, 50, UI.HIDE_LOADER);
      }
      throw e;
    })
    .then(response => {
      if (!silent) {
        setTimeout(dispatch, 50, UI.HIDE_LOADER);
      }
      return checkStatus(response);
    });
}
