import { ComponentOptions } from 'vue';
import { BookingLink, KVComponent } from 'types';

interface Component extends KVComponent {
  bookingLinks: BookingLink[];
  onClose: () => void;
  isGerman: boolean;
  linkUrl: (link: BookingLink) => string | undefined;
}

export default {
  props: ['bookingLinks'],

  computed: {
    isGerman() {
      return this.$store.state.locale === 'de';
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    linkUrl(link: BookingLink) {
      return (!this.isGerman && link.urlEN) || link.urlDE;
    },

    openLink(link: BookingLink) {
      const url = this.linkUrl(link);
      if (url) {
        window.open(url, '_blank');
      }
      this.onClose();
    },
  },
} as ComponentOptions<Component>;
