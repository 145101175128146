import { ComponentOptions } from 'vue';
import {
  KVComponent,
  RootState,
  TrainOperatorDetails,
  TrainDetails,
} from 'types';
import { mapState } from 'vuex';
import { weekDaysMini } from 'utils/moment-service';
import { CONNECTION } from 'store/actions';
import { DEFAULT_LANGUAGE } from 'kv_shared/lib/utils/localization';
import { TRANSPORT_FEATURE_MARKERS } from 'utils/constants';

interface Component extends KVComponent {
  currentLanguage: string;
  mouseIsDragging: boolean;
  data: TrainOperatorDetails;
  operatorTrainCurrentIndex: number;
  currentTrain: TrainDetails;
}

export default {
  props: ['data'],

  data() {
    return {
      operatorTrainCurrentIndex: 0,
    };
  },

  computed: {
    ...mapState<RootState>({
      currentLanguage: state => state.locale,
      mouseIsDragging: state => state.ui.mouseIsDragging,
    }),

    currentTrain(this: Component) {
      return this.data.trains[this.operatorTrainCurrentIndex];
    },

    marker(this: Component) {
      return TRANSPORT_FEATURE_MARKERS[this.currentTrain.transportStatus];
    },
  },

  methods: {
    openTrainDetails(uid) {
      if (!this.mouseIsDragging) {
        this.$store.dispatch(CONNECTION.SHOW_TRAIN_DETAILS, uid);
      }
    },

    openOperatorDetails(uid) {
      if (!this.mouseIsDragging) {
        this.$store.dispatch(CONNECTION.SHOW_OPERATOR_DETAILS, uid);
      }
    },

    openTerminalDetails(uid) {
      if (!this.mouseIsDragging) {
        this.$store.dispatch(CONNECTION.SHOW_TERMINAL_DETAILS, uid);
      }
    },

    trainCapacityClass(train) {
      return train.capacitiesLeft === undefined
        ? 'no-data'
        : train.capacitiesLeft
        ? 'capacities'
        : 'no-capacities';
    },

    getTerminalTiming(type, operatorTrainData, uid) {
      let timing = '';
      const trainObject =
        operatorTrainData.train.terminalTimings &&
        operatorTrainData.train.terminalTimings.find(item => item.uid === uid);

      if (trainObject) {
        let timeString = '';
        const trainTimingData = trainObject[type];
        const dayString =
          trainTimingData && trainTimingData.day
            ? weekDaysMini[trainTimingData.day - 1].label[
                this.currentLanguage
              ] || weekDaysMini[trainTimingData.day - 1].label[DEFAULT_LANGUAGE]
            : '';

        if (trainTimingData && trainTimingData.time) {
          // related to new time format
          if (trainTimingData.time.h === undefined) {
            timeString = trainTimingData.time || '';
          } else {
            // or use old one, yet
            const hour = trainTimingData.time.h
              ? String('00' + trainTimingData.time.h).substr(-2)
              : '';
            const minute = trainTimingData.time.m
              ? String('00' + trainTimingData.time.m).substr(-2)
              : '';
            timeString = hour + ':' + minute + ' h';
          }
        }

        timing = dayString + ' ' + timeString;
      }

      return timing;
    },
  },
} as ComponentOptions<Component>;
