import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import { mapGetters } from 'vuex';
import { UI as UI_GETTERS } from 'store/getters';

interface Component extends KVComponent {
  isSelectGroupActive: boolean;
  focusNextSelectElement: (refString) => {};
  isMobile: boolean;
  onlyCountry: boolean;
}

export default {
  props: [
    'currentCountry',
    'currentLocations',
    'label',
    'activateSelectGroup',
    'disabled',
    'selectionText',
    'countryOptions',
    'locationOptions',
    'onlyCountry',
  ],

  data() {
    return {
      isSelectGroupActive: false,
    };
  },

  computed: {
    ...mapGetters({
      isMobile: UI_GETTERS.IS_MOBILE,
    }),

    locationSelection() {
      return this.currentLocations[0];
    },
  },

  watch: {
    activateSelectGroup: 'updateActivateSelectGroup',
  },

  methods: {
    selectCountry(value) {
      this.$emit('selectCountry', value);

      if (!this.onlyCountry) {
        this.focusNextSelectElement('multiselectLocation');
      }
    },

    selectLocation(value) {
      this.$emit('selectLocation', value);
    },

    focusNextSelectElement(refString) {
      if (!this.isMobile) {
        this.$nextTick(() => {
          const selectElement = this.$refs[refString] as any;
          selectElement.$el.focus();
        });
      }
    },

    updateActivateSelectGroup(newValue, oldValue) {
      this.isSelectGroupActive = newValue;
    },
  },
} as ComponentOptions<Component>;
