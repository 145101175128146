import { StoredHashmap, PlaceAndLocationStore } from 'types';
import { Location, Place, Country } from 'kv_shared/lib/data-types';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';

export function getCountriesOfLocations(
  locationItems: StoredHashmap<Location>,
) {
  const countries: { [id: string]: string[] } = {};

  for (const uid in locationItems) {
    const l = locationItems[uid];
    const cid = l.country && l.country.uid;
    if (cid) {
      if (countries[cid]) {
        countries[cid].push(uid);
      } else {
        countries[cid] = [uid];
      }
    }
  }

  return freezeToStopVueReactivity(countries);
}

/**
 * Generates lists of places grouped by location and grouped by country.
 */
export function getPlacesByLocationAndCountry(
  places: StoredHashmap<Place>,
  locations: StoredHashmap<Location>,
  countries: StoredHashmap<Country>,
) {
  const locs: PlaceAndLocationStore<Place> = {
    placesByLocation: {},
    placesByCountry: {},
    locationsByCountry: {},
  };

  for (const uid in places) {
    const item = places[uid];
    const locationId = item.locality && item.locality.uid;

    if (locationId) {
      const loc = locations[locationId];
      const countryId = loc && loc.country && loc.country.uid;

      // new location
      if (!locs.placesByLocation[locationId]) {
        locs.placesByLocation[locationId] = [item];

        if (countryId) {
          locs.locationsByCountry[countryId]
            ? locs.locationsByCountry[countryId].push(loc)
            : (locs.locationsByCountry[countryId] = [loc]);
        }

        // add place to existing location
      } else {
        locs.placesByLocation[locationId].push(item);
      }

      if (countryId) {
        locs.placesByCountry[countryId]
          ? locs.placesByCountry[countryId].push(item)
          : (locs.placesByCountry[countryId] = [item]);
      }
    }
  }

  return freezeToStopVueReactivity(locs);
}
