import { KVComponent } from 'types';
import { ComponentOptions } from 'vue';
import ImprintContactBlock from 'components/ui/ImprintContactBlock.vue';
import ImprintBlock from 'components/ui/ImprintBlock.vue';
import PartnerBlock from 'components/ui/PartnerBlock.vue';
import PrivacyBlock from 'components/ui/PrivacyBlock.vue';
import { MAP } from 'store/actions';

export default {
  components: {
    ImprintContactBlock,
    ImprintBlock,
    PartnerBlock,
    PrivacyBlock,
  },

  props: ['scrollTo'],

  created() {
    this.$store.dispatch(MAP.RENDER, {});
    this.$store.dispatch(MAP.ZOOM_TO_WORLD);
  },

  mounted() {
    if (this.scrollTo) {
      const target = this.$el.querySelector(this.scrollTo);
      if (target !== null && target !== undefined) {
        this.$el.scrollTop = target.offsetTop;
      }
    }
  },
} as ComponentOptions<KVComponent>;
