import { ComponentOptions } from 'vue';
import { KVComponent, RootState } from 'types';
import PerfectScrollbar from 'perfect-scrollbar';
import { mapState, mapGetters } from 'vuex';
import { UI as UI_GETTERS } from 'store/getters';

interface PerfectScrollbarMixin extends KVComponent {
  uiWindowSize: any;
  perfectScrollbarInstance: any;
  initScrollbar: () => {};
  destroyScrollbar: () => {};
  scrollToElement: (scrollTo) => {};
  uiHasMainContentMobileScrollbar: boolean;
  shouldDeactivateOnMobile: boolean;
  scrollTo: string;
}

export function perfectScrollbarComponent() {
  return {
    props: ['scrollTo'],

    data() {
      return {
        perfectScrollbarInstance: null,
        shouldDeactivateOnMobile: false,
      };
    },

    mounted() {
      const ref = this.$refs.scrollContainer as any;
      this.shouldDeactivateOnMobile =
        ref && ref.hasAttribute('data-scrollbar-deactivate-mobile');

      // Init inner scrollbars only if NOT mobile
      if (
        this.shouldDeactivateOnMobile &&
        !this.uiHasMainContentMobileScrollbar
      ) {
        this.initScrollbar();
      }

      // Init all other scrollbars always
      if (!this.shouldDeactivateOnMobile) {
        this.initScrollbar();
      }
    },

    beforeDestroy() {
      this.destroyScrollbar();
    },

    computed: {
      ...mapState<RootState>({
        uiWindowSize: state => state.ui.windowSize,
      }),

      ...mapGetters({
        uiHasMainContentMobileScrollbar:
          UI_GETTERS.HAS_MAIN_CONTENT_MOBILE_SCROLLBAR,
      }),
    },

    watch: {
      uiWindowSize: 'updateScrollbar',
    },

    methods: {
      updateScrollbar() {
        if (this.shouldDeactivateOnMobile) {
          // console.log('updateScrollbar: ');
          if (this.uiHasMainContentMobileScrollbar) {
            this.destroyScrollbar();
          } else {
            this.initScrollbar();
          }
        }
      },

      initScrollbar() {
        const ref = this.$refs.scrollContainer as any;

        if (ref && this.perfectScrollbarInstance === null) {
          // console.log('create at: ', this.uiWindowSize.width, ' ref ', ref);
          this.perfectScrollbarInstance = new PerfectScrollbar(ref, {
            scrollXMarginOffset: 5,
            scrollYMarginOffset: 5,
          });

          this.scrollToElement(this.scrollTo);
        }
      },

      destroyScrollbar() {
        const ref = this.$refs.scrollContainer as any;

        if (ref && this.perfectScrollbarInstance !== null) {
          // console.log('destroy at: ', this.uiWindowSize.width, ' ref ', ref);
          this.perfectScrollbarInstance.destroy();
          this.perfectScrollbarInstance = null;
        }
      },

      scrollToElement(scrollTo) {
        if (scrollTo) {
          const ref = this.$refs.scrollContainer as any;
          const target = ref.querySelector(scrollTo) as any;
          if (target !== null && target !== undefined) {
            ref.scrollTop = target.offsetTop;
          }
        }
      },
    },
  } as ComponentOptions<PerfectScrollbarMixin>;
}
