import isEqual from 'lodash/isEqual';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';

export function listToFrozenMap<T>(list: T[], propName = 'uid') {
  const map: { [id: string]: Readonly<T> } = {};
  for (const item of list) {
    map[item[propName]] = freezeToStopVueReactivity(item);
  }
  return freezeToStopVueReactivity(map);
}

export const localeCompareAvailable = typeof 'x'.localeCompare === 'function';

export function sortListBy<T>(list: T[], ...properties) {
  return list.sort((a, b) => {
    let result = 0;

    for (const prop of properties) {
      const valA = a[prop];
      const valB = b[prop];

      if (
        typeof valA === 'string' &&
        typeof valB === 'string' &&
        localeCompareAvailable
      ) {
        result = valA.toLowerCase().localeCompare(valB.toLowerCase());
      } else {
        result = valA > valB ? 1 : valA < valB ? -1 : 0;
      }
      if (result !== 0) {
        return result;
      }
    }
    return result;
  });
}

export function defaultValueOnHttpError(val) {
  return error => {
    console.error('handleHttpError', error);
    return val;
  };
}

export function normalizeUrl(url) {
  if (url && url.toLowerCase().indexOf('http') !== 0) {
    url = 'http://' + url;
  }
  return url;
}

export function normalizePhone(phonenumber) {
  return phonenumber.replace(/[\s-\/]/g, '');
}

// compare data with default values and return changes, to save traffic send to server.
// unused for now cause api does not delete untransmitted keys.
export function deepDiffWithDefaults(data: object, defaults: object): object {
  const result = Array.isArray(data) ? [] : {};

  for (const key in data) {
    const value = data[key];

    // include missing defaults,
    // or default values that needs to be transmitted,
    // like true boolean, non empty strings, numbers, arrays
    if (
      !defaults ||
      (typeof value === 'boolean' && value) ||
      (typeof value === 'string' && value) ||
      typeof value === 'number' ||
      Array.isArray(value)
    ) {
      result[key] = value;
      continue;

      // omit if values are equal
    } else if (isEqual(defaults[key], value)) {
      continue;
    }

    // traverse tree if needed
    result[key] =
      typeof value === 'object'
        ? deepDiffWithDefaults(value, defaults[key])
        : value;
  }

  return result;
}

export function stripTags(content) {
  return content.replace(/<.+?>/g, '');
}
