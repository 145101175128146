/**
 * Extend Vue-multiselect via mixin
 * To enhance the template for this specific use-case
 */

import { mapState } from 'vuex';
import { ComponentOptions } from 'vue';
import { KVComponent } from 'types';
import Multiselect from 'vue-multiselect';

interface MulticheckboxData extends KVComponent {
  deactivate: () => {};
}

export default {
  mixins: [Multiselect],

  props: {
    showMaxElementsWarning: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState<any>({}),
  },

  methods: {
    handleBlur(event) {
      // Only deactivate the multicheckbox if some random element is targetted when blur occurs
      // This will prevent the box from closing when the submit button is focused via tab key
      // console.log(event.relatedTarget);
      if (
        event.relatedTarget === null ||
        !event.relatedTarget.hasAttribute('data-button-submit')
      ) {
        this.deactivate();
      }
    },
  },
} as ComponentOptions<MulticheckboxData>;
