import { MAP as ACTIONS } from 'store/actions';
import { MAP as MUTATIONS } from 'store/mutations';
import { RootState, MapState, MapZoomType } from 'types';
import { ActionTree, MutationTree } from 'vuex';

const state: MapState = {
  renderItems: {},
  zoom: null,
  interaction: null,
};

const actions: ActionTree<MapState, RootState> = {
  [ACTIONS.REGISTER_INTERACTION]({ commit, dispatch }, interaction) {
    commit(MUTATIONS.SET_INTERACTION, interaction);
  },

  [ACTIONS.RENDER]({ commit, dispatch }, data) {
    commit(MUTATIONS.SET_RENDER, data);
  },

  [ACTIONS.ZOOM_IN]({ commit, dispatch }) {
    commit(MUTATIONS.SET_ZOOM, {
      type: MapZoomType.ZOOM_IN,
    });
  },

  [ACTIONS.ZOOM_OUT]({ commit, dispatch }) {
    commit(MUTATIONS.SET_ZOOM, {
      type: MapZoomType.ZOOM_OUT,
    });
  },

  [ACTIONS.ZOOM_TO_CONNECTIONS]({ commit, dispatch }) {
    commit(MUTATIONS.SET_ZOOM, {
      type: MapZoomType.ZOOM_TO_CONNECTIONS,
    });
  },

  [ACTIONS.ZOOM_TO_START_COUNTRY]({ commit, dispatch }) {
    commit(MUTATIONS.SET_ZOOM, {
      type: MapZoomType.ZOOM_TO_START_COUNTRY,
    });
  },

  [ACTIONS.ZOOM_TO_WORLD]({ commit, dispatch }) {
    commit(MUTATIONS.SET_ZOOM, {
      type: MapZoomType.ZOOM_TO_WORLD,
    });
  },

  [ACTIONS.ZOOM_TO_PLACES]({ commit, dispatch }) {
    commit(MUTATIONS.SET_ZOOM, {
      type: MapZoomType.ZOOM_TO_PLACES,
    });
  },
};

const mutations: MutationTree<MapState> = {
  [MUTATIONS.SET_RENDER](state, spec) {
    state.renderItems = spec;
  },
  [MUTATIONS.SET_ZOOM](state, zoom) {
    state.zoom = zoom;
  },
  [MUTATIONS.SET_INTERACTION](state, interaction) {
    state.interaction = interaction;
  },
};

export default {
  state,
  actions,
  mutations,
};
