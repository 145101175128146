import Vue from 'vue';
import Vuex from 'vuex';
import api from 'store/modules/api';
import terminals from 'store/modules/terminals';
import companies from 'store/modules/companies';
import locations from 'store/modules/locations';
import countries from 'store/modules/countries';
import loadingPoints from 'store/modules/loadingpoints';
import wagonLoad from 'store/modules/wagonload';
import connections from 'store/modules/connections';
import trains from 'store/modules/trains';
import conveyance from 'store/modules/conveyance';
import news from 'store/modules/news';
import misc from 'store/modules/misc';
import ui from 'store/modules/ui';
import selector from 'store/modules/selector';
import map from 'store/modules/map';
import rootStore from 'store/root';
import { RootState } from 'types';

Vue.use(Vuex);

const store = new Vuex.Store<RootState>({
  ...rootStore,
  modules: {
    api,
    ui,
    terminals,
    companies,
    locations,
    countries,
    loadingPoints,
    wagonLoad,
    connections,
    trains,
    conveyance,
    news,
    misc,
    selector,
    map,
  },
});

(window as any).vuex = store;

export default store;
