import { TERMINAL } from 'store/actions';
import { TERMINAL as GETTERS } from 'store/getters';
// tslint:disable-next-line:max-line-length
import { StoredEntity } from 'types';
import { ComponentOptions } from 'vue';
import {
  selectionListComponent,
  SelectionListMixin,
} from 'components/mixins/selection-list-component';
import { Terminal, ParsedGeoLocation } from 'kv_shared/lib/data-types';
import { API_ENDPOINT } from 'utils/constants';
import { terminalFeatures } from 'components/mixins/terminal-features';
import { createTerminalRenderOption } from 'utils/map-helpers';
import { placeMapInteractions } from 'components/mixins/place-map-setup';

interface Component extends SelectionListMixin<Terminal> {
  positions: { [id: string]: ParsedGeoLocation };
}

export default {
  data() {
    return {
      documentsApiEndpoint: API_ENDPOINT.TERMINAL,
      legendKeysList: [],
    };
  },

  mixins: [
    selectionListComponent(TERMINAL, GETTERS),
    terminalFeatures(),
    placeMapInteractions(),
  ],

  computed: {
    item() {
      return this.$store.state.terminals.selectedItem;
    },
    items() {
      return this.$store.getters[GETTERS.ACTIVE_TERMINALS]; // needed for map rendering
    },

    positions() {
      return this.$store.getters[GETTERS.PARSED_POSITIONS];
    },

    keysActive() {
      return true;
    },
  },

  methods: {
    getPlaceMapRenderOptions(t: StoredEntity<Terminal>) {
      return createTerminalRenderOption(
        t,
        this.positions[t.uid],
        this.item && this.item.uid === t.uid,
      );
    },
  },
} as ComponentOptions<Component>;
