import { CONNECTION as ACTIONS, API, TRAIN } from 'store/actions';
// tslint:disable-next-line:max-line-length
import {
  CONNECTION as GETTERS,
  SELECTOR as SELECTOR_GETTERS,
  LOCATION as LOCATION_GETTERS,
  TERMINAL as TERMINAL_GETTERS,
  COMPANY as COMPANY_GETTERS,
  GLOBAL as GLOBAL_GETTERS,
} from 'store/getters';
import { CONNECTION as MUTATIONS } from 'store/mutations';
import { defaultValueOnHttpError, listToFrozenMap } from 'utils/helpers';
import {
  defaultItemActions,
  defaultItemMutations,
  defaultItemState,
} from 'store/commons/item-store';
// tslint:disable-next-line:max-line-length
import {
  RootState,
  ConnectionState,
  ConnectionListItem,
  ApplicationMode,
  SelectionMode,
  ConnectionHop,
  TrainConnectionDetail,
  TrainOperatorDetails,
  TrainDetails,
  ConnectionFilter,
  StoredHashmap,
  TransportFeatureStatus,
  ConnectionResultTrain,
  ConnectionDetails,
  StoredEntity,
  BookingLink,
} from 'types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
// tslint:disable-next-line:max-line-length
import { defaultSelectionListMutations } from 'store/commons/place-selection-list';
import { getPlacesByLocationAndCountry } from 'utils/data-transformations';
// tslint:disable-next-line:max-line-length
import {
  Company,
  ConnectionSearchResult,
  Entity,
  Terminal,
  TransportUsableUnits,
} from 'kv_shared/lib/data-types';
import { freezeToStopVueReactivity } from 'utils/vue-helpers';
import isEqual from 'lodash/isEqual';
import zipWith from 'lodash/zipWith';
import { bookableConnectionId } from 'kv_shared/lib/bookingProviderHack';

const state: ConnectionState = {
  ...defaultItemState(),
  selectedConnections: [],
  directConnections: [],
  terminalsWithTrains: {},

  selectedItem: null,

  isSearching: false,
  selectedOperatorUid: '',
  selectedTerminalUid: '',
  selectedTrainUid: '',
  showDirectConnections: false,
  terminalsLoaded: false,

  filter: new ConnectionFilter(),
  usableUnits: null,
};

// Dont use default selectionlist getters here, but implement all the getters manually
const getters: GetterTree<ConnectionState, RootState> = {
  [GETTERS.USED_TERMINALS](state, getters, rootState, rootGetters) {
    // Show all active terminals for locations that have connections
    const locations = {};

    const terminalsWithTrainsAvailable =
      state.terminalsLoaded &&
      Object.keys(state.terminalsWithTrains).length > 0;

    if (terminalsWithTrainsAvailable) {
      for (const terminalUid in state.terminalsWithTrains) {
        const t = state.terminalsWithTrains[terminalUid];
        if (t.locality && t.locality.uid) {
          locations[t.locality.uid] = true;
        }
      }
    } else {
      for (const uid in state.items) {
        const c = state.items[uid];
        if (c.locality && c.locality.length) {
          for (const loc of c.locality) {
            const location =
              rootState.locations.items[loc && (loc.uid as string)];
            if (location && location.uid) {
              locations[location.uid] = true;
            }
          }
        }
      }
    }

    const terminals: StoredHashmap<Terminal> = {};

    for (const locId in locations) {
      const ts =
        getters[TERMINAL_GETTERS.PLACE_AND_LOCATION_STORE].placesByLocation[
          locId
        ];
      if (ts && ts.length) {
        for (const t of ts) {
          terminals[t.uid] = t;
        }
      }
    }

    return freezeToStopVueReactivity(terminals);
  },

  [GETTERS.PLACE_AND_LOCATION_STORE](state, getters, rootState) {
    return getPlacesByLocationAndCountry(
      getters[GETTERS.USED_TERMINALS],
      rootState.locations.items,
      rootState.countries.items,
    );
  },

  [GETTERS.ALL_BOOKABLE_CONNECTIONS](state, getters) {
    const connections: { [connectionId: string]: BookingLink[] } = {};

    for (const provider of getters[
      COMPANY_GETTERS.BOOKING_PROVIDERS
    ] as StoredEntity<Company>[]) {
      if (provider.name && provider.urls.url1 && provider.bookableConnections) {
        const link: BookingLink = freezeToStopVueReactivity({
          id: provider.uid,
          label: provider.name,
          urlDE: provider.urls.url1,
          urlEN: provider.urls.url2,
        });

        for (const connection of provider.bookableConnections) {
          const providers = connections[connection] || [];
          providers.push(link);
          connections[connection] = providers;
        }
      }
    }

    return freezeToStopVueReactivity(connections);
  },

  [GETTERS.TRANSFORMED_SEARCH_RESULTS](state, getter, rootState) {
    const locationNames = getter[LOCATION_GETTERS.LOCALE_NAMES];
    const cs = state.selectedConnections;

    const connectionDetails: ConnectionListItem[] = [];

    if (
      getter[GLOBAL_GETTERS.APPLICATION_MODE] !== ApplicationMode.CONNECTIONS ||
      getter[SELECTOR_GETTERS.SELECTION_MODE] !== SelectionMode.ALL_SELECTED
    ) {
      return connectionDetails;
    }

    const bookable = getter[GETTERS.ALL_BOOKABLE_CONNECTIONS];

    for (const connection of cs) {
      const firstHop = connection.connection[0];
      const hops: ConnectionHop[] = [];

      let label = locationNames[firstHop.uidFrom];
      let connectionStatus = TransportFeatureStatus.CAPACITIES;

      for (const hop of connection.connection) {
        label += ' - ' + locationNames[hop.uidTo];

        const trains: ConnectionResultTrain[] = [];

        let hopStatus = TransportFeatureStatus.NO_CAPACITIES;

        for (const zug of hop.zug) {
          const train = rootState.trains.items[zug.train.uid as any];
          const trainStatus = getTrainStatus(
            state.usableUnits,
            train && train.usableUnits,
          );

          trains.push({
            ...zug,
            train,
            transportStatus: trainStatus,
          });

          hopStatus = getHopStatus(hopStatus, trainStatus);
        }

        hops.push(
          freezeToStopVueReactivity({
            active: hop.active,
            distance: hop.distance,
            fromLocationUid: hop.uidFrom,
            toLocationUid: hop.uidTo,
            transportStatus: hopStatus,
            trains,
            bookableBy:
              bookable[
                bookableConnectionId([{ uid: hop.uidFrom }, { uid: hop.uidTo }])
              ],
          }),
        );

        connectionStatus = getConnectionStatus(connectionStatus, hopStatus);
      }

      let bookableBy: BookingLink[] | undefined;

      if (hops.length === 1) {
        bookableBy = hops[0].bookableBy;
      }

      connectionDetails.push(
        freezeToStopVueReactivity({
          label,
          connections: freezeToStopVueReactivity(hops),
          realDistance: connection.realDistance,
          operatorChanges: connection.operatorChanges,
          terminalChanges: connection.terminalChanges,
          transferNumber: connection.transferNumber,
          transportStatus: connectionStatus,
          bookableBy,
        }),
      );
    }

    return freezeToStopVueReactivity(connectionDetails);
  },

  [GETTERS.FILTERED_SEARCH_RESULTS](state, getters) {
    const results: ConnectionListItem[] =
      getters[GETTERS.TRANSFORMED_SEARCH_RESULTS];
    const filter = state.filter;

    if (!filter) {
      return results;
    }

    // filter by nr of max changes
    let filteredConnections = results.filter(
      connectionBlock =>
        connectionBlock.connections.length <= filter.changeCount + 1,
    );

    // filter by terminal changes
    if (!filter.changeTerminal) {
      filteredConnections = filteredConnections.filter(
        connectionBlock => connectionBlock.terminalChanges === 0,
      );
    }

    // filter by operator changes
    if (!filter.changeOperator) {
      filteredConnections = filteredConnections.filter(
        connectionBlock => connectionBlock.operatorChanges === 0,
      );
    }

    return freezeToStopVueReactivity(filteredConnections);
  },

  [GETTERS.FILTERED_BOOKING_LINKS](state, getter) {
    const linksMap = {};
    const results: ConnectionListItem[] =
      getter[GETTERS.FILTERED_SEARCH_RESULTS];

    for (const res of results) {
      if (res.bookableBy) {
        for (const link of res.bookableBy) {
          linksMap[link.id] = link;
        }
      }
    }

    return freezeToStopVueReactivity(Object.values(linksMap));
  },
};

const actions: ActionTree<ConnectionState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  // Get all train data from API for the selected connection and save as selected item
  [ACTIONS.SELECT_ITEM](
    { commit, dispatch, rootState },
    item?: ConnectionListItem,
  ) {
    if (!item) {
      return commit(MUTATIONS.SET_CURRENT_ITEM, null);
    }

    const locations = rootState.locations.items;
    const companies = rootState.companies.items;
    const terminals = rootState.terminals.items;

    const connections: TrainConnectionDetail[] = [];

    for (const hop of item.connections) {
      const perOperator: { [uid: string]: TrainOperatorDetails } = {};

      if (hop.trains) {
        for (const train of hop.trains) {
          const opUid = train.operator.uid as string;

          if (!perOperator[opUid]) {
            perOperator[opUid] = {
              operator: companies[opUid],
              terminals: {
                from: {},
                to: {},
              },
              trains: [],
            };
          }

          const trainData: TrainDetails = {
            terminalsFrom: train.terminalFrom.map(
              t => terminals[t.uid as string],
            ),
            terminalsTo: train.terminalTo.map(t => terminals[t.uid as string]),
            train: train.train,
            transportStatus: train.transportStatus,
          };

          perOperator[opUid].trains.push(trainData);
          trainData.terminalsFrom.forEach(
            t => (perOperator[opUid].terminals.from[t.uid] = t),
          );
          trainData.terminalsTo.forEach(
            t => (perOperator[opUid].terminals.to[t.uid] = t),
          );
        }
      }

      const data: TrainConnectionDetail = {
        from: locations[hop.fromLocationUid as string],
        to: locations[hop.toLocationUid as string],
        trains: perOperator,
        transportStatus: hop.transportStatus as TransportFeatureStatus,
      };

      connections.push(freezeToStopVueReactivity(data));
    }

    const selected: ConnectionDetails = freezeToStopVueReactivity({
      label: item.label,
      connections: freezeToStopVueReactivity(connections),
      transportStatus: item.transportStatus,
      bookableBy: item.bookableBy,
    });

    commit(MUTATIONS.SET_CURRENT_ITEM, selected);
  },

  [ACTIONS.LOAD_TERMINALS_WITH_TRAINS]({ state, dispatch, commit }) {
    if (!state.terminalsLoaded) {
      return dispatch(ACTIONS.API_GET_USED_TERMINALS).then(list => {
        commit(MUTATIONS.SET_TERMINALS_WITH_TRAINS, list);
        commit(MUTATIONS.SET_TERMINALS_LOADED, true);
      });
    }
  },

  [ACTIONS.RESET_ITEM]({ commit }) {
    commit(MUTATIONS.SET_CURRENT_ITEM, null);
  },

  [ACTIONS.RESET_SEARCH_RESULTS]({ commit }) {
    commit(MUTATIONS.SET_SELECTED_CONNECTIONS, []);
  },

  [ACTIONS.RESET_DIRECT_CONNECTIONS]({ commit }) {
    commit(MUTATIONS.SET_DIRECT_CONNECTIONS, []);
    commit(MUTATIONS.SET_DIRECT_CONNECTIONS_VISIBILITY, false);
  },

  [ACTIONS.SEARCH_CONNECTIONS]({ state, rootState, dispatch, commit }) {
    const start = rootState.selector.startLocations.join(',');
    const end = rootState.selector.endLocations.join(',');

    if (start && end) {
      commit(MUTATIONS.SET_SEARCHING, true);
      commit(MUTATIONS.SET_SELECTED_CONNECTIONS, []);

      dispatch(ACTIONS.API_GET_SELECTED_CONNECTIONS, { start, end })
        .then(list =>
          dispatch(ACTIONS.LOAD_ALL_TRAINS_FOR_SEARCH_RESULTS, list),
        )
        .then(list => commit(MUTATIONS.SET_SELECTED_CONNECTIONS, list))
        .then(() => commit(MUTATIONS.SET_SEARCHING, false));
    }
  },

  async [ACTIONS.SEARCH_DIRECT_CONNECTIONS]({ rootState, dispatch, commit }) {
    const start = rootState.selector.startLocations;

    if (start) {
      await dispatch(ACTIONS.RESET_DIRECT_CONNECTIONS);

      const lists = await Promise.all(
        start.map(loc => dispatch(ACTIONS.API_GET_DIRECT_CONNECTIONS, loc)),
      );

      const list = zipWith(start, lists, (startId, l) =>
        l.map(dest => ({ startId, destId: dest.uid })),
      ).reduce((list, l) => list.concat(l), []);

      commit(MUTATIONS.SET_DIRECT_CONNECTIONS, list);
      commit(MUTATIONS.SET_DIRECT_CONNECTIONS_VISIBILITY, true);
    }
  },

  [ACTIONS.LOAD_ALL_TRAINS_FOR_SEARCH_RESULTS](
    { dispatch },
    list: ReadonlyArray<Readonly<ConnectionSearchResult>>,
  ) {
    const operators: { [uid: string]: boolean } = {};
    const trains: { [uid: string]: boolean } = {};

    for (const connection of list) {
      for (const hop of connection.connection) {
        for (const train of hop.zug) {
          if (train && train.operator && train.operator.uid) {
            operators[train.operator.uid] = true;
          }
          if (train && train.train && train.train.uid) {
            trains[train.train.uid] = true;
          }
        }
      }
    }

    // Load all Trains for Operators
    return (
      Promise.all(
        Object.keys(operators).map(opId =>
          dispatch(TRAIN.LOAD_BY_OPERATOR, opId),
        ),
      )
        // Check if there are any trains left.
        // LOAD_ITEM does not do any request, if the train already exists
        .then(() =>
          Promise.all(
            Object.keys(trains).map(tId => dispatch(TRAIN.LOAD_ITEM, tId)),
          ),
        )
        .catch(e => console.error(e))
        .then(() => list)
    );
  },

  [ACTIONS.SHOW_OPERATOR_DETAILS]({ commit }, uid) {
    commit(MUTATIONS.SET_SELECTED_OPERATOR_UID, uid);
  },

  [ACTIONS.SHOW_TRAIN_DETAILS]({ commit }, uid) {
    commit(MUTATIONS.SET_SELECTED_TRAIN_UID, uid);
  },

  [ACTIONS.SHOW_TERMINAL_DETAILS]({ commit }, uid) {
    commit(MUTATIONS.SET_SELECTED_TERMINAL_UID, uid);
  },

  [ACTIONS.SET_DIRECT_CONNECTIONS_VISIBILITY]({ commit }, status) {
    commit(MUTATIONS.SET_DIRECT_CONNECTIONS_VISIBILITY, status);
  },

  [ACTIONS.SET_FILTER]({ commit }, filter) {
    commit(MUTATIONS.SET_FILTER, filter);
  },

  [ACTIONS.SET_USABLE_UNITS]({ commit }, units) {
    commit(MUTATIONS.SET_USABLE_UNITS, units);
  },

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'schedule/connection' })
      .then(response => {
        return response.connectionList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'schedule/connection/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },

  [ACTIONS.API_GET_SELECTED_CONNECTIONS]({ dispatch }, { start, end }) {
    return dispatch(API.GET, {
      path: 'web/connections',
      query: { from: start, to: end },
    })
      .then(response => {
        return response.connectionList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_DIRECT_CONNECTIONS]({ dispatch, commit }, start) {
    return dispatch(API.GET, {
      path: 'web/directConnectionLocalities/' + start,
    })
      .then(response => {
        return response.directConnectionLocalityList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_USED_TERMINALS]({ dispatch, commit }) {
    return dispatch(API.GET, { path: 'place/terminal/used' })
      .then(response => {
        return response.placeList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },
};

const mutations: MutationTree<ConnectionState> = {
  ...defaultItemMutations(MUTATIONS),
  ...(defaultSelectionListMutations(MUTATIONS) as any),

  [MUTATIONS.SET_SELECTED_CONNECTIONS](state, list: ConnectionSearchResult[]) {
    // freeze the list and its items
    state.selectedConnections = freezeToStopVueReactivity(
      list.map<ConnectionSearchResult>(freezeToStopVueReactivity),
    );
  },

  [MUTATIONS.SET_SEARCHING](state, searching) {
    state.isSearching = searching;
  },

  [MUTATIONS.SET_SELECTED_OPERATOR_UID](state, uid) {
    state.selectedOperatorUid = uid;
  },

  [MUTATIONS.SET_SELECTED_TRAIN_UID](state, uid) {
    state.selectedTrainUid = uid;
  },

  [MUTATIONS.SET_SELECTED_TERMINAL_UID](state, uid) {
    state.selectedTerminalUid = uid;
  },

  [MUTATIONS.SET_DIRECT_CONNECTIONS](state, list: Entity[]) {
    state.directConnections = freezeToStopVueReactivity(
      list.map<Entity>(freezeToStopVueReactivity),
    );
  },

  [MUTATIONS.SET_DIRECT_CONNECTIONS_VISIBILITY](state, status: boolean) {
    state.showDirectConnections = status;
  },

  [MUTATIONS.SET_TERMINALS_WITH_TRAINS](state, list) {
    state.terminalsWithTrains = listToFrozenMap(list);
  },

  [MUTATIONS.SET_TERMINALS_LOADED](state, loaded: boolean) {
    state.terminalsLoaded = loaded;
  },

  [MUTATIONS.SET_FILTER](state, filter) {
    if (!filter || isEqual(filter, defaultFilter)) {
      state.filter = new ConnectionFilter();
    } else {
      state.filter = freezeToStopVueReactivity({ ...filter });
    }
  },

  [MUTATIONS.SET_USABLE_UNITS](state, units) {
    if (!units || equalUsableUnits(units, defaultUsableUnits)) {
      state.usableUnits = null;
    } else {
      state.usableUnits = freezeToStopVueReactivity({ ...units });
    }
  },
};

const defaultUsableUnits = new TransportUsableUnits();
const defaultFilter = new ConnectionFilter();

export default {
  state,
  getters,
  actions,
  mutations,
};

function equalUsableUnits(
  units1: TransportUsableUnits,
  units2: TransportUsableUnits,
) {
  for (const k in units1) {
    if (
      units1.hasOwnProperty(k) &&
      units2.hasOwnProperty(k) &&
      units1[k] !== units2[k]
    ) {
      return false;
    }
  }
  return true;
}

function getTrainStatus(
  filter: TransportUsableUnits | null,
  trainUnits?: TransportUsableUnits,
) {
  if (!trainUnits || equalUsableUnits(trainUnits, defaultUsableUnits)) {
    return TransportFeatureStatus.NO_DATA;
  }
  if (!filter) {
    return TransportFeatureStatus.CAPACITIES;
  }

  let someHits = false;
  let allHits = true;

  for (const k in filter) {
    if (filter.hasOwnProperty(k) && filter[k]) {
      if (trainUnits[k]) {
        someHits = true;
      } else {
        allHits = false;
      }
    }
  }

  if (allHits) {
    return TransportFeatureStatus.CAPACITIES;
  } else if (someHits) {
    return TransportFeatureStatus.SOME_CAPACITIES;
  }

  return TransportFeatureStatus.NO_CAPACITIES;
}

function getHopStatus(
  currentStatus: TransportFeatureStatus,
  trainStatus: TransportFeatureStatus,
) {
  if (trainStatus === TransportFeatureStatus.CAPACITIES) {
    return trainStatus;
  }
  if (currentStatus === TransportFeatureStatus.CAPACITIES) {
    return currentStatus;
  }
  if (trainStatus === TransportFeatureStatus.SOME_CAPACITIES) {
    return trainStatus;
  }
  if (currentStatus === TransportFeatureStatus.SOME_CAPACITIES) {
    return currentStatus;
  }
  if (trainStatus === TransportFeatureStatus.NO_DATA) {
    return trainStatus;
  }
  if (currentStatus === TransportFeatureStatus.NO_DATA) {
    return currentStatus;
  }
  return TransportFeatureStatus.NO_CAPACITIES;
}

function getConnectionStatus(
  currentStatus: TransportFeatureStatus,
  hopStatus: TransportFeatureStatus,
) {
  if (hopStatus === TransportFeatureStatus.NO_CAPACITIES) {
    return hopStatus;
  }
  if (currentStatus === TransportFeatureStatus.NO_CAPACITIES) {
    return currentStatus;
  }
  if (hopStatus === TransportFeatureStatus.NO_DATA) {
    return hopStatus;
  }
  if (currentStatus === TransportFeatureStatus.NO_DATA) {
    return currentStatus;
  }
  if (hopStatus === TransportFeatureStatus.SOME_CAPACITIES) {
    return hopStatus;
  }
  if (currentStatus === TransportFeatureStatus.SOME_CAPACITIES) {
    return currentStatus;
  }
  return TransportFeatureStatus.CAPACITIES;
}
