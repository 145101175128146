import { ComponentOptions } from 'vue';
import {
  KVComponent,
  RootState,
  StoredEntity,
  MapInteractionType,
  MapZoomType,
  MapRenderSpec,
  StoredHashmap,
} from 'types';
import { MapController } from 'components/main/mapController';
import {
  COUNTRY as COUNTRY_GETTERS,
  TERMINAL as TERMINAL_GETTERS,
} from 'store/getters';
import { mapGetters, mapState } from 'vuex';
import { Country } from 'kv_shared/lib/data-types';
import { MAP } from 'store/actions';

export interface Component extends KVComponent {
  map: MapController;
  language: string;
  zoom: { type: MapZoomType };
  renderItems: MapRenderSpec;
  countriesByCode: { [code: string]: StoredEntity<Country> };
  countries: StoredHashmap<Country>;
  renderHydrogenPipelines: boolean;

  updateLanguage: () => {};
  setZoom: () => {};
  render: () => {};
  setupEvents: () => {};
}

export enum MapEvents {
  COUNTRY_CLICKED = 'map_event::country_clicked',
  LOCATION_CLICKED = 'map_event::location_clicked',
  LOCATION_CLOSED = 'map_event::location_closed',
  PLACE_CLICKED = 'map_event::place_clicked',
  COUNTRY_ITEM_CLICKED = 'map_event::country_item_clicked',
}

export default {
  computed: {
    ...mapState<RootState>({
      language: state => state.locale,
      zoom: state => state.map.zoom,
      renderItems: state => state.map.renderItems,
      countries: state => state.countries.items,
    }),

    ...mapGetters({
      countriesByCode: COUNTRY_GETTERS.BY_COUNTRY_CODE,
      renderHydrogenPipelines: TERMINAL_GETTERS.RENDER_HYDROGEN_PIPELINES_MAP,
    }),
  },

  mounted(this: Component) {
    this.map = new MapController(this, this.$store.state.apiConfig);
    this.setupEvents();
    this.updateLanguage();
  },

  watch: {
    language: 'updateLanguage',
    zoom: 'setZoom',
    renderItems: 'render',
    renderHydrogenPipelines: 'toggleHydrogenPipelines',
  },

  methods: {
    setZoom() {
      switch (this.zoom.type) {
        case MapZoomType.ZOOM_IN:
          this.map.zoom(1);
          break;

        case MapZoomType.ZOOM_OUT:
          this.map.zoom(-1);
          break;

        case MapZoomType.ZOOM_TO_CONNECTIONS:
          this.map.zoomToConnections();
          break;

        case MapZoomType.ZOOM_TO_PLACES:
          this.map.zoomToPlaces();
          break;

        case MapZoomType.ZOOM_TO_START_COUNTRY:
          const cid = this.$store.state.selector.startCountry;
          if (cid) {
            const c = this.countries[cid];
            this.map.zoomToCountry(c.center || c.code);
          }
          break;

        case MapZoomType.ZOOM_TO_WORLD:
          this.map.zoomToWorld();
          break;
      }
    },

    render() {
      this.map.render(this.renderItems);
    },

    setupEvents() {
      this.$on(MapEvents.COUNTRY_CLICKED, data => {
        this.$store.dispatch(MAP.REGISTER_INTERACTION, {
          type: MapInteractionType.COUNTRY_CLICKED,
          payload: data,
        });
      });

      this.$on(MapEvents.LOCATION_CLICKED, data => {
        this.$store.dispatch(MAP.REGISTER_INTERACTION, {
          type: MapInteractionType.LOCATION_CLICKED,
          payload: data,
        });
      });

      this.$on(MapEvents.LOCATION_CLOSED, data => {
        this.$store.dispatch(MAP.REGISTER_INTERACTION, {
          type: MapInteractionType.LOCATION_CLOSED,
          payload: data,
        });
      });

      this.$on(MapEvents.PLACE_CLICKED, data => {
        this.$store.dispatch(MAP.REGISTER_INTERACTION, {
          type: MapInteractionType.PLACE_CLICKED,
          payload: data,
        });
      });

      this.$on(MapEvents.COUNTRY_ITEM_CLICKED, data => {
        this.$store.dispatch(MAP.REGISTER_INTERACTION, {
          type: MapInteractionType.COUNTRY_ITEM_CLICKED,
          payload: data,
        });
      });
    },

    updateLanguage() {
      this.map.setLanguage(this.language);
    },

    toggleHydrogenPipelines() {
      this.map.showHydrogenPipelines(this.renderHydrogenPipelines);
    },
  },
} as ComponentOptions<Component>;
