import { NEWS as ACTIONS, API } from 'store/actions';
import { NEWS as MUTATIONS } from 'store/mutations';
import { defaultValueOnHttpError } from 'utils/helpers';
import {
  defaultItemState,
  defaultItemActions,
  defaultItemMutations,
} from 'store/commons/item-store';
import { RootState, NewsState } from 'types';
import { ActionTree, MutationTree } from 'vuex';

const state: NewsState = {
  ...defaultItemState(),
};

const actions: ActionTree<NewsState, RootState> = {
  ...defaultItemActions(ACTIONS, MUTATIONS),

  // API Actions

  [ACTIONS.API_GET_LIST]({ dispatch }) {
    return dispatch(API.GET, { path: 'misc/news' })
      .then(response => {
        return response.newsList || [];
      })
      .catch(defaultValueOnHttpError([]));
  },

  [ACTIONS.API_GET_BY_ID]({ dispatch }, uid) {
    return dispatch(API.GET, { path: 'misc/news/' + uid }).catch(
      defaultValueOnHttpError(null),
    );
  },
};

const mutations: MutationTree<NewsState> = {
  ...defaultItemMutations(MUTATIONS),
};

export default {
  state,
  actions,
  mutations,
};
