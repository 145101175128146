import { MISC as ACTIONS, API } from 'store/actions';
import { MISC as MUTATIONS } from 'store/mutations';
import { MiscState, RootState } from 'types';
import { ActionTree, MutationTree } from 'vuex';

const state: MiscState = {
  imprintData: {},
  showCookieNotice: true,
  isMatomoTrackingActive: true,
};

const actions: ActionTree<MiscState, RootState> = {
  [ACTIONS.GET_IMPRINT]({ commit, dispatch }) {
    return dispatch(API.GET, { path: 'misc/singleton/imprint' }).then(imprint =>
      commit(MUTATIONS.SET_IMPRINT, imprint),
    );
  },

  [ACTIONS.HIDE_COOKIE_NOTICE]({ commit }) {
    document.cookie =
      'rwt_show_cookie_notice=false; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    commit(MUTATIONS.HIDE_COOKIE_NOTICE);
  },

  [ACTIONS.GET_COOKIE_NOTICE_VISIBILITY]({ commit }) {
    if (
      document.cookie
        .split(';')
        .filter(item => item.includes('rwt_show_cookie_notice=false')).length
    ) {
      commit(MUTATIONS.HIDE_COOKIE_NOTICE);
    }

    if (
      document.cookie
        .split(';')
        .filter(item => item.includes('rwt_show_cookie_notice=true')).length
    ) {
      commit(MUTATIONS.SHOW_COOKIE_NOTICE);
    }
  },

  [ACTIONS.GET_MATOMO_TRACKING]({ commit }) {
    if (
      document.cookie.split(';').filter(item => item.includes('mtm_consent='))
        .length >= 0
    ) {
      commit(MUTATIONS.SET_MATOMO_TRACKING, true);
    }

    if (
      document.cookie
        .split(';')
        .filter(item => item.includes('mtm_consent_removed=')).length
    ) {
      commit(MUTATIONS.SET_MATOMO_TRACKING, false);
    }
  },

  [ACTIONS.SET_MATOMO_TRACKING]({ commit }, active) {
    if (active) {
      window._paq.push(['forgetUserOptOut']);
    } else {
      window._paq.push(['optUserOut']);
    }
    commit(MUTATIONS.SET_MATOMO_TRACKING, active);
  },
};

const mutations: MutationTree<MiscState> = {
  [MUTATIONS.SET_IMPRINT](state, imprint) {
    state.imprintData = imprint;
  },

  [MUTATIONS.SHOW_COOKIE_NOTICE](state) {
    state.showCookieNotice = true;
  },

  [MUTATIONS.HIDE_COOKIE_NOTICE](state) {
    state.showCookieNotice = false;
  },

  [MUTATIONS.SET_MATOMO_TRACKING](state, active) {
    state.isMatomoTrackingActive = active;
  },
};

export default {
  state,
  actions,
  mutations,
};
