// Default configuration
const prefix = 'v-collapse';
const basename = 'collapse';

export const defaults = {
  prefix: prefix,
  basename: basename,
  togglerClassDefault: prefix + '-toggler',
  togglerClassOpen: prefix + '-toggler-open',
  contentClassDefault: prefix + '-content',
  contentClassEnd: prefix + '-content-end',
};

// Global toggle methods
export function toggleElement(target, config) {
  target.content.classList.toggle(config.contentClassEnd);
  target.toggle.classList.toggle(config.togglerClassOpen);
}

export function closeElement(target, config) {
  target.content.classList.remove(config.contentClassEnd);
  target.toggle.classList.remove(config.togglerClassOpen);
}

export function openElement(target, config) {
  target.content.classList.add(config.contentClassEnd);
  target.toggle.classList.add(config.togglerClassOpen);
}
