import { start, store, router } from './app';
import { GLOBAL, SELECTOR } from 'store/actions';
import * as MUTATIONS from 'store/mutations';
import { default as i18n } from 'kv_shared/lib/vue/i18n';
import { availableLanguages } from 'kv_shared/lib/utils/localization';
import devtools from '@vue/devtools';

if (process.env.NODE_ENV === 'development') {
  devtools.connect();
  (window as any).store = store;
}

// Fetch local config and add it to the store
fetch('config.local.json', { credentials: 'include' })
  .then(response => response.json())
  .then(config => {
    store.dispatch(GLOBAL.ADD_CONFIG, config);
  })
  .catch(() => {
    /* no op */
  })
  .then(start);

store.commit(MUTATIONS.GLOBAL.SET_AVAILABLE_LANGUAGES, availableLanguages);

store.watch(
  state => state.route.params.lang,
  value => store.dispatch(GLOBAL.CHANGE_LANGUAGE, value),
);

store.watch(state => state.locale, value => (i18n.locale = value));

router.afterEach(to => {
  store.dispatch(SELECTOR.PROCESS_ROUTE_QUERY);
});

store.dispatch(SELECTOR.PROCESS_ROUTE_QUERY);
