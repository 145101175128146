import { ComponentOptions } from 'vue';
import { KVComponent, LocationQueryOption } from 'types';
import { UI as UI_GETTERS } from 'store/getters';
import { mapGetters } from 'vuex';
import { availablePLZApiCountryCodes } from 'kv_shared/lib/constants';

interface Component extends KVComponent {
  currentLocations: Array<{ value: string; label: string }>;
  currentCountry: any;
  isSelectGroupActive: boolean;
  radiusOptions: [string];
  allLocationsSelected: boolean;
  locationSelection: Array<{ value: string; label: string }>;
  locationOptions: Array<{ value: string; label: string }>;
  focusNextSelectElement: (refString) => {};
  isMobile: boolean;
  currentGeoPosQueryLength: number;
  geoLocationOptions: LocationQueryOption[];
}

export default {
  props: [
    'currentCountry',
    'currentGeoLocation',
    'currentRadius',
    'currentLocations',
    'label',
    'labelLocation',
    'placeholderMulticheckbox',
    'activateSelectGroup',
    'disabled',
    'countryOptions',
    'geoLocationOptions',
    'locationOptions',
    'dataType',
  ],

  data() {
    return {
      radiusOptions: [
        { value: 5, label: '5km' },
        { value: 10, label: '10km' },
        { value: 25, label: '25km' },
        { value: 50, label: '50km' },
      ],
      allLocationsSelected: false,
      locationSelection: [],
      isSelectGroupActive: false,
      currentGeoPosQueryLength: 0,
    };
  },

  computed: {
    ...mapGetters({
      isMobile: UI_GETTERS.IS_MOBILE,
    }),

    currentRadiusSelection() {
      return this.radiusOptions.find(opt => opt.value === this.currentRadius);
    },

    doneSearchWithNoResults() {
      return (
        !this.locationOptions.length &&
        this.currentGeoLocation &&
        this.currentCountry
      );
    },

    selectionText() {
      let label = '';
      if (this.currentCountry) {
        label += this.currentCountry.label;
        if (this.currentGeoLocation) {
          label += ', ' + this.currentGeoLocation.label;
        }
      }
      return label;
    },

    countryUnavailable(this: Component) {
      if (this.currentCountry) {
        const country = this.$store.state.countries.items[
          this.currentCountry.value
        ];
        return !availablePLZApiCountryCodes[country.code];
      }
      return false;
    },

    multicheckboxDisabled() {
      return (
        !this.locationOptions ||
        this.locationOptions.length === 0 ||
        (!this.currentGeoLocation &&
          !(this.currentLocations && this.currentLocations.length))
      );
    },

    labeledGeoLocationOptions(this: Component) {
      const optionsWithTerminal: any[] = [];
      const optionsFromSearchAPI: any[] = [];
      const options: any[] = [];

      this.geoLocationOptions.forEach(o => {
        if (o.id) {
          optionsWithTerminal.push(o);
        } else {
          optionsFromSearchAPI.push(o);
        }
      });

      if (optionsWithTerminal.length) {
        options.push({
          label: this.$t('Orte mit Terminal'),
          values: optionsWithTerminal,
        });
      }

      if (optionsFromSearchAPI.length) {
        options.push({
          label: this.$t('Gefundene weitere Orte'),
          values: optionsFromSearchAPI,
        });
      }

      return options;
    },
  },

  watch: {
    activateSelectGroup: 'updateActivateSelectGroup',
    currentLocations() {
      return (this.locationSelection = this.currentLocations);
    },
    locationOptions: 'reset',
  },

  methods: {
    updateMulticheckbox(value, id) {
      this.allLocationsSelected = false;
    },

    closeMulticheckbox(value, id) {
      this.locationSelection = this.currentLocations;
    },

    toggleAllMulticheckbox() {
      if (this.allLocationsSelected) {
        this.locationSelection = [];
        this.allLocationsSelected = false;
      } else {
        this.locationSelection = this.locationOptions;
        this.allLocationsSelected = true;
      }
    },

    submitMultiCheckbox() {
      this.$emit('selectLocations', this.locationSelection);
      const multicheckbox = this.$refs.multicheckbox as any;
      multicheckbox.deactivate(); // = close the multicheckbox dropdown
    },

    reset() {
      this.allLocationsSelected = false;
    },

    selectCountry(value) {
      this.$emit('selectCountry', value);
      this.focusNextSelectElement('multiselectGeoLocation');
    },

    selectGeoLocation(value) {
      this.$emit('selectGeoLocation', value);
      this.focusNextSelectElement('multicheckbox');
    },

    selectRadius(value) {
      this.$emit('selectRadius', value);
      this.focusNextSelectElement('multicheckbox');
    },

    setGeoPosQuery(value) {
      this.$emit('setGeoPosQuery', value);
      this.currentGeoPosQueryLength = value.length;
    },

    focusNextSelectElement(refString) {
      if (!this.isMobile) {
        this.$nextTick(() => {
          const selectElement = this.$refs[refString] as any;
          if (selectElement) {
            selectElement.$el.focus();
          }
        });
      }
    },

    updateActivateSelectGroup(newValue, oldValue) {
      this.isSelectGroupActive = newValue;
    },
  },
} as ComponentOptions<Component>;
