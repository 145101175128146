import { UI as MUTATIONS } from 'store/mutations';
import { UI as ACTIONS } from 'store/actions';
import { UI as GETTERS } from 'store/getters';
import { UIState, RootState } from 'types';
import { GetterTree, ActionTree, MutationTree } from 'vuex';

const state: UIState = {
  windowSize: {
    width: 0,
    height: 0,
  },
  loaderCount: 0,
  showLoader: false,
  currentContentView: '',
  mouseIsDragging: false,
  closeDetails: false,
  closeMainContent: false,
  closeConnectionFilter: false,
};

const getters: GetterTree<UIState, RootState> = {
  [GETTERS.HAS_MAIN_CONTENT_MOBILE_SCROLLBAR](state, getters, rootState) {
    return state.windowSize.width <= 1024 || state.windowSize.height <= 800;
  },

  [GETTERS.IS_MOBILE](state, getters, rootState) {
    return state.windowSize.width <= 768;
  },
};

const actions: ActionTree<UIState, RootState> = {
  [ACTIONS.HIDE_LOADER]({ commit, rootState }) {
    commit(MUTATIONS.SET_LOADER, false);
  },

  [ACTIONS.SHOW_LOADER]({ commit }) {
    commit(MUTATIONS.SET_LOADER, true);
  },

  [ACTIONS.SET_CONTENT_VIEW]({ commit }, viewClass) {
    commit(MUTATIONS.SET_CONTENT_VIEW, viewClass);
  },

  [ACTIONS.SET_MOUSE_DRAGGING_STATE]({ commit }, isDragging) {
    commit(MUTATIONS.SET_MOUSE_DRAGGING_STATE, isDragging);
  },

  [ACTIONS.SET_CLOSE_DETAILS]({ commit }, closeDetails) {
    commit(MUTATIONS.SET_CLOSE_DETAILS, closeDetails);
  },

  [ACTIONS.SET_CLOSE_MAIN_CONTENT]({ commit }, closeMainContent) {
    commit(MUTATIONS.SET_CLOSE_MAIN_CONTENT, closeMainContent);
  },

  [ACTIONS.SET_CLOSE_CONNECTION_FILTER]({ commit }, closeConnection) {
    commit(MUTATIONS.SET_CLOSE_CONNECTION_FILTER, closeConnection);
  },

  [ACTIONS.SET_WINDOW_SIZE]({ commit }, windowSize) {
    commit(MUTATIONS.SET_WINDOW_SIZE, windowSize);
  },
};

const mutations: MutationTree<UIState> = {
  [MUTATIONS.SET_LOADER](state, isShowing) {
    if (isShowing) {
      state.loaderCount += 1;
    } else {
      state.loaderCount -= 1;
    }
    state.showLoader = state.loaderCount > 0;
  },

  [MUTATIONS.SET_CONTENT_VIEW](state, viewClass) {
    state.currentContentView = viewClass;
  },

  [MUTATIONS.SET_MOUSE_DRAGGING_STATE](state, isDragging) {
    state.mouseIsDragging = isDragging;
  },

  [MUTATIONS.SET_CLOSE_DETAILS](state, closeDetails) {
    state.closeDetails = closeDetails;
  },

  [MUTATIONS.SET_CLOSE_MAIN_CONTENT](state, closeMainContent) {
    state.closeMainContent = closeMainContent;
  },

  [MUTATIONS.SET_CLOSE_CONNECTION_FILTER](state, closeConnectionFilter) {
    state.closeConnectionFilter = closeConnectionFilter;
  },

  [MUTATIONS.SET_WINDOW_SIZE](state, windowSize) {
    state.windowSize = windowSize;
  },
};

export default { state, mutations, actions, getters };
