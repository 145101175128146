var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"block-train-details"},[_c('h3',[_vm._v(_vm._s(_vm.$t('Buchungsschluss')))]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column-left"},[_vm._v("\n      "+_vm._s(_vm.trainBookingInfo)+"\n    ")])]),_vm._v(" "),(_vm.trainData.prices.usableUnit)?[_c('h3',[_vm._v(_vm._s(_vm.$t('Preise')))]),_vm._v(" "),(_vm.trainData.prices.all && _vm.trainData.prices.all.price)?[_c('h4',[_vm._v(_vm._s(_vm.$t('Gesamtstrecke')))]),_vm._v(" "),_c('p',[(_vm.trainData.prices.all.fromLocation)?[_vm._v("\n          "+_vm._s(_vm.getLocationNameFromUid(_vm.trainData.prices.all.fromLocation))+" -\n          "+_vm._s(_vm.getLocationNameFromUid(
              _vm.trainData.prices.sectionPrices[
                _vm.trainData.prices.sectionPrices.length - 1
              ].toLocation
            ))+":\n        ")]:_vm._e(),_vm._v("\n        "+_vm._s(_vm.trainData.prices.all.price + ' €')),_c('br'),_vm._v("\n        "+_vm._s(_vm.$t('Ladeform'))+":\n        "+_vm._s(_vm.getUsableUnitName(_vm.trainData.prices.usableUnit))+"\n      ")],2)]:_vm._e(),_vm._v(" "),(_vm.trainData.prices.sectionPrices.length)?[_c('h4',[_vm._v(_vm._s(_vm.$t('Teilstrecke(n)')))]),_vm._v(" "),_c('ul',_vm._l((_vm.trainData.prices.sectionPrices),function(section,index){return _c('li',{key:'sP' + index},[_vm._v("\n          "+_vm._s(_vm.getLocationNameFromUid(section.fromLocation))+" -\n          "+_vm._s(_vm.getLocationNameFromUid(section.toLocation))+":\n          "+_vm._s(section.price + ' €')+"\n        ")])}),0)]:_vm._e()]:_vm._e(),_vm._v(" "),(_vm.trainData.text)?[_c('h3',[_vm._v(_vm._s(_vm.$t('Zuginfo')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.trainData.text))])]:_vm._e(),_vm._v(" "),_c('h3',[_vm._v(_vm._s(_vm.$t('Ladeformen')))]),_vm._v(" "),(
      Object.values(_vm.trainData.usableUnits).findIndex(function (unit) { return unit === true; }) >
        -1
    )?_c('ul',_vm._l((_vm.convertedUsableUnits),function(unit){return _c('li',{key:'unit-' + unit},[_vm._v("\n      "+_vm._s(unit)+"\n    ")])}),0):_c('p',[_vm._v(_vm._s(_vm.$t('n/a')))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }